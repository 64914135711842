import React from 'react';
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import { useTranslation } from 'react-i18next';


//icon loading
const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 50,
        }}
        spin
    />
);


export default function Loading({ }) {

    const { t } = useTranslation();

    return (
        <>
            <div className='card flex items-center gap-5 flex-col justify-center text-lg rounded-md p-2.5'>
                <Spin indicator={antIcon}/>
                <p className='text-white'>{t('loading.titulo_principal')}</p>
            </div>
        </>
    );


}