import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BiError } from "react-icons/bi";
import logo from '../../assets/logo-branco 2.svg';
import { useTranslation } from 'react-i18next';


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

function Login({  }) {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [response, setResponse] = useState(null);
    const [loginRealizado, setLoginRealizado] = useState(false);
    const [loginErro, setLoginErro] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loginErroMsg, setLoginErroMsg] = useState('Ocorreu algum erro, tente novamente!');



    const handleLogin = async () => {

        setLoginLoading(true)

        try {
            const response = await axios.post(
                'https://backoffice.importrust.com/wp-json/importrust-login/login',
                {
                    username,
                    password,
                },
                { withCredentials: true }

            );


            if (response.data.success) {
                localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('token', (response.data.token));
                setLoginLoading(false)
                setIsAuthenticated(true);
                setLoginRealizado(true);
                setLoginErro(false);
                setTimeout(() => {

                    // RELOAD
                    window.location.reload();

                    // navigate("/");
                }, 1500);
                // console.log(response);
            } else {
                setLoginLoading(false)
                setLoginRealizado(false);
                setLoginErro(true);
                setLoginErroMsg(response.data.message)
                // console.log(response);
            }
        } catch (error) {
            setLoginLoading(false)
            setLoginRealizado(false);
            setLoginErro(true);
            console.error('Login error:', error.message);
        }
    };




    return (
        <div className="sec-geral-top login-section card">
            <div className="form main flex flex-col gap-4">
                <div className='flex justify-center'>
                    <img
                        src={logo}
                        alt="Logo"
                        className="w-36 h-10 w-auto object-contain"
                    />
                </div>
                <div className='text-center'>
                    <h1 className='text-base font-semibold'>{t('login.titulo_principal')}</h1>

                </div>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={() => handleLogin()}

                >
                    <p className='label-do-campo text-white pb-2'>{t('login.utilizador')}</p>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: `${t('login.msg.username')}`,
                            },
                        ]}
                    >
                        <Input
                            className="input-geral"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            autoComplete="username"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder={t('login.utilizador')} />
                    </Form.Item>
                    <p className='label-do-campo text-white pb-2'>{t('login.password')}</p>
                    <Form.Item
                        name="password"
                        value={password}
                        rules={[
                            {
                                required: true,
                                message: `${t('login.msg.password')}`,
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="input-geral"
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            autoComplete="current-password"
                            placeholder={t('login.password')}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="bg-white text-black font-bold rounded">
                            {t('login.entrar')}
                        </Button>
                    </Form.Item>
                </Form>
                {loginLoading &&
                    <>
                        <div className="flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-center'>{t('login.loading')}</p>
                        </div>
                    </>
                }
                {loginRealizado ? (
                    <>
                        <div className="flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-center'>{t('login.sucesso')}</p>
                        </div>
                    </>
                ) : loginErro ? (
                    <div className="flex flex-col text-center gap-4 items-center">
                        <BiError className='iconc' />
                        <p className='text-center'>{loginErroMsg}</p>

                    </div>
                ) : null}
            </div>

        </div>

    );
}

export default Login;


