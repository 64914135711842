import React, { useState, useEffect } from 'react';


export default function ProcessoCredito({
    formatarNumero,

    activeTabKeyResultadoSimulacao,

    ivaNaOrigem,
    valorIvaNaOrigem,
    valorFinalISV,
    taxaDeServico,
    precoFinalCarro,
    precoFinalDoCarroSemIva,
    valorDaViatura,
    setValorDaViatura,
    valorFinalaoCliente,
    setValorFinalaoCliente,
    valorPedirAFabi,
    setValorPedirAFabi,
    percentagemPedirAFabi,
    setPercentagemPedirAFabi,
}) {

    // const [valorDaViatura, setValorDaViatura] = useState('');
    // const [valorFinalaoCliente, setValorFinalaoCliente] = useState('');
    // const [valorPedirAFabi, setValorPedirAFabi] = useState('');
    // const [percentagemPedirAFabi, setPercentagemPedirAFabi] = useState('20');


    useEffect(() => {

        const calcularProcessoComIVAnaOrigem = () => {
            const valor1 = parseFloat(valorDaViatura);
            const valor2 = parseFloat(taxaDeServico);
            const valor3 = parseFloat(valorFinalISV);
            const percentagem = parseFloat(percentagemPedirAFabi) / 100;


            if (!isNaN(valor1) && !isNaN(valor2) && !isNaN(valor3)) {
                const montanteNecessario = (valor1 + (valor2 / 1.23)) * 1.23 + valor3;

                const valorParaCliente = montanteNecessario * (0 + percentagem);
                setValorFinalaoCliente(montanteNecessario)
                setValorPedirAFabi(valorParaCliente)
            }
        };


        const calcularProcessoSemIVAnaOrigem = () => {
            const valor1 = parseFloat(valorDaViatura);
            const valor2 = parseFloat(taxaDeServico);
            const valor3 = parseFloat(valorFinalISV);
            const percentagem = parseFloat(percentagemPedirAFabi) / 100;


            if (!isNaN(valor1) && !isNaN(valor2) && !isNaN(valor3)) {
                const montanteNecessario = valor1 + valor2 + valor3;
                const valorParaCliente = montanteNecessario * (0 + percentagem);
                setValorFinalaoCliente(montanteNecessario)
                setValorPedirAFabi(valorParaCliente)
            }
        };



        if (valorIvaNaOrigem) {
            calcularProcessoComIVAnaOrigem();
        } else {
            calcularProcessoSemIVAnaOrigem()
        }



    }, [valorFinalISV, valorDaViatura]);

    useEffect(() => {

        if (activeTabKeyResultadoSimulacao === '1') {
            setValorDaViatura(precoFinalCarro)
            // console.log('particular')
        } else {
            setValorDaViatura(precoFinalDoCarroSemIva)
            // console.log('empresa')
        }


    }, [activeTabKeyResultadoSimulacao]);



    return (
        <div>
            {ivaNaOrigem ? (
                <>
                    <p className='text-black text-base flex justify-between'>Preço do Carro c/IVA na origem: <span
                        className='font-bold text-lg'>{formatarNumero(valorDaViatura)}€</span>
                    </p>
                    <p className='text-black text-base flex justify-between'>
                        ISV:
                        <span className='font-bold text-lg flex items-center gap-2'>
                            {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                        </span>
                    </p>
                    <p className='text-black text-base flex justify-between'>Taxa de Serviço:
                        <span className='font-bold text-lg'>{formatarNumero(taxaDeServico)}€</span>
                    </p>
                    <p className='text-black text-base font-semibold flex justify-between'>Valor final ao cliente:
                        <span className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorFinalaoCliente)}€</span>
                    </p>
                    <p className='text-black text-base font-semibold flex justify-between'>Valor pedir à Fabi (20%):
                        <span className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorPedirAFabi)}€</span>
                    </p>
                </>
            ) : (
                <>
                    <p className='text-black text-base flex justify-between'>Preço do Carro s/IVA na origem: <span
                        className='font-bold text-lg'>{formatarNumero(valorDaViatura)}€</span>
                    </p>
                    <p className='text-black text-base flex justify-between'>
                        ISV:
                        <span className='font-bold text-lg flex items-center gap-2'>
                            {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                        </span>
                    </p>
                    <p className='text-black text-base flex justify-between'>Taxa de Serviço:
                        <span className='font-bold text-lg'>{formatarNumero(taxaDeServico)}€</span>
                    </p>
                    <p className='text-black text-base font-semibold flex justify-between'>Valor final ao cliente:
                        <span className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorFinalaoCliente)}€</span>
                    </p>
                    <p className='text-black text-base font-semibold flex justify-between'>Valor pedir à Fabi (20%):
                        <span className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorPedirAFabi)}€</span>
                    </p>
                </>
            )}
        </div >
    );
}
