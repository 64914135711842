import React, { useState, useEffect } from "react";
import { Image, Popover, Menu, Avatar, Select, Dropdown, Button } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from '../../../assets/logo-branco 2.svg';
import flagPortugal from '../../../assets/flags/flag-portugal.svg';
import flagSpain from '../../../assets/flags/flag-spain.svg';
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";
import i18n from '../../../App';
import { FlagOutlined } from '@ant-design/icons'; // Importe os ícones das bandeiras aqui
import { Option } from "antd/lib/mentions";
import { useTranslation } from 'react-i18next';
import geral from '../../../locale/geral.json';
import SubMenu from "antd/es/menu/SubMenu";

export default function HeaderFuncionario({
    userData,
    currentLanguage,
    setCurrentLanguage,
    setEsconde,
    setUrlCarroLink,
    setEstadoDaPartilhaNomeDaProposta,
    setErroPedido,
    setTeste,
    allLanguage
}) {

    const [openKey, setOpenKey] = useState(null);

    const { t } = useTranslation();


    const [showButton, setShowButton] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const path = location.pathname;
        setShowButton(path !== "/");
    }, [location.pathname]);


    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = "/";
    };

    const handleClick = () => {
        setEsconde(false);
        setUrlCarroLink('');
        setErroPedido(false)
        setEstadoDaPartilhaNomeDaProposta(false)
        setTeste(false)
    };


    const handleChangeLanguage = (e) => {

        const currentLanguageFromURL = window.location.pathname.split('/')[1];
        const newURL = window.location.pathname.replace(`/${currentLanguageFromURL}/`, `/${e}/`);
        window.history.pushState({}, '', newURL);
        setCurrentLanguage(e);

    };


    const handleClickMenuMain = () => {
        setEsconde(false);
        setUrlCarroLink('');
        setErroPedido(false)
        setEstadoDaPartilhaNomeDaProposta(false)
        setTeste(false);
    };


    const handleRestoreMainState = () => {
        setTeste(false)
        setEsconde(false);
        setErroPedido(false)
        setUrlCarroLink('');
        setEstadoDaPartilhaNomeDaProposta(false)
        navigate(-1)
    }

    return (

        <div className="card header-func">
            <div className="flex gap-4 items-center justify-between">
                <div className="w-3/5 sm:w-1/4 flex items-center gap-4">
                    {showButton && (
                        <div className="flex items-center gap-4">
                            <Button
                                type="primary"
                                target="_blank"
                                className="flex gap-2 items-center bg-white text-black  font-bold rounded"
                                onClick={handleRestoreMainState}><IoIosArrowBack /> {t('header.voltar')}</Button>

                            <div>
                                |
                            </div>
                        </div>
                    )}

                    <Link to="/">
                        <img
                            src={logo}
                            alt="Logo"
                            className="w-40 max-w-40 h-10 object-contain cursor-pointer"
                        />
                    </Link>
                </div>
                <div className="w-2/5 sm:w-1/4 flex justify-end">
                    <Popover
                        placement="bottomRight"
                        content={
                            <>
                                <div className="flex flex-col gap-2 w-64">
                                    <div className="flex flex-col items-center gap-2">
                                        <Image
                                            style={{ borderRadius: '50%' }}
                                            width={60}
                                            src={userData?.data?.foto_perfil}
                                            className={'img-user-logo'}
                                        />
                                        <div className="flex flex-col items-center">
                                            <span className="text-base font-semibold">{userData?.data?.display_name}</span>
                                            <span className="text-sm">{userData?.data?.cargo}</span>
                                        </div>

                                    </div>
                                    <div className="border-t-2">
                                        <Menu
                                            mode="inline"
                                            openKeys={[openKey]}
                                            onOpenChange={(keys) => {
                                                const latestOpenKey = keys.find((key) => key !== openKey);
                                                setOpenKey(latestOpenKey);
                                            }}
                                        >
                                            <Menu.Item onClick={() => handleClickMenuMain()}>
                                                <Link to="/" onClick={() => setOpenKey(null)}>{t('header.painel')}</Link>
                                            </Menu.Item>
                                            <Menu.Item onClick={() => handleClickMenuMain()}>
                                                <Link to={`/${currentLanguage}/${t('header.simulador')}`} onClick={() => setOpenKey(null)}>{t('header.Simulador')}</Link>
                                            </Menu.Item>
                                            <Menu.Item onClick={() => handleClickMenuMain()}>
                                                <Link to={`/${currentLanguage}/${t('header.propostas')}`} onClick={() => setOpenKey(null)}>{t('header.Ver Propostas')}</Link>
                                            </Menu.Item>
                                            <Menu.Item onClick={() => handleClickMenuMain()}>
                                                <Link to={`/${currentLanguage}/${t('header.historico-de-pesquisas')}`} onClick={() => setOpenKey(null)}>{t('header.Histórico de Pesquisas')}</Link>
                                            </Menu.Item>
                                            {currentLanguage === "pt" && (
                                                <>
                                                    <Menu.Item onClick={() => handleClickMenuMain()}>
                                                        <Link to="https://tools.importrust.com/" target="_blank" onClick={() => setOpenKey(null)}>Tools</Link>
                                                    </Menu.Item>
                                                    <SubMenu
                                                        key="hubspot"
                                                        title="Hubspot"
                                                        className="hubspot-menu-main"
                                                        // onTitleClick={() => handleClick('hubspot')}
                                                    >
                                                        <Menu.Item className="hubspot-menu-li" onClick={() => handleClickMenuMain()}>
                                                            <Link to={`/${currentLanguage}/hubspot/consultar-lista-deals`} onClick={() => setOpenKey('hubspot')}>
                                                                Lista de Deals
                                                            </Link>
                                                        </Menu.Item>
                                                    </SubMenu>
                                                </>
                                            )}
                                        </Menu>

                                    </div>
                                    <div className="border-t-2">
                                        <Menu>
                                            <Menu.Item>
                                                <Dropdown overlay={
                                                    <Menu onClick={(e) => handleChangeLanguage(e.key)}>
                                                        {allLanguage.includes('pt') && (
                                                            <Menu.Item key="pt">
                                                                <Link to="/">
                                                                    <div className="flex items-center">
                                                                        <img
                                                                            src={flagPortugal}
                                                                            alt="Portugal"
                                                                            style={{ marginRight: '8px', width: '24px', height: '16px' }}
                                                                        />
                                                                        {t('main.portugal')}
                                                                    </div>
                                                                </Link>
                                                            </Menu.Item>
                                                        )}

                                                        {allLanguage.includes('es') && (
                                                            <Menu.Item key="es">
                                                                <Link to="/">
                                                                    <div className="flex items-center">
                                                                        <img
                                                                            src={flagSpain}
                                                                            alt="Spain"
                                                                            style={{ marginRight: '8px', width: '24px', height: '16px' }}
                                                                        />
                                                                        {t('main.spain')}
                                                                    </div>
                                                                </Link>
                                                            </Menu.Item>
                                                        )}

                                                    </Menu>
                                                }>
                                                    <a className="ant-dropdown-link">
                                                        <div className="flex align-center justify-between">
                                                            {t('header.linguagem')}
                                                            <span className="flex items-center align-center">
                                                                {currentLanguage && (
                                                                    <img
                                                                        src={geral[currentLanguage].imagem}
                                                                        alt={geral[currentLanguage].nome}
                                                                        style={{ width: '44px', height: '31px', cursor: 'pointer' }}
                                                                    />
                                                                )}
                                                            </span>
                                                        </div>
                                                    </a>
                                                </Dropdown>
                                            </Menu.Item>

                                            <Menu.Item onClick={handleLogout}>
                                                {t('header.sair')}
                                            </Menu.Item>
                                        </Menu>

                                    </div>
                                </div>
                            </>

                        }
                        trigger="click"
                    >
                        <div className="flex justify-end gap-1 items-center cursor-pointer">
                            <Avatar src={userData?.data?.foto_perfil} size={37} style={{}} />
                            <IoIosArrowDown />
                        </div>
                    </Popover>
                </div >
            </div >
        </div >

    )
}