import React, { useState, useEffect, useRef } from 'react';
import {
    useLocation
} from "react-router-dom";
import axios from 'axios';
import { BiError } from 'react-icons/bi';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TabelaViatura from "../../../components/pt/tabelaViatura";
import ErroSection from "../../../components/pt/erros/erro-section";
import Loading from "../../../components/pt/loading/loading";
import { Button } from 'antd';
import TabelaViaturaCliente from '../../../components/pt/tabelaViaturaCliente';
import LinkPartilhaExpira from '../../../components/pt/link-partilha-expira';
import FuncContactDetails from '../../../components/pt/funcionario/func-contact-details';
import PesquisaSimulacao from '../../../components/pt/pesquisa-simulacao';
import HeaderSimulador from '../../../components/pt/header/header-simulador';
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';
import CreditoMainComp from '../../../components/pt/credito/credito-main-comp';
import { useTranslation } from 'react-i18next';
import ES_CalculoChaveMao from '../../../components/es/es_calculo-chave-mao';
import ES_PartilharProposta from '../../../components/es/es_partilhar-proposta';
import ES_ResultadodaSimulacao from '../../../components/es/es_resultado-da-simulacao';
import ES_CalculoChaveMaoVista from '../../../components/es/es_calculo-chave-mao-vista';


dayjs.extend(customParseFormat);


export default function ES_Calculo({
    setNovaPesquisa,
    isAuthenticated,
    setCurrentLanguage,
    currentLanguage,
    validartipodeparametro,
    userData,
    isMobile,
    setNomeGeradoDaProposta,
    setEstadoDaPartilhaNomeDaProposta,
    estadoDaPartilhaNomeDaProposta,
    nomeGeradoDaProposta,
    linkDaProposta,
    temParametros,
    formatarNumero,
    setTemParametros,
    setPartilhaNomeDaProposta,
    partilhaNomeDaProposta,
    iDTokenAuthenticated,
    saveToken,
    setEsconde,
    setErroPedido,
    urlCarroLink,
    setUrlCarroLink,
    esconde,
    erroPedido,
    teste,
    setTeste
}) {




    useEffect(() => {
        setTeste(false)
        setEsconde(false);
        setErroPedido(false)
        setUrlCarroLink('');
        setEstadoDaPartilhaNomeDaProposta(false)
    }, []);



    const { t } = useTranslation();


    //outros
    const [loading, setLoading] = useState(false);
    const [alertasNoPedido, setAlertasNoPedido] = useState(false);


    //guardar dados do carro
    const [dataCarros, setDataCarros] = useState([]);

    //guardar titulo do copypasy da proposta
    const [tituloDaPropostaChaveNaMao, setTituloDaPropostaChaveNaMao] = useState('');
    const [tituloDaPropostaChaveNaMaoParticular, setTituloDaPropostaChaveNaMaoParticular] = useState('');

    //para ISV
    const [tipoDeVeiculoParaInsencoes, setTipoDeVeiculoParaInsencoes] = useState('');
    const [novoOuUsado, setNovoOuUsado] = useState('');
    const [dataDeRegisto, setDataDeRegisto] = useState('');
    const [cilindrada, setCilindrada] = useState('');
    const [tipoDeCombustivel, setTipoDeCombustivel] = useState('');
    //  const [particulas, setParticulas] = useState('');
    // const [tipoDeTestes, setTipoDeTestes] = useState('');
    const [emissoesGases, setEmissoesGases] = useState('');



    //para ISV e IUC -ESPANHOL
    const [idModelo, setIdModelo] = useState('');
    const [listaDeModelos, setListaDeModelos] = useState('');

    const [idMarca, setIdMarca] = useState('');
    const [listaDeMarcas, setListaDeMarcas] = useState('');

    const [valorViaturaNova, setValorViaturaNova] = useState('');
    const [tipoCarro, setTipoCarro] = useState('');

    const [idCidade, setIdCidade] = useState('');
    const [listaDeCidades, setListaDeCidades] = useState('');

    const [idMunicipio, setIdMunicipio] = useState('');
    const [listaDeMunicipios, setListaDeMunicipios] = useState('');



    const [iucCavalosFiscais, setIucCavalosFiscais] = useState('');
    const [iucCidade, setIucCidade] = useState('');
    const [iucMunicipio, setIucMunicipio] = useState('');
    const [iucPercentagemDescontoEletrico, setIucPercentagemDescontoEletrico] = useState('');



    const [isvComunidad, setIsvComunidad] = useState('');
    const [isvValoracionInicial, setIsvValoracionInicial] = useState('');
    const [isvPorcentajeaAplicar, setIsvPorcentajeaAplicar] = useState('');
    const [isvMinoracion, setIsvMinoracion] = useState('');
    const [isvBaseImponible, setIsvBaseImponible] = useState('');
    const [isvReduccionBaseImponible, setIsvReduccionBaseImponible] = useState('');
    const [isvBaseImponibleFinal, setIsvBaseImponibleFinal] = useState('');
    const [isvCuotaTributaria, setIsvCuotaTributaria] = useState('');
    const [isvTotalaPagar, setIsvTotalaPagar] = useState('');

    //--



    //para calculos
    const [taxaDeServico, setTaxaDeServico] = useState('2800');
    const [precoFinalCarro, setPrecoFinalCarro] = useState('');
    const [valorFinalISV, setValorFinalISV] = useState('0');
    const [dataGeralISV, setDataGeralISV] = useState('');

    const [valorFinalIUC, setValorFinalIUC] = useState('0');
    const [dataGeralIUC, setDataGeralIUC] = useState('');

    // const [iucCilindrada, setIucCilindrada] = useState('');
    // const [iucCO2, setIucCO2] = useState('');
    // const [iucAdicionalCO2, setIucAdicionalCO2] = useState('');
    // const [iucCoeficienteAnoMatriculo, setIucCoeficienteAnoMatriculo] = useState('');
    // const [iucAdicionalGasoleo, setIucAdicionalGasoleo] = useState('');

    // const [isvcomponentecilindrada, setIsvComponenteCilindrada] = useState('');
    // const [isvcomponenteambiental, setIsvcomponenteambiental] = useState('');
    // const [isvReducaoAnosUsoCilindrada, setIsvReducaoAnosUsoCilindrada] = useState('');
    // const [isvReducaoAnosUsoAmbiental, setIsvReducaoAnosUsoAmbiental] = useState('');
    // const [isvAgravamentoParticulas, setIsvAgravamentoParticulas] = useState('');
    // const [isvReducaoAnosUsoParticulas, setIsvReducaoAnosUsoParticulas] = useState('');
    // const [isvTaxaAplicavelTabela, setIsvTaxaAplicavelTabela] = useState('');


    const [valorChaveNaMaoEmpresa, setValorChaveNaMaoEmpresa] = useState('');
    const [valorChaveNaMaoParticular, setValorChaveNaMaoParticular] = useState('');


    //Dados - Validar Dados - Guardar Dados
    //  const [quantidadeDeKM, setQuantidadeDeKM] = useState('');
    //  const [anoDoRegisto, setAnoDoRegisto] = useState('');
    const [precoFinalDoCarroSemIva, setPrecoFinalDoCarroSemIva] = useState('');
    const [precoFinalDoCarroIvaDedutival, setPrecoFinalDoCarroIvaDedutival] = useState(false);
    //const [selecionarIsencao, setSelecionarIsencao] = useState(true);
    const [hibridoIsChecked, setHibridoIsChecked] = useState(false);
    const [co2IsChecked, setCo2IsChecked] = useState(false);
    //  const [wltpIsChecked, setWltpIsChecked] = useState(false);
    const [outrosServicos, setOutrosServicos] = useState('');
    const [validarAnoDeRegisto, setValidarAnoDeRegisto] = useState(false);
    const [validarViaturaEletrico, setValidarViaturaEletrico] = useState(false);
    // VALIDACAO HIBRIDO PLUGIN
    // const [hibridoValidationIsChecked, setHibridoValidationIsChecked] = useState(false);
    // PARA SABER SE É TAB PARTICULAR OU EMPRESA (PARTICULAR COMEÇA COM TRUE)
    const [isParticular, setIsParticular] = useState(true);

    const [erroInicial, setErroInicial] = useState('Ocorreu algum erro, tente novamente!');
    const [infoInicialPartilhaProposta, setInfoInicialPartilhaProposta] = useState('');

    //Resultado da Simulação
    const [activeTabKeyResultadoSimulacao, setActiveTabKeyResultadoSimulacao] = useState("1");
    const [tabResultadoSimulacao, setTabResultadoSimulacao] = useState("0");


    //partilhar com cliente
    const [nomeDaProposta, setNomeDaProposta] = useState('');
    const [allDataLinkPartilhado, setAllDataLinkPartilhado] = useState('');


    const [nomePerfilDataFuncionario, setNomePerfilDataFuncionario] = useState('');
    const [fotoPerfilDataFuncionario, setFotoPerfilDataFuncionario] = useState('');
    const [whatsappDataFuncionario, setWhatsappDataFuncionario] = useState('');
    const [emailDataInternoFuncionario, setEmailDataInternoFuncionario] = useState('');


    const [IgnorarAvisos, setIgnorarAvisos] = useState(false);

    //simulacao de credito
    const [activeTabKeySimulacaoCredito, setActiveTabKeySimulacaoCredito] = useState("1");
    const [taxaDeJuroCredito, setTaxaDeJuroCredito] = useState('6.50');
    const [entradaCredito, setEntradaCredito] = useState('');
    const [valorChaveNaMaoViaturaParaCredito, setValorChaveNaMaoViaturaParaCredito] = useState('');
    const [valorFinanciadoCredito, setValorFinanciadoCredito] = useState('');
    const [montanteNecessarioCredito, setMontanteNecessarioCredito] = useState('');
    const [prestacoesMesesCredito, setPrestacoesMesesCredito] = useState('');
    const [valorChaveNaMaoParaCredito, setValorChaveNaMaoParaCredito] = useState('');
    const [valorFinalCredito, setValorFinalCredito] = useState('');
    const [ivaNaOrigem, setIvaNaOrigem] = useState(false);
    const [valorIvaNaOrigem, setValorIvaNaOrigem] = useState('');
    const [valordaViaturaIsChecked, setValordaViaturaIsChecked] = useState(false);


    //Procurar Média Nacional
    const [valorMediaNacional, setValorMediaNacional] = useState('');


    const location = useLocation();


    useEffect(() => {
        const parametros = new URLSearchParams(location.search)
        if (parametros.size > 0) {

            setCurrentLanguage("es");

            const nomePropostaParam = parametros.get('propuesta_nombre');
            const idPropostaParam = parametros.get('id_propuesta');

            if (nomePropostaParam || idPropostaParam) {

                if (nomePropostaParam) {
                    setNomeGeradoDaProposta(nomePropostaParam);
                }
                if (idPropostaParam) {
                    setNomeGeradoDaProposta(idPropostaParam);
                }
                setEstadoDaPartilhaNomeDaProposta(true)
                setTeste(true)
                if (!isAuthenticated) {
                    setNovaPesquisa(false);
                    setTemParametros(true);
                } else {
                    setNovaPesquisa(true);
                    setTemParametros(false);
                }
            }
        } else {
            setEstadoDaPartilhaNomeDaProposta(false);
        }
    }, []);


    const handleRestoreMainState = () => {
        setTeste(false)
        setEsconde(false);
        setErroPedido(false)
        setUrlCarroLink('');
        setEstadoDaPartilhaNomeDaProposta(false)
    }


    //valida se tem parametros na url
    useEffect(() => {

        if (estadoDaPartilhaNomeDaProposta) {

            // console.log('asa')

            setLoading(true);
            setEsconde(true);

            let linkdopedido

            if (validartipodeparametro) {
                linkdopedido = `${process.env.REACT_APP_GET_PROPOSTA_ES}?${t('propostas.nome_proposta')}=${nomeGeradoDaProposta}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`
            } else {
                linkdopedido = `${process.env.REACT_APP_GET_PROPOSTA_ES}?${t('propostas.id_proposta')}=${nomeGeradoDaProposta}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`
            }

            axios.get(linkdopedido)
                .then((response) => {

                    const whatsappFuncionario = response?.data?.user.whatsapp
                    const emailInternoFuncionario = response?.data?.user.email_interno
                    const fotoPerfilInternoFuncionario = response?.data?.user.foto_perfil
                    const nomePerfilInternoFuncionario = response?.data?.user.nome_user
                    setNomePerfilDataFuncionario(nomePerfilInternoFuncionario)
                    setFotoPerfilDataFuncionario(fotoPerfilInternoFuncionario)
                    setWhatsappDataFuncionario(whatsappFuncionario)
                    setEmailDataInternoFuncionario(emailInternoFuncionario)




                    if (response.data.sucesso) {
                        setLoading(false);
                        setEsconde(false);
                        const dataLinkPartilhado = response.data.data
                        // console.log('dataLinkPartilhado-dataLinkPartilhado', dataLinkPartilhado)
                        setAllDataLinkPartilhado(dataLinkPartilhado)
                        processosRepetidosNosPedidos(dataLinkPartilhado);
                        setValorFinalISV(dataLinkPartilhado.isv)
                        setValorFinalIUC(dataLinkPartilhado.iuc)


                        setIdMarca(dataLinkPartilhado.id_marca);
                        setIdModelo(dataLinkPartilhado.id_modelo);
                        setIdCidade(dataLinkPartilhado.id_cidade);
                        setIdMunicipio(dataLinkPartilhado.id_municipio);





                        setIucCavalosFiscais(dataLinkPartilhado.iuc_cavalos_fiscais);
                        setIucCidade(dataLinkPartilhado.iuc_cidade);
                        setIucMunicipio(dataLinkPartilhado.iuc_municipio);
                        setIucPercentagemDescontoEletrico(dataLinkPartilhado.iuc_percentagem_desconto_eletrico);


                        setIsvComunidad(dataLinkPartilhado.isv_comunidad);
                        setIsvValoracionInicial(dataLinkPartilhado.isv_valoracion_inicial);
                        setIsvPorcentajeaAplicar(dataLinkPartilhado.isv_porcentaje_aplicar);
                        setIsvMinoracion(dataLinkPartilhado.isv_minoracion);
                        setIsvBaseImponible(dataLinkPartilhado.isv_base_imponible);
                        setIsvReduccionBaseImponible(dataLinkPartilhado.isv_reduccion_base_imponible);
                        setIsvBaseImponibleFinal(dataLinkPartilhado.isv_base_imponible_final);
                        setIsvCuotaTributaria(dataLinkPartilhado.isv_cuota_tributaria);
                        setIsvTotalaPagar(dataLinkPartilhado.isv_total_pagar);

                        // setIucCilindrada(dataLinkPartilhado.iuc_cilindrada)
                        // setIucCO2(dataLinkPartilhado.iuc_co2)
                        // setIucAdicionalCO2(dataLinkPartilhado.iuc_adicional_co2)
                        // setIucCoeficienteAnoMatriculo(dataLinkPartilhado.iuc_coeficiente_ano_matriculo)
                        // setIucAdicionalGasoleo(dataLinkPartilhado.iuc_adicional_gasoleo)

                        // setIsvComponenteCilindrada(dataLinkPartilhado.isv_componente_cilindrada)
                        // setIsvcomponenteambiental(dataLinkPartilhado.isv_componente_ambiental)
                        // setIsvReducaoAnosUsoCilindrada(dataLinkPartilhado.isv_reducao_anos_uso_cilindrada)
                        // setIsvReducaoAnosUsoAmbiental(dataLinkPartilhado.isv_reducao_anos_uso_ambiental)
                        // setIsvAgravamentoParticulas(dataLinkPartilhado.isv_agravamento_particulas)
                        // setIsvReducaoAnosUsoParticulas(dataLinkPartilhado.isv_reducao_anos_uso_particulas)
                        // setIsvTaxaAplicavelTabela(dataLinkPartilhado.isv_taxa_aplicavel_tabela)


                        setTaxaDeServico(dataLinkPartilhado.taxadeservico)
                        setInfoInicialPartilhaProposta(response.data.data_expira)
                        setIgnorarAvisos(true);
                        setTabResultadoSimulacao(response.data.data.empresa)



                        if (dataLinkPartilhado.tipo_de_combustivel === "Eletrico") {
                            setValidarViaturaEletrico(true)
                        } else {
                            setValidarViaturaEletrico(false)
                        }


                    } else {

                        setErroInicial(response.data.mensagem)
                        setErroPedido(true)
                        setLoading(false);
                        setEsconde(false);

                    }


                })
                .catch((error) => {


                })
                .finally(() => {

                });
        }


    }, [estadoDaPartilhaNomeDaProposta]);


    //Pedido
    useEffect(() => {

        setErroPedido(false)
        setTipoDeVeiculoParaInsencoes('')
        setNovoOuUsado('')
        setDataDeRegisto('')
        setCilindrada('')
        setTipoDeCombustivel('')
        //setParticulas('')
        //setTipoDeTestes('')
        setEmissoesGases('')
        setPrecoFinalCarro('')
        setValidarAnoDeRegisto(false)
        setAlertasNoPedido(false)
        setValorChaveNaMaoEmpresa('')
        setValorChaveNaMaoParticular('')
        setValorChaveNaMaoParaCredito('')
        setValordaViaturaIsChecked(false);
        setCo2IsChecked(false);
        //    setWltpIsChecked(false);
        setHibridoIsChecked(false)
        setValidarViaturaEletrico(false)
        setIvaNaOrigem(false)
        setIdModelo('')
        setIdMunicipio('')
        setIdCidade('')
        setIdMarca('')
        setValorViaturaNova('')
        setAlertasNoPedido(false)
        setValorMediaNacional('')


        if (!urlCarroLink) {
            setErroInicial('Ingrese la URL del vehículo.')
            return;
        } else {

            setErroPedido(false)
            setLoading(true);
            setEsconde(true);

            const fetchData = async () => {
                try {

                    const requestUrl = `${process.env.REACT_APP_PEDIDO_PROPOSTA}?request=${encodeURIComponent(urlCarroLink)}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`;


                    const response = await axios.get(requestUrl);
                    // console.log(response)
                    const dataFinalDadosObjeto = response.data;
                    //setTodosOsDadosDoCarro(dataFinalDadosObjeto)

                    if (dataFinalDadosObjeto.car) {

                        // TRATAR OS DADOS (SEJA AUTO SCOUT OU MOBILE.DE)
                        processosRepetidosNosPedidos(dataFinalDadosObjeto.car);

                        // Guardar dados do URL
                        setDataCarros(dataFinalDadosObjeto.car);
                        setIdMarca(dataFinalDadosObjeto.car.id_marca);
                        setListaDeModelos(dataFinalDadosObjeto.car.modelos);
                    } else {
                        setLoading(false);
                        setEsconde(false);
                        setErroPedido(true);

                        if (dataFinalDadosObjeto.erro) {
                            setErroInicial(dataFinalDadosObjeto.erro)
                        }

                    }


                } catch (error) {
                    setLoading(false);
                    setEsconde(false);
                    setErroPedido(true);
                    console.error('Error fetching data:', error);
                }
            };


            const fetchCidade = async () => {
                try {

                    const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_CIDADES}`;

                    const response = await axios.get(requestUrl);
                    setListaDeCidades(response.data.mensagem)


                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };


            const fetchMarca = async () => {
                try {

                    const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_MARCAS}`;

                    const response = await axios.get(requestUrl);
                    // console.log('fetchMarca', response.data.mensagem)
                    setListaDeMarcas(response.data.mensagem)


                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };



            if (urlCarroLink) {
                // CHAMAR A FUNCAO ACIMA (TEM QUE SER FUNCAO POR SER ASYNC) (ESPERAR QUE TERMINE PARA EXECUTAR O RESTANTE CODIGO ABAIXO)
                fetchData();
                fetchCidade();
                fetchMarca()
            }

        }

    }, [urlCarroLink]);


    useEffect(() => {
        setUrlCarroLink(linkDaProposta)
    }, [linkDaProposta]);



    useEffect(() => {

        // console.log('passou aqui', idCidade)

        const fetchMunicipio = async () => {
            try {

                const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_MUNICIPIOS}?id_cidade=${idCidade}`;

                const response = await axios.get(requestUrl);
                // console.log('Municipio', response)
                setListaDeMunicipios(response.data.mensagem)


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (idCidade) {
            // console.log('correu', idCidade)
            fetchMunicipio();
        }

    }, [idCidade]);



    useEffect(() => {

        // console.log('passou aqui', idMarca)

        const fetchModelos = async () => {
            try {

                const [month, year] = dataDeRegisto.split('/');

                const requestUrl = `${process.env.REACT_APP_GET_BUSCAR_MODELOS}?id_marca=${idMarca}&ano=${year}`;

                const response = await axios.get(requestUrl);
                // console.log('setListaDeModelos', response)
                setListaDeModelos(response.data.mensagem)


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if ((idMarca && !listaDeModelos) || (idMarca && listaDeModelos)) {
            // console.log('correu', idMarca)
            fetchModelos();
        }

    }, [idMarca]);


    // console.log('Municipio', listaDeMunicipios)


    // CODIGO QUE SEJA EXATAMENTE O MESMO PARA OS DOIS PEDIDOS (AUTOSCOUT24 E MOBILE.DE ou + no futuro)
    function processosRepetidosNosPedidos(dataFinalDadosObjeto) {


        if (estadoDaPartilhaNomeDaProposta) {
            setDataCarros(dataFinalDadosObjeto)
        }

        if (dataFinalDadosObjeto.novo_ou_usado === "1") {
            setNovoOuUsado('Novo')
        } else {
            setNovoOuUsado('Usado')
        }

        // IVA NA ORIGEM
        setIvaNaOrigem(dataFinalDadosObjeto.tem_iva)
        setValorIvaNaOrigem(dataFinalDadosObjeto.perc_iva)


        // Tipo de Combustivel
        setTipoDeCombustivel(dataFinalDadosObjeto.tipo_combustivel_traduzido)

        // CILINDRADA
        setCilindrada(dataFinalDadosObjeto.cilindrada)

        // EMISSOES
        setEmissoesGases(dataFinalDadosObjeto.emissoes_numerico)

        // Data primeiro Registo
        setDataDeRegisto(dataFinalDadosObjeto.data_registo ? dataFinalDadosObjeto.data_registo : '')



        // TIPO DE TESTES:
        //  setTipoDeTestes('NEDC');
        setValidarAnoDeRegisto(false)
        // setAnoDoRegisto(false)

        // PARA CIMA DE 10/2018 - WLTP POR DEFEITO
        let data_registo = dataFinalDadosObjeto.data_registo;

        // PRECO DA VIATURA (COM IVA)
        const precoViatura = dataFinalDadosObjeto.preco_final_carro ? dataFinalDadosObjeto.preco_final_carro : "";
        setPrecoFinalCarro(precoViatura)


        setPrecoFinalDoCarroIvaDedutival(false)
        let precoViaturaSemIva = precoViatura;

        if (dataFinalDadosObjeto.preco_final_carro_sem_iva) { // SE EXISTIR SEM IVA
            setPrecoFinalDoCarroIvaDedutival(true)
            precoViaturaSemIva = dataFinalDadosObjeto.preco_final_carro_sem_iva;
        }
        setPrecoFinalDoCarroSemIva(precoViaturaSemIva)

        /*  //validar a quantidade de KM
          let quantidadeKM = dataFinalDadosObjeto.quantidade_de_km ? dataFinalDadosObjeto.quantidade_de_km : "";
  
          if (quantidadeKM) {
              quantidadeKM = parseFloat(quantidadeKM.toString().replace(",", ""));
          }*/

        let tipo_combustivel_raw = dataFinalDadosObjeto.tipo_de_combustivel ? dataFinalDadosObjeto.tipo_de_combustivel : "";
        let tipo_combustivel = dataFinalDadosObjeto.tipo_combustivel_traduzido ? dataFinalDadosObjeto.tipo_combustivel_traduzido : "";

        if (data_registo) {

            // SEPARAR AS DATA EM DUAS VARIAVEIS
            let [data_registo_month, data_registo_year] = data_registo.split('/');
            // CRIAR COMO DATA (ANO, MES)
            data_registo = new Date(data_registo_year, data_registo_month - 1);
            // CRIAR A DATA DO WLTP
            let data_wltp = new Date(2018, 10 - 1);

            // COMPARAR DATAS
            if (data_registo > data_wltp) {
                //setTipoDeTestes('WLTP');
                //   setWltpIsChecked(true);
                setValidarAnoDeRegisto(true)
            }


            /* //Validar o ano do Registo
             const validarAnoDoRegisto = dataDeRegisto
             // Convertendo a string para um objeto Date
             const dataRegistro = new Date(validarAnoDoRegisto);
             // Obtendo a data atual
             const dataAtual = new Date();
             // Adicionando 6 meses à data atual para obter a data limite
             const dataLimite = new Date();
             dataLimite.setMonth(dataAtual.getMonth() + 6);
             // Comparando as datas
             if (dataRegistro <= dataLimite) {
                 setAnoDoRegisto(true)
             }*/

        }


        //validar a quantidade de KM
        /*  if (quantidadeKM < 6000) {
  
              setQuantidadeDeKM(true)
          } else {
  
              setQuantidadeDeKM(false)
          }*/


        //Validar eletrico
        if (tipo_combustivel_raw === "Electric") {
            setValidarViaturaEletrico(true)
            setValorFinalISV('0€')
            setAlertasNoPedido(false)
            setValidarAnoDeRegisto(false)
            setCo2IsChecked(true)
        } else {
            setValidarViaturaEletrico(false)
        }


        //validar hibrido
        if (tipo_combustivel_raw === "Hybrid (petrol/electric)") {
            setHibridoIsChecked(true)
            setValorFinalISV('0€')
            setCo2IsChecked(false)
            setTipoDeCombustivel('Gasolina')

        } else if (tipo_combustivel_raw === "Hybrid (diesel/electric)") {
            setHibridoIsChecked(true)
            setValorFinalISV('0€')
            setCo2IsChecked(false)
            setTipoDeCombustivel('Gasoleo')
            tipo_combustivel = 'Gasoleo'
        } else {
            setHibridoIsChecked(false)
        }



        // DEFINIR PARTICULAS SE FOR GASOLEO OU GASOLINA
        /*if (tipo_combustivel.toLowerCase() == "gasoleo") {
            setParticulas('1')
        } else {
            setParticulas('0')
        }*/





        if (!estadoDaPartilhaNomeDaProposta) {
            setLoading(false);
        }

        setEsconde(true);

    }




    useEffect(() => {

        // console.log('corre')

        if (IgnorarAvisos || estadoDaPartilhaNomeDaProposta || !urlCarroLink) {
            return;
        }


        if (validarAnoDeRegisto) {
            setValorFinalISV('0')
            if (!co2IsChecked) {
                setAlertasNoPedido(true)
                return
            }
        }



        /* if (!hibridoValidationIsChecked && hibridoIsChecked) {
             setValorFinalISV('0€')
             return;
         }*/



        /*  if (hibridoIsChecked && hibridoValidationIsChecked && emissoesGases >= 50) {
              setValorFinalISV('0€')
              return;
          }*/


        let ignore_isv = false;
        // SE FOR ELETRICO N FAZ PEDIDO
        if (validarViaturaEletrico) {
            setValorFinalISV('0€')
            setAlertasNoPedido(false)
            ignore_isv = true;
        }



        // SE NAO CONFIRMO EMISSOES CO2
        if (alertasNoPedido) {
            setValorFinalISV('0€')
            return
        }


        let pedidoChaveNaMao
        let pedidoIUC

        // if (hibridoIsChecked) {
        //     tipoDeVeiculoFinal = 'HibridosPlugIn';
        // }

        /*     let tipoDeTestes = 'NEDC';
     
     
     
             // WLTP
             if (wltpIsChecked) {
                 tipoDeTestes = 'WLTP';
             }
     */

        if (typeof urlCarroLink === '' && estadoDaPartilhaNomeDaProposta) {
            return;
        }

        let novo_usado_int = 0;
        if (novoOuUsado === "Novo") {

            novo_usado_int = 1;
        }


        if (!ignore_isv) {
            pedidoChaveNaMao = `${process.env.REACT_APP_PEDIDO_ISV_ES}?cilindrada=${cilindrada}&novo=${novo_usado_int}&combustivel=${tipoDeCombustivel}&co2=${emissoesGases}&ano=${dataDeRegisto}&isencao=${tipoDeVeiculoParaInsencoes}&id_modelo=${idModelo}&id_cidade=${idCidade}&valor_viatura_nova=${valorViaturaNova}`;

            axios.get(pedidoChaveNaMao)
                .then((response) => {
                    console.log('response-pedidoChaveNaMao', response)

                    setValorFinalISV(response.data.isv)
                    setDataGeralISV(response.data)

                    setIsvComunidad(response.data.taxas.isv_comunidad)
                    setIsvValoracionInicial(response.data.taxas.isv_valoracion_inicial)
                    setIsvPorcentajeaAplicar(response.data.taxas.isv_porcentaje_aplicar)
                    setIsvMinoracion(response.data.taxas.isv_minoracion)
                    setIsvBaseImponible(response.data.taxas.isv_base_imponible)
                    setIsvReduccionBaseImponible(response.data.taxas.isv_reduccion_base_imponible)
                    setIsvBaseImponibleFinal(response.data.taxas.isv_base_imponible_final)
                    setIsvCuotaTributaria(response.data.taxas.cuota_tributaria)
                    setIsvTotalaPagar(response.data.taxas.isv_total_pagar)


                    // setIsvComponenteCilindrada(response.data.taxas.componente_cilindrada)
                    // setIsvcomponenteambiental(response.data.taxas.componente_ambiental)
                    // setIsvReducaoAnosUsoCilindrada(response.data.taxas.reducao_anos_uso_cilindrada)
                    // setIsvReducaoAnosUsoAmbiental(response.data.taxas.reducao_anos_uso_ambiental)
                    // setIsvAgravamentoParticulas(response.data.taxas.agravamento_particulas)
                    // setIsvReducaoAnosUsoParticulas(response.data.taxas.reducao_anos_uso_particulas)
                    // setIsvTaxaAplicavelTabela(response.data.taxas.taxa_aplicavel_tabela)

                })
                .catch((error) => {

                });
        }



        // pedidoIUC = `${process.env.REACT_APP_PEDIDO_IUC_ES}?cilindrada=${cilindrada}&ano=${dataDeRegisto}&combustivel=${tipoDeCombustivel}&co2=${emissoesGases}&tipo_carro=${tipoDeVeiculoParaOutros}&tipo_de_testes_co2=${tipoDeTestes}&novo=${novo_usado_int}`;

        pedidoIUC = `${process.env.REACT_APP_PEDIDO_IUC_ES}?id_modelo=${idModelo}&id_municipio=${idMunicipio}&tipo_carro=${tipoCarro}`;


        axios.get(pedidoIUC)
            .then((response) => {
                // console.log('response', response)
                setValorFinalIUC(response.data.iuc)
                setDataGeralIUC(response.data)


                setIucCavalosFiscais(response.data.taxas.iuc_cavalos_fiscais)
                setIucCidade(response.data.taxas.iuc_cidade)
                setIucMunicipio(response.data.taxas.iuc_municipio)
                setIucPercentagemDescontoEletrico(response.data.taxas.iuc_percentagem_desconto_eletrico)




                // setIucCilindrada(response.data.taxas.cilindrada)
                // setIucCO2(response.data.taxas.co2)
                // setIucAdicionalCO2(response.data.taxas.adicional_co2)
                // setIucCoeficienteAnoMatriculo(response.data.taxas.coeficiente_ano_matricula)
                // setIucAdicionalGasoleo(response.data.taxas.adicional_gasoleo)

            })
            .catch((error) => {

            });



    }, [idCidade, idMunicipio, valorViaturaNova, co2IsChecked, hibridoIsChecked, tipoDeCombustivel, emissoesGases, cilindrada, dataDeRegisto, tipoDeVeiculoParaInsencoes, novoOuUsado]);

    // hibridoValidationIsChecked, wltpIsChecked, particulas, tipoDeTestes,




    useEffect(() => {


        let chaveNaMaoValue = ""
        let precoFinalCarroCal = ""

        let taxaDeServicoCal = parseFloat(taxaDeServico);
        let valorFinalISVCal = parseFloat(valorFinalISV);

        if (isNaN(taxaDeServicoCal)) {
            taxaDeServicoCal = 0;
        }

        // Particular
        precoFinalCarroCal = parseFloat(precoFinalCarro);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            setValorChaveNaMaoParticular(chaveNaMaoValue);
            setValorChaveNaMaoParaCredito(chaveNaMaoValue)
        } else {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            setValorChaveNaMaoParticular(chaveNaMaoValue);
            setValorChaveNaMaoParaCredito(chaveNaMaoValue)
        }

        // EMPRESA
        precoFinalCarroCal = parseFloat(precoFinalDoCarroSemIva);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
            setValorChaveNaMaoEmpresa(chaveNaMaoValue);
        } else {
            chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
            // Round up no decimals
            setValorChaveNaMaoEmpresa(chaveNaMaoValue);
        }


    }, [precoFinalCarro, taxaDeServico, valorFinalISV]);


    const tituloDaPropostaChaveNaMaoComLinkFinal = () => (
        <a href={urlCarroLink}>{tituloDaPropostaChaveNaMao}</a>
    );


    const tituloDaPropostaChaveNaMaoComLinkFinalParticular = () => (
        <a href={urlCarroLink}>{tituloDaPropostaChaveNaMaoParticular}</a>
    );


    return (


        <>


            <div className='w-full flex flex-col justify-center items-center gap-4'>
                <div className='grid gap-4 grid-cols-1 w-full h-full'>
                    {!estadoDaPartilhaNomeDaProposta && iDTokenAuthenticated ? (
                        <div className='card'>
                            <div className='titulo-form'>
                                <p>{t('simulador.titulo_principal')}</p>
                            </div>
                            <PesquisaSimulacao
                                setEstadoDaPartilhaNomeDaProposta={setEstadoDaPartilhaNomeDaProposta}
                                setEsconde={setEsconde}
                                urlCarroLink={urlCarroLink}
                                setUrlCarroLink={setUrlCarroLink}
                            />
                        </div>
                    ) : null}
                    {/* {teste && iDTokenAuthenticated ? (
                        <div className='card'>
                            <Link to={`/${currentLanguage}/${t('header.propostas')}`} onClick={handleRestoreMainState}>
                                <Button
                                    type="primary"
                                    target="_blank"
                                    className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                >
                                    <IoArrowBack />
                                    Volver a las Propuestas
                                </Button>
                            </Link>

                        </div>
                    ) : null} */}
                    <div className="w-full text-white">
                        <div>
                            {esconde ? (
                                <>
                                    {loading ? (
                                        <Loading />
                                    ) : (
                                        <div className='grid gap-4'>
                                            {/* 
                                            {estadoDaPartilhaNomeDaProposta && !iDTokenAuthenticated ? (
                                                <HeaderSimulador
                                                    nomePerfilDataFuncionario={nomePerfilDataFuncionario}
                                                    fotoPerfilDataFuncionario={fotoPerfilDataFuncionario}
                                                    whatsappDataFuncionario={whatsappDataFuncionario}
                                                    emailDataInternoFuncionario={emailDataInternoFuncionario}
                                                />
                                            ) : null} */}

                                            <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                                <div className='w-full sm:w-3/5'>
                                                    {dataCarros ? (
                                                        <div>
                                                            {/* <div className='tab-container-custom flex flex-col sm:flex-row justify-between items-center gap-4'>
                                                                {estadoDaPartilhaNomeDaProposta && isMobile ? (
                                                                    <Button
                                                                        type="primary"
                                                                        htmlType="submit"
                                                                        href={allDataLinkPartilhado?.url}
                                                                        target="_blank"
                                                                        className="bg-white text-black font-bold rounded"
                                                                    >
                                                                        Ver anuncio original
                                                                    </Button>
                                                                ) : null}
                                                                <div className='flex flex-row gap-4'>
                                                                    <div
                                                                        className={`tab-custom ${isParticular ? 'custom-active' : ''}`}
                                                                        onClick={() => setIsParticular(true)}>
                                                                        Particular
                                                                    </div>
                                                                    <div
                                                                        className={`tab-custom ${!isParticular ? 'custom-active' : ''}`}
                                                                        onClick={() => setIsParticular(false)}>
                                                                        Empresa
                                                                    </div>
                                                                </div>
                                                                {estadoDaPartilhaNomeDaProposta && !isMobile ? (
                                                                    <Button
                                                                        type="primary"
                                                                        htmlType="submit"
                                                                        href={allDataLinkPartilhado?.url}
                                                                        target="_blank"
                                                                        className="bg-white text-black font-bold rounded"
                                                                    >
                                                                        Ver anuncio original
                                                                    </Button>
                                                                ) : null}
                                                            </div> */}

                                                            {estadoDaPartilhaNomeDaProposta ? (
                                                                <TabelaViaturaCliente
                                                                    nomeGeradoDaProposta={nomeGeradoDaProposta}
                                                                    allDataLinkPartilhado={allDataLinkPartilhado}
                                                                    formatarNumero={formatarNumero}
                                                                    dataCarros={dataCarros}
                                                                    isParticular={isParticular}
                                                                />
                                                            ) : (
                                                                <TabelaViatura
                                                                    currentLanguage={currentLanguage}
                                                                    formatarNumero={formatarNumero}
                                                                    dataCarros={dataCarros}
                                                                    isParticular={isParticular}
                                                                    urlCarroLink={urlCarroLink}
                                                                    valorMediaNacional={valorMediaNacional}
                                                                    setValorMediaNacional={setValorMediaNacional}
                                                                />
                                                            )}

                                                        </div>

                                                    ) : null}

                                                </div>
                                                <div className="w-full sm:w-2/5 flex flex-col gap-4">
                                                    {estadoDaPartilhaNomeDaProposta ? (
                                                        <>

                                                            <ES_CalculoChaveMaoVista
                                                                valorViaturaNova={valorViaturaNova}
                                                                setValorViaturaNova={setValorViaturaNova}
                                                                setListaDeModelos={setListaDeModelos}
                                                                setIdModelo={setIdModelo}
                                                                idModelo={idModelo}
                                                                listaDeModelos={listaDeModelos}
                                                                idMarca={idMarca}
                                                                setIdMarca={setIdMarca}
                                                                listaDeMarcas={listaDeMarcas}
                                                                setIdMunicipio={setIdMunicipio}
                                                                idMunicipio={idMunicipio}
                                                                listaDeMunicipios={listaDeMunicipios}
                                                                idCidade={idCidade}
                                                                setIdCidade={setIdCidade}
                                                                listaDeCidades={listaDeCidades}
                                                                allDataLinkPartilhado={allDataLinkPartilhado}
                                                                urlCarroLink={urlCarroLink}
                                                                validarViaturaEletrico={validarViaturaEletrico}
                                                                temParametros={temParametros}
                                                                dataDeRegisto={dataDeRegisto}
                                                                novoOuUsado={novoOuUsado}
                                                                tipoDeCombustivel={tipoDeCombustivel}
                                                                setTaxaDeServico={setTaxaDeServico}
                                                                hibridoIsChecked={hibridoIsChecked}
                                                                setCo2IsChecked={setCo2IsChecked}
                                                                valordaViaturaIsChecked={valordaViaturaIsChecked}
                                                                setValordaViaturaIsChecked={setValordaViaturaIsChecked}
                                                                //    setHibridoValidationIsChecked={setHibridoValidationIsChecked}
                                                                setNovoOuUsado={setNovoOuUsado}
                                                                setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                                                setOutrosServicos={setOutrosServicos}
                                                                setAlertasNoPedido={setAlertasNoPedido}
                                                                setCilindrada={setCilindrada}
                                                                setDataDeRegisto={setDataDeRegisto}
                                                                setEmissoesGases={setEmissoesGases}
                                                                // setHibridoIsChecked={setHibridoIsChecked}
                                                                setTipoDeCombustivel={setTipoDeCombustivel}
                                                                //  setParticulas={setParticulas}
                                                                //   setWltpIsChecked={setWltpIsChecked}
                                                                setTipoDeVeiculoParaInsencoes={setTipoDeVeiculoParaInsencoes}
                                                                alertasNoPedido={alertasNoPedido}
                                                                taxaDeServico={taxaDeServico}
                                                                tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                                                //    hibridoValidationIsChecked={hibridoValidationIsChecked}
                                                                outrosServicos={outrosServicos}
                                                                cilindrada={cilindrada}
                                                                emissoesGases={emissoesGases}
                                                                //    wltpIsChecked={wltpIsChecked}
                                                                //   particulas={particulas}
                                                                validarAnoDeRegisto={validarAnoDeRegisto}
                                                                co2IsChecked={co2IsChecked}
                                                                valorFinalISV={valorFinalISV}
                                                                dataGeralISV={dataGeralISV}
                                                                //     quantidadeDeKM={quantidadeDeKM}
                                                                //   anoDoRegisto={anoDoRegisto}
                                                                IgnorarAvisos={IgnorarAvisos}
                                                            />

                                                        </>
                                                    ) : (
                                                        <ES_CalculoChaveMao
                                                            valorViaturaNova={valorViaturaNova}
                                                            setValorViaturaNova={setValorViaturaNova}
                                                            setListaDeModelos={setListaDeModelos}
                                                            setIdModelo={setIdModelo}
                                                            idModelo={idModelo}
                                                            listaDeModelos={listaDeModelos}
                                                            idMarca={idMarca}
                                                            setIdMarca={setIdMarca}
                                                            listaDeMarcas={listaDeMarcas}
                                                            setIdMunicipio={setIdMunicipio}
                                                            idMunicipio={idMunicipio}
                                                            listaDeMunicipios={listaDeMunicipios}
                                                            idCidade={idCidade}
                                                            setIdCidade={setIdCidade}
                                                            listaDeCidades={listaDeCidades}
                                                            allDataLinkPartilhado={allDataLinkPartilhado}
                                                            urlCarroLink={urlCarroLink}
                                                            validarViaturaEletrico={validarViaturaEletrico}
                                                            temParametros={temParametros}
                                                            dataDeRegisto={dataDeRegisto}
                                                            novoOuUsado={novoOuUsado}
                                                            tipoDeCombustivel={tipoDeCombustivel}
                                                            setTaxaDeServico={setTaxaDeServico}
                                                            hibridoIsChecked={hibridoIsChecked}
                                                            setCo2IsChecked={setCo2IsChecked}
                                                            valordaViaturaIsChecked={valordaViaturaIsChecked}
                                                            setValordaViaturaIsChecked={setValordaViaturaIsChecked}
                                                            //    setHibridoValidationIsChecked={setHibridoValidationIsChecked}
                                                            setNovoOuUsado={setNovoOuUsado}
                                                            setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                                            setOutrosServicos={setOutrosServicos}
                                                            setAlertasNoPedido={setAlertasNoPedido}
                                                            setCilindrada={setCilindrada}
                                                            setDataDeRegisto={setDataDeRegisto}
                                                            setEmissoesGases={setEmissoesGases}
                                                            // setHibridoIsChecked={setHibridoIsChecked}
                                                            setTipoDeCombustivel={setTipoDeCombustivel}
                                                            //  setParticulas={setParticulas}
                                                            //   setWltpIsChecked={setWltpIsChecked}
                                                            setTipoDeVeiculoParaInsencoes={setTipoDeVeiculoParaInsencoes}
                                                            alertasNoPedido={alertasNoPedido}
                                                            taxaDeServico={taxaDeServico}
                                                            tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                                            //    hibridoValidationIsChecked={hibridoValidationIsChecked}
                                                            outrosServicos={outrosServicos}
                                                            cilindrada={cilindrada}
                                                            emissoesGases={emissoesGases}
                                                            //    wltpIsChecked={wltpIsChecked}
                                                            //   particulas={particulas}
                                                            validarAnoDeRegisto={validarAnoDeRegisto}
                                                            co2IsChecked={co2IsChecked}
                                                            valorFinalISV={valorFinalISV}
                                                            dataGeralISV={dataGeralISV}
                                                            //     quantidadeDeKM={quantidadeDeKM}
                                                            //   anoDoRegisto={anoDoRegisto}
                                                            IgnorarAvisos={IgnorarAvisos}
                                                        />
                                                    )}


                                                    <ES_ResultadodaSimulacao
                                                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                                        setActiveTabKeyResultadoSimulacao={setActiveTabKeyResultadoSimulacao}
                                                        formatarNumero={formatarNumero}
                                                        valorFinalIUC={valorFinalIUC}
                                                        valorFinalISV={valorFinalISV}

                                                        iucCavalosFiscais={iucCavalosFiscais}
                                                        iucCidade={iucCidade}
                                                        iucMunicipio={iucMunicipio}
                                                        iucPercentagemDescontoEletrico={iucPercentagemDescontoEletrico}
                                                        isvComunidad={isvComunidad}
                                                        isvValoracionInicial={isvValoracionInicial}
                                                        isvPorcentajeaAplicar={isvPorcentajeaAplicar}
                                                        isvMinoracion={isvMinoracion}
                                                        isvBaseImponible={isvBaseImponible}
                                                        isvReduccionBaseImponible={isvReduccionBaseImponible}
                                                        isvBaseImponibleFinal={isvBaseImponibleFinal}
                                                        isvCuotaTributaria={isvCuotaTributaria}
                                                        isvTotalaPagar={isvTotalaPagar}
                                                        // iucCilindrada={iucCilindrada}
                                                        // iucCO2={iucCO2}
                                                        // iucAdicionalCO2={iucAdicionalCO2}
                                                        // iucCoeficienteAnoMatriculo={iucCoeficienteAnoMatriculo}
                                                        // iucAdicionalGasoleo={iucAdicionalGasoleo}
                                                        // isvcomponentecilindrada={isvcomponentecilindrada}
                                                        // isvcomponenteambiental={isvcomponenteambiental}
                                                        // isvReducaoAnosUsoCilindrada={isvReducaoAnosUsoCilindrada}
                                                        // isvReducaoAnosUsoAmbiental={isvReducaoAnosUsoAmbiental}
                                                        // isvAgravamentoParticulas={isvAgravamentoParticulas}
                                                        // isvReducaoAnosUsoParticulas={isvReducaoAnosUsoParticulas}
                                                        // isvTaxaAplicavelTabela={isvTaxaAplicavelTabela}
                                                        dataGeralIUC={dataGeralIUC}
                                                        taxaDeServico={taxaDeServico}
                                                        tituloDaPropostaChaveNaMao={tituloDaPropostaChaveNaMao}
                                                        precoFinalCarro={precoFinalCarro}
                                                        valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                                        valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                                        temParametros={temParametros}
                                                        tituloDaPropostaChaveNaMaoComLinkFinal={tituloDaPropostaChaveNaMaoComLinkFinal}
                                                        setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                                        dataCarros={dataCarros}
                                                        urlCarroLink={urlCarroLink}
                                                        precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                                        tituloDaPropostaChaveNaMaoComLinkFinalParticular={tituloDaPropostaChaveNaMaoComLinkFinalParticular}
                                                        setTabResultadoSimulacao={setTabResultadoSimulacao}
                                                        tabResultadoSimulacao={tabResultadoSimulacao}
                                                        iDTokenAuthenticated={iDTokenAuthenticated}
                                                        estadoDaPartilhaNomeDaProposta={estadoDaPartilhaNomeDaProposta}

                                                    />


                                                    {!estadoDaPartilhaNomeDaProposta ? (
                                                        <>
                                                            {/* <CreditoMainComp
                                                                formatarNumero={formatarNumero}
                                                                taxaDeJuroCredito={taxaDeJuroCredito}
                                                                precoFinalCarro={precoFinalCarro}
                                                                precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                                                entradaCredito={entradaCredito}
                                                                setEntradaCredito={setEntradaCredito}
                                                                valorFinanciadoCredito={valorFinanciadoCredito}
                                                                setValorFinanciadoCredito={setValorFinanciadoCredito}
                                                                prestacoesMesesCredito={prestacoesMesesCredito}
                                                                setPrestacoesMesesCredito={setPrestacoesMesesCredito}
                                                                ivaNaOrigem={ivaNaOrigem}
                                                                valorFinalCredito={valorFinalCredito}
                                                                setValorFinalCredito={setValorFinalCredito}
                                                                valorIvaNaOrigem={valorIvaNaOrigem}
                                                                activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                                                activeTabKeySimulacaoCredito={activeTabKeySimulacaoCredito}
                                                                setActiveTabKeySimulacaoCredito={setActiveTabKeySimulacaoCredito}
                                                                setTabResultadoSimulacao={setTabResultadoSimulacao}
                                                                valorFinalISV={valorFinalISV}
                                                                taxaDeServico={taxaDeServico}
                                                                valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                                                valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                                                valorChaveNaMaoParaCredito={valorChaveNaMaoParaCredito}
                                                                setValorChaveNaMaoParaCredito={setValorChaveNaMaoParaCredito}
                                                            /> */}


                                                            <ES_PartilharProposta
                                                                currentLanguage={currentLanguage}
                                                                idModelo={idModelo}
                                                                idMarca={idMarca}
                                                                valorViaturaNova={valorViaturaNova}
                                                                idCidade={idCidade}
                                                                idMunicipio={idMunicipio}
                                                                cilindrada={cilindrada}
                                                                novoOuUsado={novoOuUsado}
                                                                tipoDeCombustivel={tipoDeCombustivel}
                                                                emissoesGases={emissoesGases}
                                                                dataDeRegisto={dataDeRegisto}
                                                                //  particulas={particulas}
                                                                tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                                                outrosServicos={outrosServicos}

                                                                precoFinalCarro={precoFinalCarro}
                                                                valorFinalISV={valorFinalISV}
                                                                taxaDeServico={taxaDeServico}

                                                                valorFinalIUC={valorFinalIUC}
                                                                // iucCilindrada={iucCilindrada}
                                                                // iucCO2={iucCO2}
                                                                // iucAdicionalCO2={iucAdicionalCO2}
                                                                // iucCoeficienteAnoMatriculo={iucCoeficienteAnoMatriculo}
                                                                // iucAdicionalGasoleo={iucAdicionalGasoleo}



                                                                iucCavalosFiscais={iucCavalosFiscais}
                                                                iucCidade={iucCidade}
                                                                iucMunicipio={iucMunicipio}
                                                                iucPercentagemDescontoEletrico={iucPercentagemDescontoEletrico}
                                                                isvComunidad={isvComunidad}
                                                                isvValoracionInicial={isvValoracionInicial}
                                                                isvPorcentajeaAplicar={isvPorcentajeaAplicar}
                                                                isvMinoracion={isvMinoracion}
                                                                isvBaseImponible={isvBaseImponible}
                                                                isvReduccionBaseImponible={isvReduccionBaseImponible}
                                                                isvBaseImponibleFinal={isvBaseImponibleFinal}
                                                                isvCuotaTributaria={isvCuotaTributaria}
                                                                isvTotalaPagar={isvTotalaPagar}


                                                                activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                                                tabResultadoSimulacao={tabResultadoSimulacao}


                                                                setPartilhaNomeDaProposta={setPartilhaNomeDaProposta}
                                                                partilhaNomeDaProposta={partilhaNomeDaProposta}
                                                                tituloDaPropostaChaveNaMaoComLinkFinalParticular={tituloDaPropostaChaveNaMaoComLinkFinalParticular}
                                                                precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                                                valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                                                valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                                                formatarNumero={formatarNumero}
                                                                dataCarros={dataCarros}
                                                                setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                                                tituloDaPropostaChaveNaMao={tituloDaPropostaChaveNaMao}
                                                                urlCarroLink={urlCarroLink}
                                                                setNomeDaProposta={setNomeDaProposta}
                                                                nomeDaProposta={nomeDaProposta}
                                                                saveToken={saveToken}
                                                                iDTokenAuthenticated={iDTokenAuthenticated}
                                                            />
                                                        </>
                                                    ) : null}

                                                    {/* {estadoDaPartilhaNomeDaProposta ? (
                                                        <>
                                                            <FuncContactDetails nomePerfilDataFuncionario={nomePerfilDataFuncionario} fotoPerfilDataFuncionario={fotoPerfilDataFuncionario} whatsappDataFuncionario={whatsappDataFuncionario} emailDataInternoFuncionario={emailDataInternoFuncionario} />
                                                            <LinkPartilhaExpira infoInicialPartilhaProposta={infoInicialPartilhaProposta} />
                                                        </>
                                                    ) : null} */}

                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </>
                            ) : (
                                <></>
                            )}

                            {erroPedido && (
                                <ErroSection whatsappDataFuncionario={whatsappDataFuncionario} emailDataInternoFuncionario={emailDataInternoFuncionario} erroInicial={erroInicial} estadoDaPartilhaNomeDaProposta={estadoDaPartilhaNomeDaProposta} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
