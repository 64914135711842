import React from 'react';
import { BiError } from 'react-icons/bi';
import { Button } from "antd";
import { HiOutlineMail } from "react-icons/hi";
import { FaWhatsapp } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';


export default function ErroSection({ whatsappDataFuncionario, emailDataInternoFuncionario, erroInicial, estadoDaPartilhaNomeDaProposta }) {

    const { t } = useTranslation();

    // console.log(erroInicial)

    return (
        <>
            <div className='card flex items-center gap-5 flex-col justify-center text-lg rounded-md p-2.5'>
                <BiError className='text-white text-3xl' />
                <p className='text-white frase-style'>{erroInicial}</p>
                {estadoDaPartilhaNomeDaProposta ? (
                    <>
                        <div className='flex flex-col gap-4 items-center'>
                            <span className='text-md font-semibold'>{t('erro_section.entre_em_contacto')}</span>
                            <div className='flex gap-4'>
                                <Button type="primary" htmlType="button" onClick={() => window.open(`${whatsappDataFuncionario}`, '_blank')} className="btn-green bg-green-500 hover:bg-green-500 text-white rounded flex items-center gap-2">
                                    <FaWhatsapp className='text-2xl' /> Whatsapp
                                </Button>

                                <Button type="primary" htmlType="button" onClick={() => window.open(`mailto:${emailDataInternoFuncionario}`, '_blank')} className="btn-blue bg-blue-500 text-white rounded flex items-center gap-2">
                                    <HiOutlineMail className='text-2xl' /> Email
                                </Button>
                            </div>
                        </div>
                    </>
                ) : null}
            </div >
        </>
    );


}