import React, { useEffect, useState } from 'react';
import { Input, Table, Image, Spin, Button, Checkbox, Modal } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import redbubble from '../../../assets/red-circle.svg';
import greenbubble from '../../../assets/green-circle.svg';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { IoAddCircleOutline, IoEyeOutline } from 'react-icons/io5';
import { IoCloseCircleOutline } from "react-icons/io5";
import AssociarDeal from '../hubspot/deals/associar-deal';
// import logo from '../../../assets/hubspot.svg';
import logo from '../../../assets/hubspot-icon.svg';
import logoCor from '../../../assets/hubspot-icon-cor.svg';
import HistoricoDePesquisasPropostas from '../historico-de-acessos-proposta';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoMdAdd } from 'react-icons/io';



const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function TabeladePropostas({
    currentLanguage,
    saveToken,
    iDTokenAuthenticated,
    formatarNumero,
    idDaPropostaEncontrada,
    showHubspot,
    setShowHubspot,
    checkDataHubspot,
    setCheckDataHubspot,
    nomeDoDeal,
    setNomeDoDeal,
    nomeDoClienteDeal,
    setNomeDoClienteDeal,
    emailDoClienteDeal,
    setEmailDoClienteDeal,
    dataDeAssociacaoDoDeal,
    setDataDeAssociacaoDoDeal,
    etapaDoDeal,
    setEtapaDoDeal,
    pipelineDoDeal,
    setPipelineDoDeal
}) {


    console.log(nomeDoClienteDeal, nomeDoDeal, emailDoClienteDeal)


    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [propostaData, setPropostaData] = useState([]);
    const [historicoDeAcessos, setHistoricoDeAcessos] = useState([]);
    const [historicoSelecionadodeAcessos, setHistoricoSelecionadodeAcessos] = useState([]);

    const [isConfigHubspotOpen, setIsConfigHubspotOpen] = useState(false);

    const [selectedPropostaParaHubspot, setSelectedPropostaParaHubspot] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenHistorico, setIsModalOpenHistorico] = useState(false);
    const [isModalOpenPerfilDoDeal, setIsModalOpenPerfilDoDeal] = useState(false);


    const [hubspotData, setHubspotData] = useState('');
    const [loadingHubspot, setLoadingHubspot] = useState(false);


    useEffect(() => {

        setLoading(true)

        const url = `${process.env.REACT_APP_GET_PROPOSTAS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&lang=${currentLanguage}`;

        axios.get(url)
            .then((response) => {

                setLoading(false)
                setPropostaData(response.data);
                // console.log(response.data)
                const historico = response.data.map((item) => ({
                    acessos: item.ultimos_acessos,
                    id: item.id_proposta
                }));
                // console.log('historico', historico)
                setHistoricoDeAcessos(historico);

            })
            .catch((error) => {
                setLoading(true)
                console.error('Error occurred:', error);
            });
    }, []);





    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredPropostas = propostaData.filter((data) => {
        const nomeDaProposta = data.nome_proposta.toLowerCase();
        const nomeDoFuncionario = data.user_data.nome_criou.toLowerCase();
        const paisDaProposta = data.pais.toLowerCase();

        const searchQueryLowerCase = searchQuery.toLowerCase();

        return (
            nomeDaProposta.includes(searchQueryLowerCase) ||
            nomeDoFuncionario.includes(searchQueryLowerCase) ||
            paisDaProposta.includes(searchQueryLowerCase)
        );
    });


    // console.log(parametrodepesquisa)

    const handleHabilitarHubSpotConfig = () => {
        setSelectedPropostaParaHubspot([])
        setIsConfigHubspotOpen(!isConfigHubspotOpen)
    };


    const onSelectChange = (propostaId) => {
        setSelectedPropostaParaHubspot(prevSelected => {
            if (prevSelected.includes(propostaId)) {

                // Remove o ID da proposta do array
                return prevSelected.filter(id => id !== propostaId);
            } else {


                // Adiciona o ID da proposta ao array
                return [...prevSelected, propostaId];
            }
        });
    };


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalHistorico = (value) => {
        setIsModalOpenHistorico(true);
        const selectedOption = historicoDeAcessos.find(option => option.id === value);
        setHistoricoSelecionadodeAcessos(selectedOption)
    };

    const handleOkHistorico = () => {
        setIsModalOpenHistorico(false);
    };

    const handleCancelHistorico = () => {
        setIsModalOpenHistorico(false);
    };




    const showModalPerfilDeal = (valueHub, valueProposta) => {

        setLoadingHubspot(true)
        setIsModalOpenPerfilDoDeal(true);

        const url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&id_deal=${valueHub}&lang=${currentLanguage}&id_proposta=${valueProposta}`;

        axios.get(url)
            .then((response) => {
                console.log(response)

                setHubspotData(response.data.deal)
                setLoadingHubspot(false)

            })
            .catch((error) => {
                setLoading(true)
                console.error('Error occurred:', error);
            });

    };

    const handleOkPerfilDeal = () => {
        setIsModalOpenPerfilDoDeal(false);
    };

    const handleCancelPerfilDeal = () => {
        setIsModalOpenPerfilDoDeal(false);
    };


    console.log(filteredPropostas)

    return (
        <>
            {loading ? (
                <>
                    <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                        <Spin indicator={antIcon} />
                        <p className='text-black text-center'>{t('media_mercado.loading')}</p>
                    </div>
                </>
            ) : (
                <>
                    <div className='card'>
                        <div className='border-b-2 border-solid border-white flex items-center gap-4 pb-4'>
                            <p className='font-semibold'>{t('propostas.titulo_principal')}</p>
                            <div>|</div>
                            {currentLanguage === "pt" && (
                                <>
                                    <Button
                                        className="flex gap-2 hubspot-btn items-center font-bold rounded"
                                        onClick={handleHabilitarHubSpotConfig}
                                    >
                                        {isConfigHubspotOpen ? (
                                            <div className='flex items-center gap-2'>
                                                <IoCloseCircleOutline className='iconc' />
                                                Fechar configurações
                                            </div>
                                        ) : (
                                            <div className='flex items-center gap-2'>
                                                <img
                                                    src={logo}
                                                    alt="Logo"
                                                    className="w-6 object-contain"
                                                />
                                                Atribuir propostas
                                            </div>
                                        )}
                                    </Button>
                                    <div>|</div>
                                </>
                            )}
                            <Link to={`/${currentLanguage}/simulador`}>
                                <Button
                                    type="primary"
                                    target="_blank"
                                    className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                >
                                    <IoMdAdd /> {t('propostas.criarpropostas')}
                                </Button>
                            </Link>
                        </div>
                        <div className="mt-4">
                            <Input
                                type="text"
                                placeholder={t('propostas.input')}
                                value={searchQuery}
                                onChange={handleInputChange}
                            />
                        </div>

                        {currentLanguage === "pt" && (
                            <>
                                <div className='mt-4 flex justify-end gap-4 items-center'>
                                    {selectedPropostaParaHubspot.length > 0 ? (
                                        <Button type="primary" className="flex items-center gap-2 bg-white text-black font-bold rounded" onClick={showModal}>
                                            <img
                                                src={logoCor}
                                                alt="Logo"
                                                className="w-6 object-contain"
                                            />
                                            Associar
                                        </Button>
                                    ) : null}
                                </div>
                                <br />
                            </>
                        )}
                        {currentLanguage === "es" && (
                            <br />
                        )}
                        <div className="w-full">
                            <div className="tabela-propostas">
                                <Table
                                    dataSource={filteredPropostas}
                                    rowKey={(proposta) => proposta.id_proposta}
                                    scroll={{ x: 'max-content' }}
                                    bordered
                                // pagination={{
                                //     position: ['bottomCenter']
                                // }}
                                >
                                    {isConfigHubspotOpen ? (
                                        <>
                                            <Table.Column
                                                title="Ação"
                                                key="select"
                                                width={100}
                                                fixed={'left'}
                                                className='text-center column-hubspot'
                                                render={(proposta) => (
                                                    <Checkbox
                                                        checked={selectedPropostaParaHubspot.includes(proposta.id_proposta)}
                                                        onChange={() => onSelectChange(proposta.id_proposta)}
                                                    />
                                                )}
                                            />
                                            <Table.Column
                                                title="Associado"
                                                key="associado"
                                                width={100}
                                                fixed={'left'}
                                                className='text-center column-hubspot'
                                                render={(proposta) => (
                                                    proposta.id_deal_hubspot ? (
                                                        <span className='flex justify-center cursor-pointer' onClick={() => showModalPerfilDeal(proposta.id_deal_hubspot)}>
                                                            <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />
                                                        </span>
                                                    ) : (
                                                        <span className='flex justify-center'>
                                                            <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                                                        </span>

                                                    )
                                                )}
                                            />
                                        </>
                                    ) : null}
                                    <Table.Column
                                        title={t('propostas.criado_por')}
                                        key="criado_por"
                                        width={100}
                                        fixed={!isConfigHubspotOpen ? "left" : undefined}
                                        render={(proposta) => (
                                            <span>{proposta.user_data.nome_criou}</span>
                                        )}
                                    />

                                    <Table.Column
                                        title="País"
                                        key="pais"
                                        render={(proposta) => (
                                            <span className='uppercase'>{proposta.pais}</span>
                                        )}
                                    />

                                    <Table.Column
                                        title={t('propostas.nome_da_proposta')}
                                        key="nomedaproposta"
                                        render={(proposta) => (
                                            <>
                                                <Link to={`/${currentLanguage}/simulador/${proposta.expirou == 0 ? `?${t('propostas.nome_proposta_link')}` : `?${t('propostas.id_proposta_link')}`}=${proposta.expirou == 0 ? proposta.url_acesso : proposta.unique_id}`}>{proposta.nome_proposta}</Link>
                                            </>
                                        )}
                                    />


                                    <Table.Column
                                        title={t('propostas.data')}
                                        key="data"
                                        render={(proposta) => (
                                            <span>{proposta.data_criacao}</span>
                                        )}
                                    />


                                    <Table.Column
                                        title={t('ultimas_pesquisas.imagem_viatura')}
                                        className='text-center'
                                        render={(proposta) => (
                                            <Image
                                                style={{ textAlign: 'center' }}
                                                width={60}
                                                src={proposta.info?.imagens?.[0]}
                                            />
                                        )}
                                    />
                                    <Table.Column
                                        title={t('propostas.marca')}
                                        key="marca"
                                        render={(proposta) => (
                                            <span>{proposta.caracteristicas.marca}</span>
                                        )}
                                    />
                                    <Table.Column
                                        title={t('propostas.modelo')}
                                        key="modelo"
                                        render={(proposta) => (
                                            <span>{proposta.caracteristicas.modelo}</span>
                                        )}
                                    />
                                    <Table.Column
                                        title={t('propostas.sociedade')}
                                        key="sociedade"
                                        render={(proposta) => (
                                            proposta.info.empresa === '0' ? (
                                                <span>Particular</span>
                                            ) : (
                                                proposta.info.empresa === '1' ? (
                                                    <span>Empresa</span>
                                                ) : (
                                                    <span>Sem dados</span>
                                                )
                                            )
                                        )}
                                    />

                                    <Table.Column
                                        title={t('propostas.preco_da_viatura')}
                                        key="precoviatura"
                                        render={(proposta) => (
                                            proposta.info.preco_final_carro ? `${formatarNumero(proposta.info.preco_final_carro)}€` : "0€"
                                        )}
                                    />
                                    <Table.Column
                                        title={t('main.isv')}
                                        key="isv"
                                        render={(proposta) => (
                                            proposta.info.isv ? `${formatarNumero(proposta.info.isv)}€` : "0€"
                                        )}
                                    />
                                    <Table.Column
                                        title={t('propostas.taxa_de_servico')}
                                        key="taxadeservico"
                                        render={(proposta) => (
                                            proposta.info.taxadeservico ? `${formatarNumero(proposta.info.taxadeservico)}€` : "0€"
                                        )}
                                    />
                                    <Table.Column
                                        title={t('propostas.preco_chave_da_mao')}
                                        key="precochavenamao"
                                        render={(proposta) => (
                                            proposta.info.valor_chavenamao_particular ? `${formatarNumero(proposta.info.valor_chavenamao_particular)}€` : "0€"
                                        )}
                                    />

                                    <Table.Column
                                        title={t('propostas.acedido')}
                                        key="acedido"
                                        render={(proposta) => (
                                            proposta.acedido == 0 ? <span className='flex justify-center'><img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" /></span> : <div className='grid gap-1'><span onClick={() => showModalHistorico(proposta.id_proposta)} className='cursor-pointer flex gap-2 flex-row items-center justify-center'><img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />{proposta.acedido}</span><span className='label-tabelaproposta'>{proposta.ultimo_acesso}</span></div>
                                        )}
                                    />

                                    <Table.Column
                                        title={t('propostas.disponivel')}
                                        key="expirou"
                                        render={(proposta) => (
                                            proposta.expirou == 1 ? (
                                                <span className='flex justify-center'>
                                                    <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                                                </span>
                                            ) : (
                                                <span className='flex justify-center' >
                                                    <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />
                                                </span>

                                            )

                                        )}
                                    />
                                    {currentLanguage === "pt" && (
                                        <>
                                            {!isConfigHubspotOpen ? (
                                                <>
                                                    <Table.Column
                                                        title="Associado"
                                                        key="associado"
                                                        className='text-center column-hubspot'
                                                        render={(proposta) => (
                                                            proposta.id_deal_hubspot ? (
                                                                <span className='flex justify-center cursor-pointer' onClick={() => showModalPerfilDeal(proposta.id_deal_hubspot, proposta.id_proposta)}>
                                                                    <img style={{ height: '20px' }} src={greenbubble} alt="Green Bubble" />
                                                                </span>
                                                            ) : (
                                                                <span className='flex justify-center'>
                                                                    <img style={{ height: '20px' }} src={redbubble} alt="Red Bubble" />
                                                                </span>

                                                            )
                                                        )}
                                                    />
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                </Table>
                                <Modal
                                    title={null}
                                    open={isModalOpen}
                                    onOk={handleOk}
                                    onCancel={handleCancel}
                                    okText="Fechar"
                                    footer={null}
                                    width={1000}
                                    className="hubspot-modal"
                                >
                                    <AssociarDeal
                                        idDaPropostaEncontrada={idDaPropostaEncontrada}
                                        saveToken={saveToken}
                                        iDTokenAuthenticated={iDTokenAuthenticated}
                                        selectedPropostaParaHubspot={selectedPropostaParaHubspot}
                                        setCheckDataHubspot={setCheckDataHubspot}
                                        setNomeDoDeal={setNomeDoDeal}
                                        setNomeDoClienteDeal={setNomeDoClienteDeal}
                                        setEmailDoClienteDeal={setEmailDoClienteDeal}
                                        setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                                        setEtapaDoDeal={setEtapaDoDeal}
                                        setPipelineDoDeal={setPipelineDoDeal}
                                        setIsModalOpen={setIsModalOpen}
                                    />
                                </Modal>
                                <Modal
                                    title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> Histórico de Acessos</span>}
                                    open={isModalOpenHistorico}
                                    onOk={handleOkHistorico}
                                    onCancel={handleCancelHistorico}
                                    okText="Fechar"
                                    footer={null}
                                    // width={1000}
                                    className="hubspot-modal"
                                >
                                    <HistoricoDePesquisasPropostas
                                        historicoSelecionadodeAcessos={historicoSelecionadodeAcessos}
                                        historicoDeAcessos={historicoDeAcessos}
                                    />
                                </Modal>
                                <Modal
                                    title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> Perfil do Deal</span>}
                                    open={isModalOpenPerfilDoDeal}
                                    onOk={handleOkPerfilDeal}
                                    onCancel={handleCancelPerfilDeal}
                                    okText="Fechar"
                                    footer={null}
                                // width={1000}
                                // className="hubspot-modal"
                                >

                                    {loadingHubspot ? (
                                        <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                                            <Spin indicator={antIcon} />
                                            <p className='text-black text-center'>{t('media_mercado.loading')}</p>
                                        </div>
                                    ) : hubspotData && (
                                        <>
                                            <div>
                                                <p className="text-black text-base flex justify-between">
                                                    Cliente: <span className="font-bold capitalize">{hubspotData.contacto.properties.firstname}</span>
                                                </p>
                                                <p className="text-black text-base flex justify-between">
                                                    Email: <span className="font-bold">{hubspotData.contacto.properties.email}</span>
                                                </p>
                                                <p className="text-black text-base flex justify-between">
                                                    Negócio: <span className="font-bold">{hubspotData.properties.dealname}</span>
                                                </p>
                                                <p className="text-black text-base flex justify-between">
                                                    Data de Associação: <span className="font-bold">{hubspotData.properties.createdate}</span>
                                                </p>
                                                <p className="text-black text-base flex justify-between">
                                                    Etapa: <span className="font-bold">{hubspotData.properties.dealstage}</span>
                                                </p>
                                                <p className="text-black text-base flex justify-between">
                                                    Pipeline: <span className="font-bold">{hubspotData.properties.pipeline}</span>
                                                </p>
                                            </div>
                                            <div className="border-t-4 pt-2 mt-2 flex gap-2 justify-end">
                                                <Link to={`/${currentLanguage}/hubspot/perfil-deal/?id_deal=${hubspotData.id}`}>
                                                    <Button
                                                        type="primary"
                                                        target="_blank"
                                                        className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                                    >
                                                        <IoEyeOutline />
                                                        Ver Perfil
                                                    </Button>
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                </Modal>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
