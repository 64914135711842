import React, { useState, useEffect } from 'react';
import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import PartilhaRedesProposta from './partilha/partilha-redes-proposta';
import { FaLink } from 'react-icons/fa6';


export default function TabelaViaturaCliente({ dataCarros, isParticular, formatarNumero, allDataLinkPartilhado, nomeGeradoDaProposta }) {

    const { t } = useTranslation();



    const [tituloDoMobileDaViatura, setTituloDoMobileDaViatura] = useState('');


    let urldaViatura

    useEffect(() => {

        urldaViatura = allDataLinkPartilhado.url
        setTituloDoMobileDaViatura(allDataLinkPartilhado?.titulo)

    }, [allDataLinkPartilhado]);


    return (
        <>
            <div className='flex flex-col gap-4 text-white font-semibold text-lg '>
                <div className='card grid gap-8'>
                    <div className='grid grid-cols-1 md:grid-cols-1 gap-8 rounded-md'>
                        <div>
                            <Swiper
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                navigation={true}
                                modules={[Autoplay, Navigation]}
                            >
                                {dataCarros.imagens && dataCarros.imagens.length > 0 && dataCarros.imagens.map((imagem, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            style={{ borderRadius: '8px', objectFit: 'cover', width: '100%' }}
                                            src={imagem}
                                            alt={`Image ${index + 1}`}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="swiper-scrollbar swiper-scrollbar-horizontal"></div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <p className='font-medium text-2xl pb-4' style={{ overflowWrap: 'break-word' }}>
                                <b>{dataCarros.titulo}</b>
                            </p>

                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                                {/*<div className='flex flex-col gap-4'> */}
                                {/* <p className='text-base text-neutral-500'>{t('tabela_viatura.preco')}: <br /><span className='text-lg text-white font-medium'>{isParticular ? formatarNumero(dataCarros.preco_final_carro) : formatarNumero(dataCarros.preco_final_carro_sem_iva)}€</span></p> */}
                                <p className='text-sm text-neutral-500'>{t('tabela_viatura.quilometros')}: <br /><span className='text-lg text-white font-medium'>{dataCarros.quantidade_de_km}</span></p>
                                {/*</div> */}
                                {/*<div className='flex flex-col gap-4'>*/}
                                <p className='text-sm text-neutral-500'>{t('tabela_viatura.data_registo')}: <br /><span className='text-lg text-white font-medium'>{dataCarros.data_registo || 'Sem informação'}</span></p>

                                {dataCarros.cilindrada ? (
                                    <p className='text-base text-neutral-500'>
                                        {t('tabela_viatura.cilindrada')}: <br />
                                        <span className='text-lg text-white font-medium'>
                                            {dataCarros.cilindrada} ccm
                                        </span>
                                    </p>
                                ) : null}
                                {/*</div> */}
                                {/*<div className='flex flex-col gap-4'>*/}
                                <p className='text-sm text-neutral-500'>Potência: <br /><span className='text-lg text-white font-medium'>
                                    {dataCarros.potencia?.toString().includes('Hp') ? dataCarros.potencia?.toString().replace('Hp', 'CV') : dataCarros?.potencia + ' CV'}
                                </span></p>
                                <p className='text-sm text-neutral-500'>Combustível: <br /><span className='text-lg text-white font-medium'>{dataCarros.tipo_combustivel_traduzido || dataCarros.tipo_de_combustivel}</span></p>
                                {dataCarros.emissoes ? (
                                    <p className='text-sm text-neutral-500'>
                                        {t('tabela_viatura.emissoes')}: <br />
                                        <span className='text-lg text-white font-medium'>
                                            {dataCarros.emissoes}
                                        </span>
                                    </p>
                                ) : null}
                                {/*</div> */}
                            </div>
                        </div>
                    </div>
                    <div className='text-white mais-informacoes-box'>
                        <h2 className='pt-2 pr-4 pb-2'>{t('tabela_viatura.mais_informacao')}</h2>
                        <List
                            style={{ padding: 0 }}
                            size="small"
                            dataSource={[
                                { title: `${t('tabela_viatura.titulo_da_proposta')}`, value: nomeGeradoDaProposta },
                                { title: `${t('tabela_viatura.n_proprietarios')}`, value: dataCarros.caracteristicas?.proprietarios_anteriores },
                                { title: `${t('tabela_viatura.estado')}`, value: dataCarros.novo },
                                { title: `${t('tabela_viatura.n_portas')}`, value: dataCarros.caracteristicas?.portas },
                                { title: `${t('tabela_viatura.cor_exterior')}`, value: dataCarros.caracteristicas?.cor },
                                { title: `${t('tabela_viatura.cor_interior')}`, value: dataCarros.caracteristicas?.corInterior },
                                { title: `${t('tabela_viatura.teve_acidentes')}`, value: dataCarros?.acidentes },
                                { title: `${t('tabela_viatura.tipo_de_caixa')}`, value: dataCarros?.tipo_de_caixa },
                            ]}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className='grid grid-cols-2 gap-4 w-full'>
                                        <p className='text-xs sm:text-sm text-white'>{item.title}:</p>
                                        <span className='text-white text-xs font-normal sm:text-sm'>{item.value ? item.value : `${t('tabela_viatura.sem_informacao')}`}</span>
                                    </div>
                                </List.Item>
                            )}
                        />
                        <div className='grid grid-cols-2 gap-4 w-full border-t py-2'>
                            <p className='text-xs sm:text-sm text-white'>{t('tabela_viatura.ver_anuncio')}:</p>
                            <a href={allDataLinkPartilhado?.url} target="_blank" className='text-white text-xs font-normal sm:text-sm flex gap-2 items-center'>Link <FaLink /></a>
                        </div>
                    </div>
                </div>
                <div className='card'>
                    <PartilhaRedesProposta tituloDoMobileDaViatura={tituloDoMobileDaViatura} nomeGeradoDaProposta={nomeGeradoDaProposta} />
                </div>
            </div>
        </>
    );

};