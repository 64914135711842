import { Button } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { FaWhatsapp } from 'react-icons/fa6';
import { HiOutlineMail } from 'react-icons/hi';
import ShareButton from './whatsapp-btn';
import { useTranslation } from 'react-i18next';
import { FiExternalLink, FiCopy, FiCheck } from 'react-icons/fi';


export default function PartilhaRedesProposta({ nomeGeradoDaProposta, tituloDoMobileDaViatura }) {

    const shareUrl = `https://view.importrust.com/pt/simulador/?nome_proposta=${nomeGeradoDaProposta}`;
    const shareMessage = `Veja esta proposta de importação de um ${tituloDoMobileDaViatura} que recebi da Importrust.`;

    const [isCopied, setIsCopied] = useState(false);

    const { t } = useTranslation();

    const handleCopy = () => {
        navigator.clipboard.writeText(shareUrl);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    return (
        <div className='flex gap-4 items-center justify-between'>
            <h2 className='text-xs sm:text-sm text-white font-semibold'>{t('tabela_viatura.partilhar')}</h2>
            <div className='flex gap-1 flex-col'>
                <div className="flex flex-row items-start gap-4">
                    <ShareButton url={shareUrl} message={shareMessage} />
                    <Button
                        type="primary"
                        onClick={() => window.open(shareUrl, '_blank')}
                        className="flex gap-2 h-full bg-sky-600 items-center font-bold rounded p-2 w-max"
                    >
                        <FiExternalLink className="text-2xl" />
                        <span className="text-xs">{t('partilhar.link')}</span>
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleCopy}
                        className="flex gap-2 h-full bg-sky-600 items-center font-bold rounded p-2 w-max"
                    >
                        {isCopied ? <FiCheck className="text-2xl" /> : <FiCopy className="text-2xl" />}
                        <span className="text-xs">
                            {isCopied ? `${t('partilhar.copiado')}` : `${t('partilhar.copiar')}`}
                        </span>
                    </Button>
                </div>
            </div >
        </div>

    )
}