import React, { useState } from 'react';
import { Button, Input, List, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { FaLink } from 'react-icons/fa6';


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function TabelaViatura({ currentLanguage, urlCarroLink, dataCarros, isParticular, formatarNumero, valorMediaNacional, setValorMediaNacional }) {

    const [loading, setLoading] = useState(false);
    const [valorMaisBaixo, setValorMaisBaixo] = useState('');
    const [modelo, setModelo] = useState('');
    const [valorMaisAlto, setValorMaisAlto] = useState('');


    // console.log(urlCarroLink)


    const { t } = useTranslation();


    const codigoPostal = dataCarros.codigo_postal_number_code


    const calcularMediaNacional = () => {

        setLoading(true)
        setValorMediaNacional('')

        let url

        if (currentLanguage === "pt") {
            url = `${process.env.REACT_APP_GET_MEDIA_MERCADO_NACIONAL}?request=${urlCarroLink}`;
        } else {
            url = `${process.env.REACT_APP_GET_MEDIA_MERCADO_NACIONAL_ES}?request=${urlCarroLink}&modelo=${modelo}`;
        }

        axios.get(url)
            .then((response) => {
                setLoading(false)
                setValorMediaNacional(response.data.media)
                setValorMaisAlto(response.data.media[1])
                setValorMaisBaixo(response.data.media[0])
            })
            .catch((error) => {
                setLoading(true)
                console.error('Error occurred:', error);
            });
    };


    const handleModeloChange = (e) => {
        setModelo(e.target.value);
    };


    // console.log('dataCarrosdataCarros',dataCarros)

    return (
        <>
            <div className='flex flex-col gap-4 text-white font-semibold text-lg '>
                <div className='card grid gap-4'>
                    <div className='grid grid-cols-1 md:grid-cols-1 gap-4 rounded-md'>
                        <div>
                            <Swiper
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                navigation={true}
                                modules={[Autoplay, Navigation]}
                            >
                                {dataCarros.imagens && dataCarros.imagens.length > 0 && dataCarros.imagens.map((imagem, index) => (
                                    <SwiperSlide key={index}>
                                        <img
                                            style={{ borderRadius: '8px', objectFit: 'cover', width: '100%' }}
                                            src={imagem}
                                            alt={`Image ${index + 1}`}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="swiper-scrollbar swiper-scrollbar-horizontal"></div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <p className='font-medium text-2xl pb-4' style={{ overflowWrap: 'break-word' }}>
                                <b>{dataCarros.titulo}</b>
                            </p>

                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                                {/* <p className='text-base text-neutral-500'>{t('tabela_viatura.preco')}: <br /><span className='text-lg text-white font-medium'>{isParticular ? formatarNumero(dataCarros.preco_final_carro) : formatarNumero(dataCarros.preco_final_carro_sem_iva)}€</span></p> */}
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.quilometros')}: <br /><span className='text-lg text-white font-medium'>{dataCarros.quantidade_de_km}</span></p>
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.data_registo')}: <br /><span className='text-lg text-white font-medium'>{dataCarros.data_registo || 'Sem informação'}</span></p>
                                {dataCarros.cilindrada ? (
                                    <p className='text-base text-neutral-500'>
                                        {t('tabela_viatura.cilindrada')}: <br />
                                        <span className='text-lg text-white font-medium'>
                                            {dataCarros.cilindrada} ccm
                                        </span>
                                    </p>
                                ) : null}
                                {/*</div> */}
                                {/*<div className='flex flex-col gap-4'>*/}
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.potencia')}: <br /><span className='text-lg text-white font-medium'>
                                    {dataCarros.potencia?.toString().includes('Hp') ? dataCarros.potencia?.toString().replace('Hp', 'CV') : dataCarros?.potencia + ' CV'}
                                </span></p>
                                <p className='text-base text-neutral-500'>{t('tabela_viatura.combustivel')}: <br /><span className='text-lg text-white font-medium'>{dataCarros.tipo_combustivel_traduzido || dataCarros.tipo_de_combustivel}</span></p>
                                {dataCarros.emissoes && dataCarros.combustivel_real !== "Electric" ? (
                                    <p className='text-base text-neutral-500'>
                                        {t('tabela_viatura.emissoes')}: <br />
                                        <span className='text-lg text-white font-medium'>
                                            {dataCarros.emissoes}
                                        </span>
                                    </p>
                                ) : null}
                                {/*</div> */}
                            </div>
                        </div>
                    </div>
                    <div className='text-white mais-informacoes-box'>
                        <h2 className='pt-2 pr-4 pb-2'>{t('tabela_viatura.mais_informacao')}</h2>
                        <List
                            size="small"
                            dataSource={[
                                { title: `${t('tabela_viatura.acidentes')}`, value: dataCarros.acidentes },
                                { title: `${t('tabela_viatura.tipo_de_caixa')}`, value: dataCarros.tipo_de_caixa },
                                { title: `${t('tabela_viatura.stand')}`, value: dataCarros.stand },
                                { title: `${t('tabela_viatura.pais')}`, value: dataCarros.pais },
                                { title: `${t('tabela_viatura.codigo_postal')}`, value: dataCarros.codigo_postal },
                                { title: `${t('tabela_viatura.morada')}`, value: dataCarros.morada },
                                { title: `${t('tabela_viatura.contacto')}`, value: dataCarros.contacto },
                                { title: `${t('tabela_viatura.contacto_2')}`, value: dataCarros.contacto_2 },
                            ]}
                            renderItem={(item) => (
                                <List.Item>
                                    <div className='grid grid-cols-2 gap-4 w-full'>
                                        <p className='text-xs sm:text-sm text-white'>{item.title}:</p>
                                        <span className='text-white text-xs font-normal sm:text-sm flex gap-2 items-center'>{item.value ? item.value : `${t('tabela_viatura.sem_informacao')}`}</span>
                                    </div>
                                </List.Item>
                            )}
                        />
                        <div className='grid grid-cols-2 gap-4 w-full border-t py-2'>
                            <p className='text-xs sm:text-sm text-white'>{t('tabela_viatura.ver_anuncio')}:</p>
                            <a href={urlCarroLink} target="_blank" className='text-white text-xs font-normal sm:text-sm flex gap-2 items-center'>Link <FaLink /></a>
                        </div>
                    </div>
                </div>
                {/* <div className='card media-mercado-nacional'>
                    <div className='grid'>
                        <div className='titulo-form'>
                            <h2 className='center font-semibold flex justify-between'>{t('media_mercado.titulo_principal')}</h2>
                            <p className='text-sm text-neutral-500'>{t('media_mercado.sub_titulo_principal')}:</p>
                        </div>

                        {loading ? (
                            <>
                                <div className='card-white grid gap-4'>
                                    <div className="flex flex-col gap-4 justify-center">
                                        <Spin indicator={antIcon} />
                                        <div>
                                            <p className='text-black text-center'>{t('media_mercado.loading')}</p>
                                            <p className='text-black text-base font-normal text-center'>Este processo pode demorar alguns segundos...</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : valorMediaNacional ? (
                            <>
                                {valorMediaNacional == "" ? (
                                    <>
                                        <div className='grid gap-4'>
                                            <div className='card-white'>
                                                <span className='text-red-600 text-base font-extrabold'>
                                                    {t('media_nacional.nenhumresultado')}
                                                </span>
                                            </div>
                                            <div className='card-white grid gap-4'>
                                                {currentLanguage === "es" ? (
                                                    <div className="grid gap-2 content-start">
                                                        <p className='text-black text-base flex justify-between'>Introduzca el modelo de vehículo</p>
                                                        <Input
                                                            placeholder=""
                                                            value={modelo}
                                                            onChange={handleModeloChange}
                                                        />
                                                    </div>
                                                ) : null}
                                                < Button type="primary" htmlType="submit" to="/" className="bg-black text-white font-bold rounded w-full"
                                                    onClick={calcularMediaNacional}>
                                                    {t('media_nacional.procurar')}
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='card-white'>
                                        <span className='text-black text-lg sm:text-2xl font-extrabold'>
                                            {currentLanguage === "es" ? (
                                                <>
                                                    {formatarNumero(valorMediaNacional) + '€'}
                                                </>

                                            ) : (
                                                <>
                                                    {formatarNumero(valorMaisBaixo) + '€ - ' + formatarNumero(valorMaisAlto) + '€ '}
                                                </>
                                            )}
                                        </span>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div className='card-white grid gap-4'>
                                    {currentLanguage === "es" ? (
                                        <div className="grid gap-2 content-start">
                                            <p className='text-black text-base flex justify-between'>Introduzca el modelo de vehículo</p>
                                            <Input
                                                placeholder=""
                                                value={modelo}
                                                onChange={handleModeloChange}
                                            />
                                        </div>
                                    ) : null}
                                    < Button type="primary" htmlType="submit" to="/" className="bg-black text-white font-bold rounded w-full"
                                        onClick={calcularMediaNacional}>
                                        {t('media_nacional.procurar')}
                                    </Button>
                                </div>
                            </>
                        )}

                    </div>
                </div> */}
                {codigoPostal !== "" && currentLanguage === "pt" && (
                    <div className='card localizacao-da-viatura'>
                        <div className='grid'>
                            <div className='titulo-form'>
                                <h2 className='center font-semibold flex justify-between'>Tempo de recolha da viatura</h2>
                            </div>
                            <div className='card-white grid gap-4'>
                                <p className='text-neutral-500'>A estimativa de recolha desta viatura é de:
                                    {codigoPostal == "4" || codigoPostal == "5" || codigoPostal == "6" || codigoPostal == "7" ? (
                                        <span className='text-black'> 2 semanas</span>
                                    ) : (
                                        <span className='text-black'> 4 semanas</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                )}

            </div >
        </>
    );

};