import React, { useState } from 'react';
import { Modal, Select } from 'antd';
import { BiCopy } from 'react-icons/bi';
import ReactDOMServer from 'react-dom/server';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';


const { Option } = Select;

export default function CopiarLinkseTextos({
    valorFinalCredito,
    precoFinalCarro,
    valorFinalISV,
    taxaDeServico,
    valorChaveNaMaoParticular,
    formatarNumero,
    dataCarros,
    urlCarroLink,
    nomeDaProposta,
    linkPartilhavel,
    valorChaveNaMaoEmpresa,
    activeTabKeyResultadoSimulacao,
    precoFinalDoCarroSemIva,
}) {

    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    function calcularISV(valorFinalISV) {
        if (valorFinalISV === '0€') {
            return 'Isento';
        } else {
            return formatarNumero(Math.ceil(valorFinalISV));
        }
    }

    console.log(linkPartilhavel)

    const handleOptionClick = (value) => {
        if (value === "1") {
            let text
            if (activeTabKeyResultadoSimulacao === "1") {
                text = (
                    <div>
                        <div>
                            <a target={"_blank"}
                                style={{ cursor: "pointer" }}
                                href={linkPartilhavel}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                        </div>
                        <div>
                            {`${t('gerar_proposta.preco_carro')}: ${formatarNumero(precoFinalCarro)}€ (Por Negociar)`}
                        </div>
                        <div>
                            {`${t('main.isv')}: ${calcularISV(valorFinalISV)} €`}
                        </div>
                        <div>
                            {`${t('gerar_proposta.despesas_de_importacao')}: ${formatarNumero(taxaDeServico)}€`}
                        </div>
                        <div>
                            <b>{`${t('gerar_proposta.preco_final_chave_mao')}: ${formatarNumero(valorChaveNaMaoParticular)}`}€</b>
                        </div>
                    </div>
                );
            } else {
                text = (
                    <div>
                        <div>
                            <a target={"_blank"}
                                style={{ cursor: "pointer" }}
                                href={linkPartilhavel}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                        </div>
                        <div>
                            {`${t('gerar_proposta.preco_carro')}: ${formatarNumero(precoFinalDoCarroSemIva)}€ (Por Negociar)`}
                        </div>
                        <div>
                            {`${t('main.isv')}: ${calcularISV(valorFinalISV)} €`}
                        </div>
                        <div>
                            {`${t('gerar_proposta.despesas_de_importacao')}: ${formatarNumero(taxaDeServico)}€`}
                        </div>
                        <div>
                            <b>{`${t('gerar_proposta.preco_final_chave_mao')}: ${formatarNumero(valorChaveNaMaoEmpresa)}`}€</b>
                        </div>
                    </div>
                )
            }

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });


        } else if (value === "2") {
            let text = (
                <div><a href={linkPartilhavel}>{`${linkPartilhavel}`}</a></div>
            );

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });

        } else if (value === "3") {

            const text = `${linkPartilhavel}`;

            // console.log(text);

            const blob = new Blob([text], { type: 'text/plain' });

            navigator.clipboard.write([new ClipboardItem({ "text/plain": blob })])
                .then(() => {
                    // Copied successfully
                })
                .catch((error) => {
                    // Handle errors
                });

        } else if (value === "5") {


            let text
            if (activeTabKeyResultadoSimulacao === "1") {
                text = (
                    <div>
                        <div>
                            <a target={"_blank"}
                                style={{ cursor: "pointer" }}
                                href={linkPartilhavel}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                        </div>
                        <div>
                            {`${t('gerar_proposta.preco_carro_en')}: ${formatarNumero(precoFinalCarro)}€ (To be negotiated)`}
                        </div>
                        <div>
                            {`${t('main.isv')}: ${calcularISV(valorFinalISV)} €`}
                        </div>
                        <div>
                            {`${t('gerar_proposta.despesas_de_importacao_en')}: ${formatarNumero(taxaDeServico)}€`}
                        </div>
                        <div>
                            <b>{`${t('gerar_proposta.preco_final_chave_mao_en')}: ${formatarNumero(valorChaveNaMaoParticular)}`}€</b>
                        </div>
                        {/* <div>
                            {`${t('gerar_proposta.prestacao_desde')}: ${formatarNumero(valorFinalCredito)}€`}
                        </div> */}
                    </div>
                );
            } else {
                text = (
                    <div>
                        <div>
                            <a target={"_blank"}
                                style={{ cursor: "pointer" }}
                                href={linkPartilhavel}>{`${nomeDaProposta || dataCarros.titulo}`}</a>
                        </div>
                        <div>
                            {`${t('gerar_proposta.preco_carro_en')}: ${formatarNumero(precoFinalDoCarroSemIva)}€ (To be negotiated)`}
                        </div>
                        <div>
                            {`${t('main.isv')}: ${calcularISV(valorFinalISV)} €`}
                        </div>
                        <div>
                            {`${t('gerar_proposta.despesas_de_importacao_en')}: ${formatarNumero(taxaDeServico)}€`}
                        </div>
                        <div>
                            <b>{`${t('gerar_proposta.preco_final_chave_mao_en')}: ${formatarNumero(valorChaveNaMaoEmpresa)}`}€</b>
                        </div>
                        {/* <div>
                            {`${t('gerar_proposta.prestacao_desde')}: ${formatarNumero(valorFinalCredito)}€`}
                        </div> */}
                    </div>
                )
            }

            const textString = ReactDOMServer.renderToStaticMarkup(text);

            const blob = new Blob([textString], { type: 'text/html' });

            navigator.clipboard.write([new ClipboardItem({ "text/html": blob })])
                .then(() => {

                })
                .catch((error) => {

                });


            // console.log(copiedText)



        } else {
            window.open(linkPartilhavel, '_blank');
        }
    };

    return (
        <div className='w-full flex items-center gap-4'>
            <Select defaultValue={t('gerar_proposta.clique_copiar_abrir')} className='w-full text-black' onSelect={handleOptionClick}>
                <Option value="1">
                    {t('gerar_proposta.copiar_texto_para_email')}
                </Option>
                <Option value="5">
                    {t('gerar_proposta.copiar_texto_para_email_en')}
                </Option>
                <Option value="2">
                    {t('gerar_proposta.copiar_url_com_link')}
                </Option>
                <Option value="3">
                    {t('gerar_proposta.copiar_url')}
                </Option>
                <Option value="4">
                    {t('gerar_proposta.abrir_link')}
                </Option>
            </Select>
            <AiOutlineInfoCircle className='icon-question' onClick={showModal} />
            <Modal
                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle />  {t('gerar_proposta.explicacao_copiar_abrir')}</span>}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={t('gerar_proposta.fechar')}
                footer={null}
            >
                <div className='grid gap-4'>
                    <p className="text-black text-base grid justify-between">{t('gerar_proposta.copiar_texto_para_email')}: <span className="font-bold text-base">{t('gerar_proposta.texto_formato_email')}</span></p>
                    <p className="text-black text-base grid justify-between">{t('gerar_proposta.copiar_texto_para_email_en')}: <span className="font-bold text-base">{t('gerar_proposta.texto_formato_email')}</span></p>
                    <p className="text-black text-base grid justify-between">{t('gerar_proposta.copiar_url_com_link')}: <span className="font-bold text-base">{t('gerar_proposta.url_com_link')}.</span></p>
                    <p className="text-black text-base grid justify-between">{t('gerar_proposta.copiar_url')}: <span className="font-bold text-base">{t('gerar_proposta.url_sem_link')}</span></p>
                    <p className="text-black text-base grid justify-between">{t('gerar_proposta.abrir_link')}: <span className="font-bold text-base">{t('gerar_proposta.target_janela')}</span></p>
                </div>
                {/* <div className="border-t-4 pt-6 mt-6">
                    <p className='text-black text-base'>Preview do que vais copiar:</p>
                    <span className="font-bold text-base w-full">{linkPartilhavel}</span>
                </div> */}

            </Modal>
            {/* <button onClick={handleCopyClick}><BiCopy /></button> */}
        </div>
    );
}
