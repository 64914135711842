import React from "react";
import { Input, Select, Button, Checkbox, Collapse, Tooltip } from 'antd';
import { AlertaMsg } from "../pt/mensagens/mensagem_alerta";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
import { Option } from "antd/lib/mentions";
import { AiOutlineInfoCircle } from "react-icons/ai";


dayjs.extend(customParseFormat);

//const { Option } = Select;
const { Panel } = Collapse;

export default function ES_CalculoChaveMaoVista({
    urlCarroLink,
    dataGeralISV,
    setListaDeModelos,
    idCidade,
    listaDeModelos,
    valorViaturaNova,
    setValorViaturaNova,
    idMarca,
    setIdMarca,
    setIdCidade,
    setIdModelo,
    idModelo,
    listaDeMarcas,
    idMunicipio,
    setIdMunicipio,
    listaDeMunicipios,
    allDataLinkPartilhado,
    validarViaturaEletrico,
    temParametros,
    dataDeRegisto,
    novoOuUsado,
    tipoDeCombustivel,
    setTaxaDeServico,
    tipoDeVeiculoParaOutros,
    hibridoIsChecked,
    setCo2IsChecked,
    setHibridoValidationIsChecked,
    setNovoOuUsado,
    setTituloDaPropostaChaveNaMao,
    setOutrosServicos,
    setAlertasNoPedido,
    setCilindrada,
    setDataDeRegisto,
    setEmissoesGases,
    setHibridoIsChecked,
    setTipoDeCombustivel,
    setParticulas,
    setTipoDeVeiculoParaOutros,
    setWltpIsChecked,
    setTipoDeVeiculoParaInsencoes,
    alertasNoPedido,
    taxaDeServico,
    tipoDeVeiculoParaInsencoes,
    hibridoValidationIsChecked,
    outrosServicos,
    cilindrada,
    emissoesGases,
    wltpIsChecked,
    particulas,
    validarAnoDeRegisto,
    co2IsChecked,
    valordaViaturaIsChecked,
    setValordaViaturaIsChecked,
    valorFinalISV,
    quantidadeDeKM,
    anoDoRegisto,
    IgnorarAvisos,
    listaDeCidades
}) {



    return (

        <div className='card'>
            <div className='titulo-form'>
                <p className="font-semibold">Cálculo do IVTM</p>
            </div>
            <form className=' grid  gap-5'>
                <div>
                    <div className="mx-auto grid gap-4">
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                            {listaDeCidades ? (
                                <div className="grid gap-2 content-start">
                                    <p className="text-sm text-neutral-500 font-semibold">Provincias</p>
                                    {idCidade ? idCidade : "Sin información"}
                                </div>
                            ) : null}

                            {idCidade && Array.isArray(listaDeMunicipios) ? (
                                <div className="grid gap-2 content-start">
                                    <p className="text-sm text-neutral-500 font-semibold">Munícipio</p>
                                    {idMunicipio ? idMunicipio : "Sin información"}
                                </div>
                            ) : null}

                            <div className="grid gap-2 content-start">
                                <p className="text-sm text-neutral-500 font-semibold">Nuevo / Usado</p>
                                {novoOuUsado ? novoOuUsado : "Sin información"}
                            </div>

                            {novoOuUsado === "Usado" ? (
                                <div className='grid gap-2'>
                                    <div className='grid gap-2'>
                                        <p className="text-sm text-neutral-500 font-semibold">Fecha de primer registro</p>
                                        {dataDeRegisto ? dataDeRegisto : "Sin información"}
                                    </div>
                                </div>
                            ) : null}

                            {listaDeMarcas ? (
                                <div className='grid gap-2'>
                                    <p className="text-sm text-neutral-500 font-semibold">Marca</p>
                                    {idMarca ? idMarca : "Sin información"}
                                </div>
                            ) : null}


                            {idMarca && Array.isArray(listaDeModelos) ? (
                                <div className='grid gap-2'>
                                    <p className="text-sm text-neutral-500 font-semibold">Modelo</p>
                                    {idModelo ? idModelo : "Sin información"}
                                </div>
                            ) : null}

                            <div className='grid gap-2'>
                                <p className="text-sm text-neutral-500 font-semibold">Valor del Vehículo Nuevo</p>
                                {valorViaturaNova ? valorViaturaNova : "Sin información"}
                            </div>

                            {tipoDeCombustivel !== "Eletrico" && (
                                <div className="grid gap-2 content-start">
                                    <p className="text-sm text-neutral-500 font-semibold">Cilindrada</p>
                                    {cilindrada ? cilindrada : "Sin información"}
                                </div>
                            )}

                            <div className="grid gap-2 content-start">
                                <p className="text-sm text-neutral-500 font-semibold">Tipo de Combustible</p>
                                {tipoDeCombustivel ? tipoDeCombustivel : "Sin información"}
                            </div>

                            {tipoDeCombustivel !== "Eletrico" && (
                                <div className="grid gap-2 content-start">
                                    <p className="text-sm text-neutral-500 font-semibold">Emisiones de Gases CO2</p>
                                    {emissoesGases ? emissoesGases : "Sin información"}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}