import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from './Routes';
import { useLocation } from "react-router-dom";
import './styles.css';
import './styles/index.css';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import axios from "axios";
import HeaderFuncionario from './components/pt/header/header-funcionario';
// import i18n from './i18n'; // Importação do arquivo i18n.jsx
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ptJSON from './locale/pt.json';
import esJSON from './locale/es.json';


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);

export default function App() {




    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const [allLanguage, setAllLanguage] = useState(null);




    useEffect(() => {
        // Analisa os parâmetros da URL atual
        const urlParams = new URLSearchParams(window.location.search);
        // Obtém o valor do parâmetro "nome_proposta"
        const nomeProposta = urlParams.get('nome_proposta');
        // console.log(window.location.pathname)
        // Verifica se o URL atual corresponde ao padrão desejado
        if (window.location.pathname === '/simulador/' && nomeProposta) {

            // Redireciona para o novo URL com o parâmetro "nome_proposta" armazenado
            window.location.href = `https://importrust.com`;
        }
    }, []);





    // console.log(currentLanguage)


    useEffect(() => {

        const parametros = new URLSearchParams(location.search)
        // console.log(parametros.size)

        if (parametros.size > 0) {

            const nomePropostaParam = parametros.get('nome_proposta');
            const idPropostaParam = parametros.get('id_proposta');

            if (nomePropostaParam || idPropostaParam) {
                setCurrentLanguage('pt')
            } else {
                setCurrentLanguage('pt')
            }
        }

        //console.log(currentLanguage)

        i18n.use(initReactI18next).init({
            resources: {
                pt: { translation: { ...ptJSON } },
                es: { translation: { ...esJSON } }
            },
            lng: currentLanguage, // Set the language based on the currentLanguage state
            fallbackLng: "pt",
            interpolation: {
                escapeValue: false
            }
        });

    }, [currentLanguage]);


    const location = useLocation();


    //Responsive
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    })

    //estados normais de interface
    const [esconde, setEsconde] = useState(false);
    const [erroPedido, setErroPedido] = useState(false);
    const [loading, setLoading] = useState(true);
    const [teste, setTeste] = useState(false);


    //Utilizador Logado
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userData, setUserData] = useState(null);
    const [validToken, setValidToken] = useState(false);
    const [validLogin, setValidLogin] = useState(false);
    const [saveToken, setSaveToken] = useState('');
    const [iDTokenAuthenticated, setIDTokenAuthenticated] = useState('');


    //Pesquisa
    const [novaPesquisa, setNovaPesquisa] = useState(false);
    const [linkDaProposta, setLinkDaProposta] = useState('');
    const [urlCarroLink, setUrlCarroLink] = useState(linkDaProposta); //guardar url


    //Proposta
    const [estadoDaPartilhaNomeDaProposta, setEstadoDaPartilhaNomeDaProposta] = useState('');
    const [temParametros, setTemParametros] = useState(false);


    const token = localStorage.getItem('token');


    useEffect(() => {



        const checkAuthentication = async (userId) => {


            setIDTokenAuthenticated(userId);
            setSaveToken(token);


            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_URL_LOGIN}`,
                    {
                        token,
                        userId
                    }
                );

                if (response.data.success) {
                    setValidLogin(true)
                } else {
                    // LIMPAR O LOCALSTORAGE
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    // window.location.href = "/";
                }

                return response.data;
            } catch (error) {
                return false;
            }
        };


        const fetchData = async (userId) => {
            let isValid = await checkAuthentication(userId);


            if (isValid && isValid.success) {
                setUserData(isValid.user);
                setAllLanguage(isValid.user.data.pais)

                if (isValid.user.data.pais && isValid.user.data.pais.length > 1) {
                    setCurrentLanguage("pt");
                } else {
                    setCurrentLanguage(isValid.user.data.pais[0]);
                }
                return true;
            } else {
                return false;
            }
        };




        const loggedInUser = localStorage.getItem("user");

        const authenticateUser = async () => {

            if (loggedInUser && loggedInUser !== "undefined" && loggedInUser !== undefined) {
                const userId = JSON.parse(loggedInUser).ID;
                if (userId) {
                    let isValid = await fetchData(userId);

                    if (isValid) {
                        const foundUser = JSON.parse(loggedInUser);
                        setIsAuthenticated(foundUser);
                        setValidToken(true);
                    } else {
                        setIsAuthenticated(false);
                        setUserData(false);
                    }
                }
            }

            setLoading(false);
        };


        authenticateUser();

    }, []);


    // console.log(currentLanguage)



    return (
        <div className={`max-w-screen-xl m-auto`}>
            {loading ? (
                <>
                    <div className="p-4 flex flex-row gap-4 h-screen text-primary">
                        <div className={`main-content flex flex-col gap-5 justify-center`}>
                            <div className="overflow-auto h-screen  pr-5 pl-5">
                                <div className="basis-1/2 flex flex-col items-center justify-center gap-4 h-full">
                                    <Spin indicator={antIcon} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>

                    <div className='w-full flex flex-col justify-center items-center gap-4 p-2 sm:p-4'>
                        <div className='w-full h-full flex flex-col gap-2 md:gap-4 text-white'>

                            {validLogin ? (
                                <HeaderFuncionario
                                    allLanguage={allLanguage}
                                    currentLanguage={currentLanguage}
                                    setCurrentLanguage={setCurrentLanguage}
                                    setTeste={setTeste}
                                    userData={userData}
                                    setEsconde={setEsconde}
                                    setUrlCarroLink={setUrlCarroLink}
                                    setEstadoDaPartilhaNomeDaProposta={setEstadoDaPartilhaNomeDaProposta}
                                    setErroPedido={setErroPedido}
                                />
                            ) : null}

                            <AppRoutes
                                allLanguage={allLanguage}
                                teste={teste}
                                setTeste={setTeste}
                                userData={userData}
                                currentLanguage={currentLanguage}
                                setCurrentLanguage={setCurrentLanguage}
                                setEsconde={setEsconde}
                                esconde={esconde}
                                urlCarroLink={urlCarroLink}
                                setUrlCarroLink={setUrlCarroLink}
                                setErroPedido={setErroPedido}
                                erroPedido={erroPedido}
                                novaPesquisa={novaPesquisa}
                                setNovaPesquisa={setNovaPesquisa}
                                iDTokenAuthenticated={iDTokenAuthenticated}
                                setIDTokenAuthenticated={setIDTokenAuthenticated}
                                isMobile={isMobile}
                                isAuthenticated={isAuthenticated}
                                saveToken={saveToken}
                                setEstadoDaPartilhaNomeDaProposta={setEstadoDaPartilhaNomeDaProposta}
                                setTemParametros={setTemParametros}
                                temParametros={temParametros}
                                estadoDaPartilhaNomeDaProposta={estadoDaPartilhaNomeDaProposta}
                                linkDaProposta={linkDaProposta}
                                validLogin={validLogin}
                            />

                        </div>
                    </div>
                </>
            )}
        </div>
    );
}


