import React, { useState, useEffect, useRef } from 'react';
import {
    useLocation, useNavigate
} from "react-router-dom";
import axios from 'axios';
import { BiError } from 'react-icons/bi';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TabelaViatura from "../../../components/pt/tabelaViatura";
import CalculoChaveMao from "../../../components/pt/calculo-chave-mao";
import ErroSection from "../../../components/pt/erros/erro-section";
import Loading from "../../../components/pt/loading/loading";
import { Button, Switch } from 'antd';
import TabelaViaturaCliente from '../../../components/pt/tabelaViaturaCliente';
import LinkPartilhaExpira from '../../../components/pt/link-partilha-expira';
import FuncContactDetails from '../../../components/pt/funcionario/func-contact-details';
import PesquisaSimulacao from '../../../components/pt/pesquisa-simulacao';
import HeaderSimulador from '../../../components/pt/header/header-simulador';
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';
import CreditoMainComp from '../../../components/pt/credito/credito-main-comp';
import { useTranslation } from 'react-i18next';
import PT_PartilharProposta from '../../../components/pt/pt_partilhar-proposta';
import PT_ResultadodaSimulacao from '../../../components/pt/pt_resultado-da-simulacao';
import AssociarDeal from '../../../components/pt/hubspot/deals/associar-deal';
import InformacoesDeal from '../../../components/pt/hubspot/deals/informacoes-deal';
import CalculoChaveMaoVista from '../../../components/pt/calculo-chave-mao-vista';


dayjs.extend(customParseFormat);


export default function PT_Calculo({
    setNovaPesquisa,
    isAuthenticated,
    setCurrentLanguage,
    currentLanguage,
    validartipodeparametro,
    userData,
    isMobile,
    setNomeGeradoDaProposta,
    setEstadoDaPartilhaNomeDaProposta,
    estadoDaPartilhaNomeDaProposta,
    nomeGeradoDaProposta,
    linkDaProposta,
    temParametros,
    formatarNumero,
    setTemParametros,
    setPartilhaNomeDaProposta,
    partilhaNomeDaProposta,
    iDTokenAuthenticated,
    saveToken,
    setEsconde,
    setErroPedido,
    urlCarroLink,
    setUrlCarroLink,
    esconde,
    erroPedido,
    teste,
    setTeste,

    showHubspot,
    setShowHubspot,
    checkDataHubspot,
    setCheckDataHubspot,
    nomeDoDeal,
    setNomeDoDeal,
    nomeDoClienteDeal,
    setNomeDoClienteDeal,
    emailDoClienteDeal,
    setEmailDoClienteDeal,
    dataDeAssociacaoDoDeal,
    setDataDeAssociacaoDoDeal,
    etapaDoDeal,
    setEtapaDoDeal,
    pipelineDoDeal,
    setPipelineDoDeal,
}) {




    useEffect(() => {
        setTeste(false)
        setEsconde(false);
        setErroPedido(false)
        setUrlCarroLink('');
        setEstadoDaPartilhaNomeDaProposta(false)
    }, []);


    const { t } = useTranslation();
    const navigate = useNavigate();


    //outros
    const [loading, setLoading] = useState(false);
    const [alertasNoPedido, setAlertasNoPedido] = useState(false);


    //guardar dados do carro
    const [dataCarros, setDataCarros] = useState([]);

    const [idDaPropostaEncontrada, setIdDaPropostaEncontrada] = useState('');
    const [idDoDealAssociar, setIdDoDealAssociar] = useState('');


    //guardar titulo do copypasy da proposta
    const [tituloDaPropostaChaveNaMao, setTituloDaPropostaChaveNaMao] = useState('');
    const [tituloDaPropostaChaveNaMaoParticular, setTituloDaPropostaChaveNaMaoParticular] = useState('');

    //para ISV
    const [tipoDeVeiculo, setTipoDeVeiculo] = useState('LigeiroPassageiros');
    const [tipoDeVeiculoParaInsencoes, setTipoDeVeiculoParaInsencoes] = useState('');
    const [tipoDeVeiculoParaOutros, setTipoDeVeiculoParaOutros] = useState('LigeiroPassageiros');
    const [novoOuUsado, setNovoOuUsado] = useState('');
    const [dataDeRegisto, setDataDeRegisto] = useState('');
    const [cilindrada, setCilindrada] = useState('');
    const [tipoDeCombustivel, setTipoDeCombustivel] = useState('');
    const [particulas, setParticulas] = useState('');
    const [tipoDeTestes, setTipoDeTestes] = useState('');
    const [emissoesGases, setEmissoesGases] = useState('');

    //para calculos
    const [taxaDeServico, setTaxaDeServico] = useState('2800');
    const [comissaoDeServico, setComissaoDeServico] = useState('');
    const [precoFinalCarro, setPrecoFinalCarro] = useState('');
    const [valorFinalISV, setValorFinalISV] = useState('0');
    const [dataGeralISV, setDataGeralISV] = useState('');

    const [valorFinalIUC, setValorFinalIUC] = useState('0');
    const [dataGeralIUC, setDataGeralIUC] = useState('');
    const [iucCilindrada, setIucCilindrada] = useState('');
    const [iucCO2, setIucCO2] = useState('');
    const [iucAdicionalCO2, setIucAdicionalCO2] = useState('');
    const [iucCoeficienteAnoMatriculo, setIucCoeficienteAnoMatriculo] = useState('');
    const [iucAdicionalGasoleo, setIucAdicionalGasoleo] = useState('');

    const [isvcomponentecilindrada, setIsvComponenteCilindrada] = useState('');
    const [isvcomponenteambiental, setIsvcomponenteambiental] = useState('');
    const [isvReducaoAnosUsoCilindrada, setIsvReducaoAnosUsoCilindrada] = useState('');
    const [isvReducaoAnosUsoAmbiental, setIsvReducaoAnosUsoAmbiental] = useState('');
    const [isvAgravamentoParticulas, setIsvAgravamentoParticulas] = useState('');
    const [isvReducaoAnosUsoParticulas, setIsvReducaoAnosUsoParticulas] = useState('');
    const [isvTaxaAplicavelTabela, setIsvTaxaAplicavelTabela] = useState('');



    const [valorChaveNaMaoEmpresa, setValorChaveNaMaoEmpresa] = useState('');
    const [valorChaveNaMaoParticular, setValorChaveNaMaoParticular] = useState('');


    //Dados - Validar Dados - Guardar Dados
    const [quantidadeDeKM, setQuantidadeDeKM] = useState('');
    const [anoDoRegisto, setAnoDoRegisto] = useState('');
    const [precoFinalDoCarroSemIva, setPrecoFinalDoCarroSemIva] = useState('');
    const [precoFinalDoCarroIvaDedutival, setPrecoFinalDoCarroIvaDedutival] = useState(false);
    //const [selecionarIsencao, setSelecionarIsencao] = useState(true);
    const [hibridoIsChecked, setHibridoIsChecked] = useState(false);
    const [co2IsChecked, setCo2IsChecked] = useState(false);
    const [wltpIsChecked, setWltpIsChecked] = useState(false);
    const [outrosServicos, setOutrosServicos] = useState('');
    const [validarAnoDeRegisto, setValidarAnoDeRegisto] = useState(false);
    const [validarViaturaEletrico, setValidarViaturaEletrico] = useState(false);
    // VALIDACAO HIBRIDO PLUGIN
    const [hibridoValidationIsChecked, setHibridoValidationIsChecked] = useState(false);
    // PARA SABER SE É TAB PARTICULAR OU EMPRESA (PARTICULAR COMEÇA COM TRUE)
    const [isParticular, setIsParticular] = useState(true);

    const [erroInicial, setErroInicial] = useState('Ocorreu algum erro, tente novamente!');
    const [infoInicialPartilhaProposta, setInfoInicialPartilhaProposta] = useState('');

    //Resultado da Simulação
    const [activeTabKeyResultadoSimulacao, setActiveTabKeyResultadoSimulacao] = useState("1");
    const [tabResultadoSimulacao, setTabResultadoSimulacao] = useState("0");


    //partilhar com cliente
    const [nomeDaProposta, setNomeDaProposta] = useState('');
    const [allDataLinkPartilhado, setAllDataLinkPartilhado] = useState('');


    //Informacoes do Deal - Hubspot
    // const [showHubspot, setShowHubspot] = useState(false);
    // const [checkDataHubspot, setCheckDataHubspot] = useState([]);
    // const [nomeDoDeal, setNomeDoDeal] = useState('');
    // const [nomeDoClienteDeal, setNomeDoClienteDeal] = useState('');
    // const [emailDoClienteDeal, setEmailDoClienteDeal] = useState('');
    // const [dataDeAssociacaoDoDeal, setDataDeAssociacaoDoDeal] = useState('');
    // const [etapaDoDeal, setEtapaDoDeal] = useState('');
    // const [pipelineDoDeal, setPipelineDoDeal] = useState('');
    const [idDoDealAtual, setIdDoDealAtual] = useState('');


    const [nomePerfilDataFuncionario, setNomePerfilDataFuncionario] = useState('');
    const [fotoPerfilDataFuncionario, setFotoPerfilDataFuncionario] = useState('');
    const [whatsappDataFuncionario, setWhatsappDataFuncionario] = useState('');
    const [emailDataInternoFuncionario, setEmailDataInternoFuncionario] = useState('');


    const [IgnorarAvisos, setIgnorarAvisos] = useState(false);

    //simulacao de credito
    const [activeTabKeySimulacaoCredito, setActiveTabKeySimulacaoCredito] = useState("1");
    const [taxaDeJuroCredito, setTaxaDeJuroCredito] = useState('6.50');
    const [entradaCredito, setEntradaCredito] = useState('');
    const [valorChaveNaMaoViaturaParaCredito, setValorChaveNaMaoViaturaParaCredito] = useState('');
    const [valorFinanciadoCredito, setValorFinanciadoCredito] = useState('');
    const [montanteNecessarioCredito, setMontanteNecessarioCredito] = useState('');
    const [prestacoesMesesCredito, setPrestacoesMesesCredito] = useState('');
    const [valorChaveNaMaoParaCredito, setValorChaveNaMaoParaCredito] = useState('');
    const [valorFinalCredito, setValorFinalCredito] = useState('');
    const [ivaNaOrigem, setIvaNaOrigem] = useState(false);
    const [valorIvaNaOrigem, setValorIvaNaOrigem] = useState('');

    //Procurar Média Nacional
    const [valorMediaNacional, setValorMediaNacional] = useState('');


    //Secção do Crédito
    const [isCheckedCredito, setIsCheckedCredito] = useState(false);
    const [mostrarResultado, setMostrarResultado] = useState(false);
    const [comissaoCredito, setComissaoCredito] = useState('400');
    const [valorDaViatura, setValorDaViatura] = useState('');
    const [valorFinalaoCliente, setValorFinalaoCliente] = useState('');
    const [valorPedirAFabi, setValorPedirAFabi] = useState('');
    const [percentagemPedirAFabi, setPercentagemPedirAFabi] = useState('20');






    const location = useLocation();



    useEffect(() => {

        const parametros = new URLSearchParams(location.search)
        // console.log(parametros.size)
        if (parametros.size > 0) {

            setEsconde(false);
            setNomeGeradoDaProposta('')
            // console.log('entrou aqui')

            const nomePropostaParam = parametros.get('nome_proposta');
            const idPropostaParam = parametros.get('id_proposta');
            const idDealParam = parametros.get('id_deal');

            if (nomePropostaParam || idPropostaParam) {
                // console.log('entrou aqui')
                setCurrentLanguage('pt')
                if (nomePropostaParam) {
                    setNomeGeradoDaProposta(nomePropostaParam);
                }
                if (idPropostaParam) {
                    setNomeGeradoDaProposta(idPropostaParam);
                }
                // setNomeGeradoDaProposta(nomePropostaParam)
                setEstadoDaPartilhaNomeDaProposta(true)
                setTeste(true)
                if (!isAuthenticated) {
                    setNovaPesquisa(false);
                    setTemParametros(true);
                } else {
                    setNovaPesquisa(true);
                    setTemParametros(false);
                }
            } else if (idDealParam) {
                setIdDoDealAssociar(idDealParam)
            }
        } else {
            setEstadoDaPartilhaNomeDaProposta(false);
        }
    }, []);

    // console.log('idDeal', idDoDealAssociar)

    // console.log('esconde', esconde)
    // console.log('erroPedido', erroPedido)
    // console.log('urlCarroLink', urlCarroLink)

    const handleRestoreMainState = () => {
        setTeste(false)
        setEsconde(false);
        setErroPedido(false)
        setUrlCarroLink('');
        setEstadoDaPartilhaNomeDaProposta(false)
        navigate(-1)
    }

    //valida se tem parametros na url
    useEffect(() => {

        // console.log(estadoDaPartilhaNomeDaProposta)

        if (estadoDaPartilhaNomeDaProposta) {

            console.log('correu')

            setLoading(true);
            setEsconde(true);

            let linkdopedido

            if (validartipodeparametro) {
                // console.log('true')
                // console.log(validartipodeparametro)
                linkdopedido = `${process.env.REACT_APP_GET_PROPOSTA_PT}?nome_proposta=${nomeGeradoDaProposta}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`
            } else {
                // console.log('false')
                // console.log(validartipodeparametro)
                linkdopedido = `${process.env.REACT_APP_GET_PROPOSTA_PT}?id_proposta=${nomeGeradoDaProposta}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`
            }

            axios.get(linkdopedido)
                .then((response) => {

                    // console.log(response)

                    const whatsappFuncionario = response?.data?.user.whatsapp
                    const emailInternoFuncionario = response?.data?.user.email_interno
                    const fotoPerfilInternoFuncionario = response?.data?.user.foto_perfil
                    const nomePerfilInternoFuncionario = response?.data?.user.nome_user
                    setNomePerfilDataFuncionario(nomePerfilInternoFuncionario)
                    setFotoPerfilDataFuncionario(fotoPerfilInternoFuncionario)
                    setWhatsappDataFuncionario(whatsappFuncionario)
                    setEmailDataInternoFuncionario(emailInternoFuncionario)

                    if (response.data.sucesso) {
                        setLoading(false);
                        setEsconde(false);
                        setShowHubspot(true)

                        if (response.data.hubspot.deal) {
                            const hubspot = response.data?.hubspot
                            console.log('correu aqui')
                            setIdDoDealAtual(response.data.hubspot.deal.id_deal_hubspot)
                            setCheckDataHubspot(hubspot)
                            setNomeDoDeal(hubspot.deal.info.properties.dealname)
                            setNomeDoClienteDeal(hubspot.contacto.info.properties.firstname)
                            setEmailDoClienteDeal(hubspot.contacto.info.properties.email)
                            setDataDeAssociacaoDoDeal(hubspot.deal.data_registo_hubspot)
                            setEtapaDoDeal(hubspot.deal.info.properties.dealstage)
                            setPipelineDoDeal(hubspot.deal.info.properties.pipeline)
                        }
                        // console.log('hubspot', hubspot)
                        const dataLinkPartilhado = response.data.data
                        // console.log('dataLinkPartilhadodataLinkPartilhado', dataLinkPartilhado)
                        setAllDataLinkPartilhado(dataLinkPartilhado)
                        processosRepetidosNosPedidos(dataLinkPartilhado);

                        setIdDaPropostaEncontrada(dataLinkPartilhado.id_proposta)

                        setValorFinalISV(dataLinkPartilhado.isv)
                        setValorFinalIUC(dataLinkPartilhado.iuc)

                        setIucCilindrada(dataLinkPartilhado.iuc_cilindrada)
                        setIucCO2(dataLinkPartilhado.iuc_co2)
                        setIucAdicionalCO2(dataLinkPartilhado.iuc_adicional_co2)
                        setIucCoeficienteAnoMatriculo(dataLinkPartilhado.iuc_coeficiente_ano_matriculo)
                        setIucAdicionalGasoleo(dataLinkPartilhado.iuc_adicional_gasoleo)


                        setIsvComponenteCilindrada(dataLinkPartilhado.isv_componente_cilindrada)
                        setIsvcomponenteambiental(dataLinkPartilhado.isv_componente_ambiental)
                        setIsvReducaoAnosUsoCilindrada(dataLinkPartilhado.isv_reducao_anos_uso_cilindrada)
                        setIsvReducaoAnosUsoAmbiental(dataLinkPartilhado.isv_reducao_anos_uso_ambiental)
                        setIsvAgravamentoParticulas(dataLinkPartilhado.isv_agravamento_particulas)
                        setIsvReducaoAnosUsoParticulas(dataLinkPartilhado.isv_reducao_anos_uso_particulas)
                        setIsvTaxaAplicavelTabela(dataLinkPartilhado.isv_taxa_aplicavel_tabela)


                        setTaxaDeServico(dataLinkPartilhado.taxadeservico)
                        setInfoInicialPartilhaProposta(response.data.data_expira)
                        setIgnorarAvisos(true);
                        setTabResultadoSimulacao(response.data.data.empresa)



                        if (dataLinkPartilhado.tipo_de_combustivel === "Eletrico") {
                            // console.log('asdsadas')
                            setValidarViaturaEletrico(true)
                        } else {
                            setValidarViaturaEletrico(false)
                            // console.log('asdsasfasfdafdfafasadas')
                        }


                    } else {

                        setErroInicial(response.data.mensagem)
                        setErroPedido(true)
                        setLoading(false);
                        setEsconde(false);

                    }


                })
                .catch((error) => {


                })
                .finally(() => {

                });
        }


    }, [estadoDaPartilhaNomeDaProposta]);


    //Pedido
    useEffect(() => {

        setCheckDataHubspot([])
        setErroPedido(false)
        setTipoDeVeiculoParaInsencoes('')
        setNovoOuUsado('')
        setDataDeRegisto('')
        setCilindrada('')
        setTipoDeCombustivel('')
        setParticulas('')
        setTipoDeTestes('')
        setEmissoesGases('')
        setPrecoFinalCarro('')
        setValidarAnoDeRegisto(false)
        setAlertasNoPedido(false)
        setValorChaveNaMaoEmpresa('')
        setValorChaveNaMaoParticular('')
        setValorChaveNaMaoParaCredito('')
        setCo2IsChecked(false);
        setWltpIsChecked(false);
        setHibridoIsChecked(false)
        setValidarViaturaEletrico(false)
        setIvaNaOrigem(false)
        setValorMediaNacional('')
        setShowHubspot(false)
        setIucCilindrada('')
        setIucCO2('')
        setIucAdicionalCO2('')
        setIucCoeficienteAnoMatriculo('')
        setIucAdicionalGasoleo('')


        setIsvComponenteCilindrada('')
        setIsvcomponenteambiental('')
        setIsvReducaoAnosUsoCilindrada('')
        setIsvReducaoAnosUsoAmbiental('')
        setIsvAgravamentoParticulas('')
        setIsvReducaoAnosUsoParticulas('')
        setIsvTaxaAplicavelTabela('')


        if (!urlCarroLink) {
            setErroInicial('Insira o URL da viatura.')
            return;
        } else {

            setErroPedido(false)
            setLoading(true);
            setEsconde(true);

            const fetchData = async () => {
                try {

                    const requestUrl = `${process.env.REACT_APP_PEDIDO_PROPOSTA}?request=${encodeURIComponent(urlCarroLink)}&user_id=${iDTokenAuthenticated}&token=${saveToken}&lang=${currentLanguage}`;


                    const response = await axios.get(requestUrl);
                    console.log(response)
                    const dataFinalDadosObjeto = response.data;
                    //setTodosOsDadosDoCarro(dataFinalDadosObjeto)

                    if (dataFinalDadosObjeto.car) {

                        // TRATAR OS DADOS (SEJA AUTO SCOUT OU MOBILE.DE)
                        processosRepetidosNosPedidos(dataFinalDadosObjeto.car);

                        // Guardar dados do URL
                        setDataCarros(dataFinalDadosObjeto.car);

                    } else {
                        setLoading(false);
                        setEsconde(false);
                        setErroPedido(true);

                        if (dataFinalDadosObjeto.erro) {
                            setErroInicial(dataFinalDadosObjeto.erro)
                        }

                    }


                } catch (error) {
                    setLoading(false);
                    setEsconde(false);
                    setErroPedido(true);
                    console.error('Error fetching data:', error);
                }
            };

            if (urlCarroLink) {
                // CHAMAR A FUNCAO ACIMA (TEM QUE SER FUNCAO POR SER ASYNC) (ESPERAR QUE TERMINE PARA EXECUTAR O RESTANTE CODIGO ABAIXO)
                fetchData();
            }

        }

    }, [urlCarroLink]);


    useEffect(() => {
        setUrlCarroLink(linkDaProposta)
    }, [linkDaProposta]);



    // CODIGO QUE SEJA EXATAMENTE O MESMO PARA OS DOIS PEDIDOS (AUTOSCOUT24 E MOBILE.DE ou + no futuro)
    function processosRepetidosNosPedidos(dataFinalDadosObjeto) {

        console.log(dataFinalDadosObjeto)

        if (estadoDaPartilhaNomeDaProposta) {
            setDataCarros(dataFinalDadosObjeto)
        }

        if (dataFinalDadosObjeto.novo_ou_usado === "1") {
            setNovoOuUsado('Novo')
        } else {
            setNovoOuUsado('Usado')
        }

        // IVA NA ORIGEM
        setIvaNaOrigem(dataFinalDadosObjeto.tem_iva)
        setValorIvaNaOrigem(dataFinalDadosObjeto.perc_iva)


        // Tipo de Combustivel
        setTipoDeCombustivel(dataFinalDadosObjeto.tipo_combustivel_traduzido)

        // CILINDRADA
        setCilindrada(dataFinalDadosObjeto.cilindrada)

        // EMISSOES
        setEmissoesGases(dataFinalDadosObjeto.emissoes_numerico)

        // Data primeiro Registo
        setDataDeRegisto(dataFinalDadosObjeto.data_registo ? dataFinalDadosObjeto.data_registo : '')



        // TIPO DE TESTES:
        setTipoDeTestes('NEDC');
        setValidarAnoDeRegisto(false)
        setAnoDoRegisto(false)

        // PARA CIMA DE 10/2018 - WLTP POR DEFEITO
        let data_registo = dataFinalDadosObjeto.data_registo;

        // PRECO DA VIATURA (COM IVA)
        const precoViatura = dataFinalDadosObjeto.preco_final_carro ? dataFinalDadosObjeto.preco_final_carro : "";
        setPrecoFinalCarro(precoViatura)


        setPrecoFinalDoCarroIvaDedutival(false)
        let precoViaturaSemIva = precoViatura;

        if (dataFinalDadosObjeto.preco_final_carro_sem_iva) { // SE EXISTIR SEM IVA
            setPrecoFinalDoCarroIvaDedutival(true)
            precoViaturaSemIva = dataFinalDadosObjeto.preco_final_carro_sem_iva;
        }
        setPrecoFinalDoCarroSemIva(precoViaturaSemIva)

        //validar a quantidade de KM
        let quantidadeKM = dataFinalDadosObjeto.quantidade_de_km ? dataFinalDadosObjeto.quantidade_de_km : "";

        if (quantidadeKM) {
            quantidadeKM = parseFloat(quantidadeKM.toString().replace(",", ""));
        }

        let tipo_combustivel_raw = dataFinalDadosObjeto.tipo_de_combustivel ? dataFinalDadosObjeto.tipo_de_combustivel : "";
        let tipo_combustivel = dataFinalDadosObjeto.tipo_combustivel_traduzido ? dataFinalDadosObjeto.tipo_combustivel_traduzido : "";

        if (data_registo) {

            // SEPARAR AS DATA EM DUAS VARIAVEIS
            let [data_registo_month, data_registo_year] = data_registo.split('/');
            // CRIAR COMO DATA (ANO, MES)
            data_registo = new Date(data_registo_year, data_registo_month - 1);
            // CRIAR A DATA DO WLTP
            let data_wltp = new Date(2018, 10 - 1);

            // COMPARAR DATAS
            if (data_registo > data_wltp) {
                //setTipoDeTestes('WLTP');
                setWltpIsChecked(true);
                setValidarAnoDeRegisto(true)
            }


            // //Validar o ano do Registo
            // const validarAnoDoRegisto = data_registo
            // console.log('validarAnoDoRegisto', validarAnoDoRegisto);
            // // Convertendo a string para um objeto Date
            // const dataRegistro = new Date(validarAnoDoRegisto);
            // // Obtendo a data atual
            // const dataAtual = new Date();
            // // Adicionando 6 meses à data atual para obter a data limite
            // const dataLimite = new Date();
            // dataLimite.setMonth(dataAtual.getMonth() + 6);
            // // Comparando as datas

            // console.log('dataRegistro', dataRegistro)
            // console.log('dataLimite', dataLimite)
            // if (dataLimite <= dataRegistro) {
            //     console.log('correu aqui')
            //     setAnoDoRegisto(true)
            // }

            const validarAnoDoRegisto = data_registo;
            console.log('validarAnoDoRegisto', validarAnoDoRegisto);

            // Convertendo a string para um objeto Date
            const dataRegistro = new Date(validarAnoDoRegisto);

            // Obtendo a data atual
            const dataAtual = new Date();

            // Subtraindo 6 meses da data atual para obter a data limite
            const dataLimite = new Date();
            dataLimite.setMonth(dataAtual.getMonth() - 6);

            // Comparando as datas
            console.log('dataRegistro', dataRegistro);
            console.log('dataLimite', dataLimite);
            if (dataRegistro >= dataLimite) {
                console.log('correu aqui');
                setAnoDoRegisto(true);
            }


        }


        //validar a quantidade de KM
        if (quantidadeKM < 6000) {

            setQuantidadeDeKM(true)
        } else {

            setQuantidadeDeKM(false)
        }


        //Validar eletrico
        if (tipo_combustivel_raw === "Electric") {
            setValidarViaturaEletrico(true)
            setValorFinalISV('0€')
            setAlertasNoPedido(false)
            setValidarAnoDeRegisto(false)
            setCo2IsChecked(true)
        } else {
            setValidarViaturaEletrico(false)
        }


        //validar hibrido
        if (tipo_combustivel_raw === "Hybrid (petrol/electric)") {
            setHibridoIsChecked(true)
            setValorFinalISV('0€')
            setCo2IsChecked(false)
            setTipoDeCombustivel('Gasolina')

        } else if (tipo_combustivel_raw === "Hybrid (diesel/electric)") {
            setHibridoIsChecked(true)
            setValorFinalISV('0€')
            setCo2IsChecked(false)
            setTipoDeCombustivel('Gasoleo')
            tipo_combustivel = 'Gasoleo'
        } else {
            setHibridoIsChecked(false)
        }



        // DEFINIR PARTICULAS SE FOR GASOLEO OU GASOLINA
        if (tipo_combustivel.toLowerCase() == "gasoleo") {
            setParticulas('1')
        } else {
            setParticulas('0')
        }





        if (!estadoDaPartilhaNomeDaProposta) {
            setLoading(false);
        }

        setEsconde(true);

    }




    useEffect(() => {

        // console.log('corre')

        if (IgnorarAvisos || estadoDaPartilhaNomeDaProposta || !urlCarroLink) {
            return;
        }


        if (validarAnoDeRegisto) {
            setValorFinalISV('0')
            if (!co2IsChecked) {
                setAlertasNoPedido(true)
                return
            }
        }



        if (!hibridoValidationIsChecked && hibridoIsChecked) {
            setValorFinalISV('0€')
            return;
        }



        if (hibridoIsChecked && hibridoValidationIsChecked && emissoesGases >= 50) {
            setValorFinalISV('0€')
            return;
        }


        let ignore_isv = false;
        // SE FOR ELETRICO N FAZ PEDIDO
        if (validarViaturaEletrico) {
            setValorFinalISV('0€')
            setAlertasNoPedido(false)
            ignore_isv = true;
        }



        // SE NAO CONFIRMO EMISSOES CO2
        if (alertasNoPedido) {
            setValorFinalISV('0€')
            return
        }


        let pedidoChaveNaMao
        let pedidoIUC
        let tipoDeVeiculoFinal = tipoDeVeiculoParaOutros;

        if (hibridoIsChecked) {
            tipoDeVeiculoFinal = 'HibridosPlugIn';
        } else {
            tipoDeVeiculoFinal = tipoDeVeiculoParaOutros;

        }

        let tipoDeTestes = 'NEDC';



        // WLTP
        if (wltpIsChecked) {
            tipoDeTestes = 'WLTP';
        }


        if (typeof urlCarroLink === '' && estadoDaPartilhaNomeDaProposta) {
            return;
        }

        let novo_usado_int = 0;
        if (novoOuUsado === "Novo") {

            novo_usado_int = 1;
        }


        if (!ignore_isv) {
            pedidoChaveNaMao = `${process.env.REACT_APP_PEDIDO_ISV}?novo=${novo_usado_int}&tipo_de_testes_co2=${tipoDeTestes}&tipo_carro=${tipoDeVeiculoFinal}&combustivel=${tipoDeCombustivel}&particulas=${particulas}&co2=${emissoesGases}&cilindrada=${cilindrada}&ano=${dataDeRegisto}&isencao=${tipoDeVeiculoParaInsencoes}&dev`;


            axios.get(pedidoChaveNaMao)
                .then((response) => {
                    console.log('response', response)

                    setValorFinalISV(response.data.isv)
                    setDataGeralISV(response.data)
                    setIsvComponenteCilindrada(response.data.taxas.componente_cilindrada)
                    setIsvcomponenteambiental(response.data.taxas.componente_ambiental)
                    setIsvReducaoAnosUsoCilindrada(response.data.taxas.reducao_anos_uso_cilindrada)
                    setIsvReducaoAnosUsoAmbiental(response.data.taxas.reducao_anos_uso_ambiental)
                    setIsvAgravamentoParticulas(response.data.taxas.agravamento_particulas)
                    setIsvReducaoAnosUsoParticulas(response.data.taxas.reducao_anos_uso_particulas)
                    setIsvTaxaAplicavelTabela(response.data.taxas.taxa_aplicavel_tabela)

                })
                .catch((error) => {

                });
        }


        // console.log(cilindrada)


        pedidoIUC = `${process.env.REACT_APP_PEDIDO_IUC}?cilindrada=${cilindrada}&ano=${dataDeRegisto}&combustivel=${tipoDeCombustivel}&co2=${emissoesGases}&tipo_carro=${tipoDeVeiculoParaOutros}&tipo_de_testes_co2=${tipoDeTestes}&novo=${novo_usado_int}`;

        axios.get(pedidoIUC)
            .then((response) => {
                console.log('response', response)
                setValorFinalIUC(response.data.iuc)
                setDataGeralIUC(response.data)
                setIucCilindrada(response.data.taxas.cilindrada)
                setIucCO2(response.data.taxas.co2)
                setIucAdicionalCO2(response.data.taxas.adicional_co2)
                setIucCoeficienteAnoMatriculo(response.data.taxas.coeficiente_ano_matricula)
                setIucAdicionalGasoleo(response.data.taxas.adicional_gasoleo)

            })
            .catch((error) => {

            });



    }, [hibridoValidationIsChecked, tipoDeTestes, co2IsChecked, hibridoIsChecked, wltpIsChecked, tipoDeVeiculo, tipoDeVeiculoParaOutros, tipoDeCombustivel, particulas, emissoesGases, cilindrada, dataDeRegisto, tipoDeVeiculoParaInsencoes, novoOuUsado]);





    useEffect(() => {


        let chaveNaMaoValue = ""
        let precoFinalCarroCal = ""

        let taxaDeServicoCal = parseFloat(taxaDeServico);
        let valorFinalISVCal = parseFloat(valorFinalISV);
        let comissaoCreditoCal = parseFloat(comissaoCredito);

        if (isNaN(taxaDeServicoCal)) {
            taxaDeServicoCal = 0;
        }

        // Particular
        precoFinalCarroCal = parseFloat(precoFinalCarro);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {

            if (isCheckedCredito) {
                console.log(isCheckedCredito)
                console.log(comissaoCreditoCal)
                chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal + comissaoCreditoCal;
                console.log(chaveNaMaoValue)
                setValorChaveNaMaoParticular(chaveNaMaoValue);
                setValorChaveNaMaoParaCredito(chaveNaMaoValue)
            } else {
                chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
                console.log(chaveNaMaoValue)
                setValorChaveNaMaoParticular(chaveNaMaoValue);
                setValorChaveNaMaoParaCredito(chaveNaMaoValue)
            }

        } else {


            if (isCheckedCredito) {

                chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + comissaoCreditoCal;
                setValorChaveNaMaoParticular(chaveNaMaoValue);
                setValorChaveNaMaoParaCredito(chaveNaMaoValue)
            } else {
                chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
                setValorChaveNaMaoParticular(chaveNaMaoValue);
                setValorChaveNaMaoParaCredito(chaveNaMaoValue)
            }


        }

        // EMPRESA
        precoFinalCarroCal = parseFloat(precoFinalDoCarroSemIva);
        if (!isNaN(precoFinalCarroCal) && !isNaN(valorFinalISVCal)) {


            if (isCheckedCredito) {
                chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal + comissaoCreditoCal;
                setValorChaveNaMaoEmpresa(chaveNaMaoValue);
            } else {

                chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + valorFinalISVCal;
                setValorChaveNaMaoEmpresa(chaveNaMaoValue);
            }


        } else {


            if (isCheckedCredito) {
                chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal + comissaoCreditoCal;
                // Round up no decimals
                setValorChaveNaMaoEmpresa(chaveNaMaoValue);
            } else {

                chaveNaMaoValue = precoFinalCarroCal + taxaDeServicoCal;
                // Round up no decimals
                setValorChaveNaMaoEmpresa(chaveNaMaoValue);
            }



        }


    }, [precoFinalCarro, taxaDeServico, valorFinalISV, comissaoCredito, isCheckedCredito]);


    const tituloDaPropostaChaveNaMaoComLinkFinal = () => (
        <a href={urlCarroLink}>{tituloDaPropostaChaveNaMao}</a>
    );


    const tituloDaPropostaChaveNaMaoComLinkFinalParticular = () => (
        <a href={urlCarroLink}>{tituloDaPropostaChaveNaMaoParticular}</a>
    );

    // console.log('IgnorarAvisos',IgnorarAvisos)
    // console.log('estadoDaPartilhaNomeDaProposta', estadoDaPartilhaNomeDaProposta)
    // console.log('temParametros', temParametros)


    // const url = `https://view.importrust.com/${currentLanguage}/simulador/?${t('propostas.nome_proposta_link')}=${nomeGeradoDaProposta}`;


    const handleCredito = (checked) => {
        setIsCheckedCredito(checked);
    };


    return (


        <>

            <div className='w-full flex flex-col justify-center items-center gap-4'>
                <div className='grid gap-4 grid-cols-1 w-full h-full'>
                    {!estadoDaPartilhaNomeDaProposta && iDTokenAuthenticated ? (
                        <div className='card'>
                            <div className='titulo-form'>
                                <p>{t('simulador.titulo_principal')}</p>
                            </div>
                            <PesquisaSimulacao
                                setEstadoDaPartilhaNomeDaProposta={setEstadoDaPartilhaNomeDaProposta}
                                setEsconde={setEsconde}
                                urlCarroLink={urlCarroLink}
                                setUrlCarroLink={setUrlCarroLink}
                            />
                        </div>
                    ) : null}
                    <div className="w-full text-white">
                        <div>
                            {esconde ? (
                                <>
                                    {loading ? (
                                        <Loading />
                                    ) : (
                                        <div className='grid gap-4'>
                                            <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                                <div className='w-full sm:w-3/5'>

                                                    {dataCarros ? (
                                                        <div>
                                                            {estadoDaPartilhaNomeDaProposta ? (
                                                                <TabelaViaturaCliente
                                                                    nomeGeradoDaProposta={nomeGeradoDaProposta}
                                                                    allDataLinkPartilhado={allDataLinkPartilhado}
                                                                    formatarNumero={formatarNumero}
                                                                    dataCarros={dataCarros}
                                                                    isParticular={isParticular}
                                                                />
                                                            ) : (
                                                                <TabelaViatura
                                                                    currentLanguage={currentLanguage}
                                                                    formatarNumero={formatarNumero}
                                                                    dataCarros={dataCarros}
                                                                    isParticular={isParticular}
                                                                    urlCarroLink={urlCarroLink}
                                                                    valorMediaNacional={valorMediaNacional}
                                                                    setValorMediaNacional={setValorMediaNacional}
                                                                />
                                                            )}
                                                        </div>
                                                    ) : null}

                                                </div>
                                                <div className="w-full sm:w-2/5 flex flex-col gap-4">
                                                    {estadoDaPartilhaNomeDaProposta ? (
                                                        <CalculoChaveMaoVista
                                                            allDataLinkPartilhado={allDataLinkPartilhado}
                                                            urlCarroLink={urlCarroLink}
                                                            validarViaturaEletrico={validarViaturaEletrico}
                                                            temParametros={temParametros}
                                                            dataDeRegisto={dataDeRegisto}
                                                            novoOuUsado={novoOuUsado}
                                                            tipoDeCombustivel={tipoDeCombustivel}
                                                            setTaxaDeServico={setTaxaDeServico}
                                                            tipoDeVeiculoParaOutros={tipoDeVeiculoParaOutros}
                                                            hibridoIsChecked={hibridoIsChecked}
                                                            setCo2IsChecked={setCo2IsChecked}
                                                            setHibridoValidationIsChecked={setHibridoValidationIsChecked}
                                                            setNovoOuUsado={setNovoOuUsado}
                                                            setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                                            setOutrosServicos={setOutrosServicos}
                                                            setAlertasNoPedido={setAlertasNoPedido}
                                                            setCilindrada={setCilindrada}
                                                            setDataDeRegisto={setDataDeRegisto}
                                                            setEmissoesGases={setEmissoesGases}
                                                            setHibridoIsChecked={setHibridoIsChecked}
                                                            setTipoDeCombustivel={setTipoDeCombustivel}
                                                            setParticulas={setParticulas}
                                                            setTipoDeVeiculoParaOutros={setTipoDeVeiculoParaOutros}
                                                            setWltpIsChecked={setWltpIsChecked}
                                                            setTipoDeVeiculoParaInsencoes={setTipoDeVeiculoParaInsencoes}
                                                            alertasNoPedido={alertasNoPedido}
                                                            taxaDeServico={taxaDeServico}
                                                            tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                                            hibridoValidationIsChecked={hibridoValidationIsChecked}
                                                            outrosServicos={outrosServicos}
                                                            cilindrada={cilindrada}
                                                            emissoesGases={emissoesGases}
                                                            wltpIsChecked={wltpIsChecked}
                                                            particulas={particulas}
                                                            validarAnoDeRegisto={validarAnoDeRegisto}
                                                            co2IsChecked={co2IsChecked}
                                                            valorFinalISV={valorFinalISV}
                                                            dataGeralISV={dataGeralISV}
                                                            quantidadeDeKM={quantidadeDeKM}
                                                            anoDoRegisto={anoDoRegisto}
                                                            IgnorarAvisos={IgnorarAvisos}
                                                            setComissaoDeServico={setComissaoDeServico}
                                                            comissaoDeServico={comissaoDeServico}
                                                        />
                                                    ) : (
                                                        <CalculoChaveMao
                                                            allDataLinkPartilhado={allDataLinkPartilhado}
                                                            urlCarroLink={urlCarroLink}
                                                            validarViaturaEletrico={validarViaturaEletrico}
                                                            temParametros={temParametros}
                                                            dataDeRegisto={dataDeRegisto}
                                                            novoOuUsado={novoOuUsado}
                                                            tipoDeCombustivel={tipoDeCombustivel}
                                                            setTaxaDeServico={setTaxaDeServico}
                                                            tipoDeVeiculoParaOutros={tipoDeVeiculoParaOutros}
                                                            hibridoIsChecked={hibridoIsChecked}
                                                            setCo2IsChecked={setCo2IsChecked}
                                                            setHibridoValidationIsChecked={setHibridoValidationIsChecked}
                                                            setNovoOuUsado={setNovoOuUsado}
                                                            setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                                            setOutrosServicos={setOutrosServicos}
                                                            setAlertasNoPedido={setAlertasNoPedido}
                                                            setCilindrada={setCilindrada}
                                                            setDataDeRegisto={setDataDeRegisto}
                                                            setEmissoesGases={setEmissoesGases}
                                                            setHibridoIsChecked={setHibridoIsChecked}
                                                            setTipoDeCombustivel={setTipoDeCombustivel}
                                                            setParticulas={setParticulas}
                                                            setTipoDeVeiculoParaOutros={setTipoDeVeiculoParaOutros}
                                                            setWltpIsChecked={setWltpIsChecked}
                                                            setTipoDeVeiculoParaInsencoes={setTipoDeVeiculoParaInsencoes}
                                                            alertasNoPedido={alertasNoPedido}
                                                            taxaDeServico={taxaDeServico}
                                                            tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                                            hibridoValidationIsChecked={hibridoValidationIsChecked}
                                                            outrosServicos={outrosServicos}
                                                            cilindrada={cilindrada}
                                                            emissoesGases={emissoesGases}
                                                            wltpIsChecked={wltpIsChecked}
                                                            particulas={particulas}
                                                            validarAnoDeRegisto={validarAnoDeRegisto}
                                                            co2IsChecked={co2IsChecked}
                                                            valorFinalISV={valorFinalISV}
                                                            dataGeralISV={dataGeralISV}
                                                            quantidadeDeKM={quantidadeDeKM}
                                                            anoDoRegisto={anoDoRegisto}
                                                            IgnorarAvisos={IgnorarAvisos}
                                                            formatarNumero={formatarNumero}
                                                            setComissaoDeServico={setComissaoDeServico}
                                                            comissaoDeServico={comissaoDeServico}
                                                        />
                                                    )}


                                                    {!estadoDaPartilhaNomeDaProposta && (
                                                        <>
                                                            <div>
                                                                <div className='card flex flex-col gap-4'>
                                                                    <div className='titulo-form'>
                                                                        <p>Simulação de crédito</p>
                                                                    </div>
                                                                    <div>
                                                                        <label className='flex gap-2 items-center cursor-pointer'>
                                                                            <Switch checked={isCheckedCredito} onChange={handleCredito} />
                                                                            {!isCheckedCredito ? (
                                                                                <span>Sim, esta proposta vai ser a crédito.</span>
                                                                            ) : (
                                                                                <span>Não, esta proposta não vai ser a crédito.</span>
                                                                            )}
                                                                        </label>
                                                                    </div>
                                                                    {/* {!isCheckedCredito && (
                                                                        <div>
                                                                            <label className='flex gap-2 items-center cursor-pointer'>
                                                                                <Switch checked={isCheckedCredito} onChange={handleCredito} />
                                                                                Sim, esta proposta vai ser a crédito.
                                                                            </label>
                                                                        </div>
                                                                    )} */}
                                                                    {isCheckedCredito && (
                                                                        <div className='grid gap-3'>
                                                                            <CreditoMainComp
                                                                                setComissaoCredito={setComissaoCredito}
                                                                                comissaoCredito={comissaoCredito}
                                                                                mostrarResultado={mostrarResultado}
                                                                                setMostrarResultado={setMostrarResultado}
                                                                                formatarNumero={formatarNumero}
                                                                                taxaDeJuroCredito={taxaDeJuroCredito}
                                                                                precoFinalCarro={precoFinalCarro}
                                                                                precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                                                                entradaCredito={entradaCredito}
                                                                                setEntradaCredito={setEntradaCredito}
                                                                                valorFinanciadoCredito={valorFinanciadoCredito}
                                                                                setValorFinanciadoCredito={setValorFinanciadoCredito}
                                                                                prestacoesMesesCredito={prestacoesMesesCredito}
                                                                                setPrestacoesMesesCredito={setPrestacoesMesesCredito}
                                                                                ivaNaOrigem={ivaNaOrigem}
                                                                                valorFinalCredito={valorFinalCredito}
                                                                                setValorFinalCredito={setValorFinalCredito}
                                                                                valorIvaNaOrigem={valorIvaNaOrigem}
                                                                                activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                                                                activeTabKeySimulacaoCredito={activeTabKeySimulacaoCredito}
                                                                                setActiveTabKeySimulacaoCredito={setActiveTabKeySimulacaoCredito}
                                                                                setTabResultadoSimulacao={setTabResultadoSimulacao}
                                                                                valorFinalISV={valorFinalISV}
                                                                                taxaDeServico={taxaDeServico}
                                                                                valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                                                                valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                                                                valorChaveNaMaoParaCredito={valorChaveNaMaoParaCredito}
                                                                                setValorChaveNaMaoParaCredito={setValorChaveNaMaoParaCredito}
                                                                                valorDaViatura={valorDaViatura}
                                                                                setValorDaViatura={setValorDaViatura}
                                                                                valorFinalaoCliente={valorFinalaoCliente}
                                                                                setValorFinalaoCliente={setValorFinalaoCliente}
                                                                                valorPedirAFabi={valorPedirAFabi}
                                                                                setValorPedirAFabi={setValorPedirAFabi}
                                                                                percentagemPedirAFabi={percentagemPedirAFabi}
                                                                                setPercentagemPedirAFabi={setPercentagemPedirAFabi}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}



                                                    <PT_ResultadodaSimulacao
                                                        setIsParticular={setIsParticular}
                                                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                                        setActiveTabKeyResultadoSimulacao={setActiveTabKeyResultadoSimulacao}
                                                        formatarNumero={formatarNumero}
                                                        valorFinalIUC={valorFinalIUC}
                                                        valorFinalISV={valorFinalISV}
                                                        iucCilindrada={iucCilindrada}
                                                        iucCO2={iucCO2}
                                                        iucAdicionalCO2={iucAdicionalCO2}
                                                        iucCoeficienteAnoMatriculo={iucCoeficienteAnoMatriculo}
                                                        iucAdicionalGasoleo={iucAdicionalGasoleo}
                                                        isvcomponentecilindrada={isvcomponentecilindrada}
                                                        isvcomponenteambiental={isvcomponenteambiental}
                                                        isvReducaoAnosUsoCilindrada={isvReducaoAnosUsoCilindrada}
                                                        isvReducaoAnosUsoAmbiental={isvReducaoAnosUsoAmbiental}
                                                        isvAgravamentoParticulas={isvAgravamentoParticulas}
                                                        isvReducaoAnosUsoParticulas={isvReducaoAnosUsoParticulas}
                                                        isvTaxaAplicavelTabela={isvTaxaAplicavelTabela}
                                                        dataGeralIUC={dataGeralIUC}
                                                        taxaDeServico={taxaDeServico}
                                                        tituloDaPropostaChaveNaMao={tituloDaPropostaChaveNaMao}
                                                        precoFinalCarro={precoFinalCarro}
                                                        valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                                        valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                                        temParametros={temParametros}
                                                        tituloDaPropostaChaveNaMaoComLinkFinal={tituloDaPropostaChaveNaMaoComLinkFinal}
                                                        setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                                        dataCarros={dataCarros}
                                                        urlCarroLink={urlCarroLink}
                                                        precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                                        tituloDaPropostaChaveNaMaoComLinkFinalParticular={tituloDaPropostaChaveNaMaoComLinkFinalParticular}
                                                        setTabResultadoSimulacao={setTabResultadoSimulacao}
                                                        tabResultadoSimulacao={tabResultadoSimulacao}
                                                        iDTokenAuthenticated={iDTokenAuthenticated}
                                                        estadoDaPartilhaNomeDaProposta={estadoDaPartilhaNomeDaProposta}
                                                        mostrarResultado={mostrarResultado}
                                                        comissaoCredito={comissaoCredito}
                                                        isCheckedCredito={isCheckedCredito}
                                                    />


                                                    {!estadoDaPartilhaNomeDaProposta ? (
                                                        <>


                                                            <PT_PartilharProposta
                                                                idDoDealAssociar={idDoDealAssociar}
                                                                cilindrada={cilindrada}
                                                                novoOuUsado={novoOuUsado}
                                                                tipoDeCombustivel={tipoDeCombustivel}
                                                                emissoesGases={emissoesGases}
                                                                dataDeRegisto={dataDeRegisto}
                                                                particulas={particulas}
                                                                tipoDeVeiculoParaOutros={tipoDeVeiculoParaOutros}
                                                                tipoDeVeiculoParaInsencoes={tipoDeVeiculoParaInsencoes}
                                                                outrosServicos={outrosServicos}

                                                                precoFinalCarro={precoFinalCarro}
                                                                valorFinalISV={valorFinalISV}
                                                                taxaDeServico={taxaDeServico}

                                                                valorFinalIUC={valorFinalIUC}
                                                                iucCilindrada={iucCilindrada}
                                                                iucCO2={iucCO2}
                                                                iucAdicionalCO2={iucAdicionalCO2}
                                                                iucCoeficienteAnoMatriculo={iucCoeficienteAnoMatriculo}
                                                                iucAdicionalGasoleo={iucAdicionalGasoleo}


                                                                isvcomponentecilindrada={isvcomponentecilindrada}
                                                                isvcomponenteambiental={isvcomponenteambiental}
                                                                isvReducaoAnosUsoCilindrada={isvReducaoAnosUsoCilindrada}
                                                                isvReducaoAnosUsoAmbiental={isvReducaoAnosUsoAmbiental}
                                                                isvAgravamentoParticulas={isvAgravamentoParticulas}
                                                                isvReducaoAnosUsoParticulas={isvReducaoAnosUsoParticulas}
                                                                isvTaxaAplicavelTabela={isvTaxaAplicavelTabela}

                                                                activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                                                tabResultadoSimulacao={tabResultadoSimulacao}

                                                                showHubspot={showHubspot}
                                                                setShowHubspot={setShowHubspot}
                                                                setIdDaPropostaEncontrada={setIdDaPropostaEncontrada}
                                                                setCheckDataHubspot={setCheckDataHubspot}
                                                                setNomeDoDeal={setNomeDoDeal}
                                                                setNomeDoClienteDeal={setNomeDoClienteDeal}
                                                                setEmailDoClienteDeal={setEmailDoClienteDeal}
                                                                setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                                                                setEtapaDoDeal={setEtapaDoDeal}
                                                                setPipelineDoDeal={setPipelineDoDeal}

                                                                setPartilhaNomeDaProposta={setPartilhaNomeDaProposta}
                                                                partilhaNomeDaProposta={partilhaNomeDaProposta}
                                                                tituloDaPropostaChaveNaMaoComLinkFinalParticular={tituloDaPropostaChaveNaMaoComLinkFinalParticular}
                                                                precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                                                valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                                                valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                                                formatarNumero={formatarNumero}
                                                                dataCarros={dataCarros}
                                                                setTituloDaPropostaChaveNaMao={setTituloDaPropostaChaveNaMao}
                                                                tituloDaPropostaChaveNaMao={tituloDaPropostaChaveNaMao}
                                                                urlCarroLink={urlCarroLink}
                                                                setNomeDaProposta={setNomeDaProposta}
                                                                nomeDaProposta={nomeDaProposta}
                                                                saveToken={saveToken}
                                                                iDTokenAuthenticated={iDTokenAuthenticated}

                                                                valorFinanciadoCredito={valorFinanciadoCredito}
                                                                comissaoCredito={comissaoCredito}
                                                                isCheckedCredito={isCheckedCredito}
                                                                valorDaViatura={valorDaViatura}
                                                                valorFinalaoCliente={valorFinalaoCliente}
                                                                valorPedirAFabi={valorPedirAFabi}
                                                                taxaDeJuroCredito={taxaDeJuroCredito}
                                                                entradaCredito={entradaCredito}
                                                                prestacoesMesesCredito={prestacoesMesesCredito}
                                                                comissaoDeServico={comissaoDeServico}
                                                            />



                                                        </>
                                                    ) : null}
                                                    {/* {estadoDaPartilhaNomeDaProposta ? (
                                                        <>
                                                            <FuncContactDetails nomePerfilDataFuncionario={nomePerfilDataFuncionario} fotoPerfilDataFuncionario={fotoPerfilDataFuncionario} whatsappDataFuncionario={whatsappDataFuncionario} emailDataInternoFuncionario={emailDataInternoFuncionario} />
                                                            <LinkPartilhaExpira infoInicialPartilhaProposta={infoInicialPartilhaProposta} />
                                                        </>
                                                    ) : null} */}

                                                    {showHubspot ? (
                                                        <InformacoesDeal
                                                            currentLanguage={currentLanguage}
                                                            setCheckDataHubspot={setCheckDataHubspot}
                                                            checkDataHubspot={checkDataHubspot}
                                                            nomeDoDeal={nomeDoDeal}
                                                            nomeDoClienteDeal={nomeDoClienteDeal}
                                                            emailDoClienteDeal={emailDoClienteDeal}
                                                            dataDeAssociacaoDoDeal={dataDeAssociacaoDoDeal}
                                                            etapaDoDeal={etapaDoDeal}
                                                            pipelineDoDeal={pipelineDoDeal}
                                                            idDaPropostaEncontrada={idDaPropostaEncontrada}
                                                            saveToken={saveToken}
                                                            idDoDealAtual={idDoDealAtual}
                                                            iDTokenAuthenticated={iDTokenAuthenticated}
                                                            setShowHubspot={setShowHubspot}
                                                            setIdDaPropostaEncontrada={setIdDaPropostaEncontrada}
                                                            setNomeDoDeal={setNomeDoDeal}
                                                            setNomeDoClienteDeal={setNomeDoClienteDeal}
                                                            setEmailDoClienteDeal={setEmailDoClienteDeal}
                                                            setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                                                            setEtapaDoDeal={setEtapaDoDeal}
                                                            setPipelineDoDeal={setPipelineDoDeal}
                                                        />
                                                    ) : null}

                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </>
                            ) : (
                                <></>
                            )}

                            {erroPedido && (
                                <ErroSection whatsappDataFuncionario={whatsappDataFuncionario} emailDataInternoFuncionario={emailDataInternoFuncionario} erroInicial={erroInicial} estadoDaPartilhaNomeDaProposta={estadoDaPartilhaNomeDaProposta} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
