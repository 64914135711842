import { useState, useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Button, Input, Modal, Tabs, Tooltip } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import CalculadoraCredito from './calculadora-credito';
import ProcessoCredito from './processo-credito';


export default function CreditoMainComp({
    formatarNumero,
    taxaDeJuroCredito,
    precoFinalCarro,
    precoFinalDoCarroSemIva,
    entradaCredito,
    setEntradaCredito,
    valorFinanciadoCredito,
    setValorFinanciadoCredito,
    prestacoesMesesCredito,
    setPrestacoesMesesCredito,
    valorFinalCredito,
    setValorFinalCredito,
    ivaNaOrigem,
    valorIvaNaOrigem,
    activeTabKeyResultadoSimulacao,
    activeTabKeySimulacaoCredito,
    setActiveTabKeySimulacaoCredito,
    setTabResultadoSimulacao,
    valorFinalISV,
    taxaDeServico,
    valorChaveNaMaoParticular,
    valorChaveNaMaoEmpresa,
    valorChaveNaMaoParaCredito,
    setValorChaveNaMaoParaCredito,
    mostrarResultado,
    setMostrarResultado,
    comissaoCredito,
    setComissaoCredito,
    valorDaViatura,
    setValorDaViatura,
    valorFinalaoCliente,
    setValorFinalaoCliente,
    valorPedirAFabi,
    setValorPedirAFabi,
    percentagemPedirAFabi,
    setPercentagemPedirAFabi,
}) {


    const handleTabCreditoChange = (key) => {
        setActiveTabKeySimulacaoCredito(key);
        if (activeTabKeySimulacaoCredito === '2') {
            setTabResultadoSimulacao('0')
        } else {
            setTabResultadoSimulacao('1')
        }
    };


    const handleEntradaCredito = (e) => {
        setEntradaCredito(e.target.value);
    };

    const handlePrestacoesMesesCredito = (e) => {
        setPrestacoesMesesCredito(e.target.value);
    };


    const calcularPrestacao = () => {
        const entrada = parseFloat(entradaCredito);
        const montanteNecessario = valorChaveNaMaoParaCredito - entrada;
        setValorFinanciadoCredito(montanteNecessario);

        const valorFinanciado = parseFloat(montanteNecessario);
        const prestacoesMeses = parseFloat(prestacoesMesesCredito);

        if (!isNaN(entrada) && !isNaN(valorFinanciado) && !isNaN(prestacoesMeses)) {
            const montanteNecessario = valorFinanciado - entrada;
            const taxaJurosMensal = taxaDeJuroCredito / (12 * 100); // dividir 12 meses (1 ano) por 100 para converter de percentagem para decimal.
            const prestacao = (montanteNecessario * taxaJurosMensal) / (1 - Math.pow(1 + taxaJurosMensal, -prestacoesMeses));
            setValorFinalCredito(prestacao.toFixed(2));
        }
    };

    const handleCalcular = () => {
        calcularPrestacao();
        setMostrarResultado(true);
    };


    const handleEditarCalcular = () => {
        setMostrarResultado(false);
    };


    const handleComissaoCredito = (e) => {
        setComissaoCredito(e.target.value);
    };



    const isButtonDisabled = () => {
        return !entradaCredito || !prestacoesMesesCredito;
    };


    return (
        <div>
            <div className='bg-white pb-4 pl-4 pr-4 rounded-md'>

                {!mostrarResultado && (
                    <div className='grid gap-4 pt-4'>
                        <div className='grid gap-2'>
                            <div className='grid gap-2'>
                                <div className='flex items-center gap-1'>
                                    <p className='text-black'>Valor da comissão: </p>
                                    <Tooltip title="Não esquecer de cobrar taxa administrativa.">
                                        <AiOutlineInfoCircle className='icon-question' />
                                    </Tooltip>
                                </div>
                                <Input
                                    placeholder="Valor de entrada"
                                    value={comissaoCredito}
                                    onChange={handleComissaoCredito}
                                />

                            </div>
                            <div className='grid gap-2'>
                                <p className='text-black'>Valor de entrada: </p>
                                <Input
                                    placeholder="Valor de entrada"
                                    value={entradaCredito}
                                    onChange={handleEntradaCredito}
                                />
                            </div>
                            <div className='grid gap-2'>
                                <p className='text-black'>Prestações / Meses: </p>
                                <Input
                                    placeholder="Prestações / Meses"
                                    value={prestacoesMesesCredito}
                                    onChange={handlePrestacoesMesesCredito}
                                />
                            </div>
                        </div>
                        <div>
                            <Button type="primary" disabled={isButtonDisabled()} onClick={handleCalcular} className="bg-black text-white font-bold rounded w-full h-full">Calcular Prestação Mensal</Button>
                        </div>
                    </div>
                )}

                {mostrarResultado && (
                    <>
                        <Tabs className='calculo-chave-na-mao' activeKey={activeTabKeySimulacaoCredito} onChange={handleTabCreditoChange}
                            tabBarGutter={16} centered>

                            <TabPane tab={<span className='text-black'>Processo de Crédito</span>}
                                key="1">
                                <div>
                                    <ProcessoCredito
                                        formatarNumero={formatarNumero}
                                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                        ivaNaOrigem={ivaNaOrigem}
                                        valorIvaNaOrigem={valorIvaNaOrigem}
                                        valorFinalISV={valorFinalISV}
                                        taxaDeServico={taxaDeServico}
                                        precoFinalCarro={precoFinalCarro}
                                        precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                        valorDaViatura={valorDaViatura}
                                        setValorDaViatura={setValorDaViatura}
                                        valorFinalaoCliente={valorFinalaoCliente}
                                        setValorFinalaoCliente={setValorFinalaoCliente}
                                        valorPedirAFabi={valorPedirAFabi}
                                        setValorPedirAFabi={setValorPedirAFabi}
                                        percentagemPedirAFabi={percentagemPedirAFabi}
                                        setPercentagemPedirAFabi={setPercentagemPedirAFabi}
                                    />
                                </div>
                            </TabPane>

                            <TabPane tab={<span className='text-black'>Prestação Mensal</span>}
                                key="2">
                                <div>
                                    <CalculadoraCredito
                                        formatarNumero={formatarNumero}
                                        valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                        taxaDeJuroCredito={taxaDeJuroCredito}
                                        entradaCredito={entradaCredito}
                                        setEntradaCredito={setEntradaCredito}
                                        valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                        valorFinanciadoCredito={valorFinanciadoCredito}
                                        setValorFinanciadoCredito={setValorFinanciadoCredito}
                                        prestacoesMesesCredito={prestacoesMesesCredito}
                                        setPrestacoesMesesCredito={setPrestacoesMesesCredito}
                                        valorFinalCredito={valorFinalCredito}
                                        setValorFinalCredito={setValorFinalCredito}
                                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                        valorChaveNaMaoParaCredito={valorChaveNaMaoParaCredito}
                                        setValorChaveNaMaoParaCredito={setValorChaveNaMaoParaCredito}
                                        mostrarResultado={mostrarResultado}
                                        setMostrarResultado={setMostrarResultado}
                                    />
                                </div>
                            </TabPane>

                        </Tabs>

                        <div>
                            <Button type="primary" onClick={handleEditarCalcular} className="bg-black text-white font-bold rounded w-full h-full">Editar Cálculo</Button>
                        </div>
                    </>
                )}
            </div >
        </div >
    );
}
