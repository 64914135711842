import React from "react";
import { Input, Select, Button, Checkbox, Collapse, Tooltip } from 'antd';
import { AlertaMsg } from "./mensagens/mensagem_alerta";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DatePicker } from 'antd';
import { Option } from "antd/lib/mentions";
import { AiOutlineInfoCircle } from "react-icons/ai";


dayjs.extend(customParseFormat);

//const { Option } = Select;
const { Panel } = Collapse;

export default function CalculoChaveMaoVista({
    urlCarroLink,
    dataGeralISV,
    allDataLinkPartilhado,
    validarViaturaEletrico,
    temParametros,
    dataDeRegisto,
    novoOuUsado,
    tipoDeCombustivel,
    setTaxaDeServico,
    tipoDeVeiculoParaOutros,
    hibridoIsChecked,
    setCo2IsChecked,
    setHibridoValidationIsChecked,
    setNovoOuUsado,
    setTituloDaPropostaChaveNaMao,
    setOutrosServicos,
    setAlertasNoPedido,
    setCilindrada,
    setDataDeRegisto,
    setEmissoesGases,
    setHibridoIsChecked,
    setTipoDeCombustivel,
    setParticulas,
    setTipoDeVeiculoParaOutros,
    setWltpIsChecked,
    setTipoDeVeiculoParaInsencoes,
    alertasNoPedido,
    taxaDeServico,
    tipoDeVeiculoParaInsencoes,
    hibridoValidationIsChecked,
    outrosServicos,
    cilindrada,
    emissoesGases,
    wltpIsChecked,
    particulas,
    validarAnoDeRegisto,
    co2IsChecked,
    valorFinalISV,
    quantidadeDeKM,
    anoDoRegisto,
    IgnorarAvisos
}) {


    return (
        <div className='card'>
            <div className='titulo-form'>
                <p className="font-semibold">Cálculo do ISV</p>
            </div>
            <form className='grid gap-5'>
                <div>
                    <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-2">
                        {validarViaturaEletrico ? (
                            <>

                                <div className='grid gap-1 content-start'>
                                    <p className='text-sm text-neutral-500 font-semibold'>Novo / Usado:</p>
                                    <span>{novoOuUsado || 'Sem informação'}</span>
                                </div>
                                <div className='grid gap-1 content-start'>
                                    <p className='text-sm text-neutral-500 font-semibold'>Tipo de Combustível:</p>
                                    <span>{tipoDeCombustivel || 'Sem informação'}</span>
                                </div>

                                {novoOuUsado === "Usado" && (

                                    <div className='grid gap-1 content-start'>
                                        <p className='text-sm text-neutral-500 font-semibold'>Data 1º Registo:</p>
                                        <span>{dataDeRegisto}</span>
                                    </div>

                                )}
                            </>
                        ) : (
                            <>

                                {cilindrada && (
                                    <div className='grid gap-1 content-start'>
                                        <p className='text-sm text-neutral-500 font-semibold'>Cilindrada:</p>
                                        <span>{cilindrada}</span>
                                    </div>
                                )}

                                <div className='grid gap-1 content-start'>
                                    <p className='text-sm text-neutral-500 font-semibold'>Novo / Usado:</p>
                                    <span>{novoOuUsado || 'Sem informação'}</span>
                                </div>


                                {tipoDeCombustivel !== "Eletrico" && (
                                    <>
                                        <div className='grid gap-1 content-start'>
                                            <p className='text-sm text-neutral-500 font-semibold'>Tipo de Combustível:</p>
                                            <span>{tipoDeCombustivel || 'Sem informação'}</span>

                                        </div>
                                        {emissoesGases && (
                                            <div className='grid gap-1 content-start'>
                                                <p className='text-sm text-neutral-500 font-semibold'>Emissões Gases C02:</p>
                                                <span>{emissoesGases}</span>
                                            </div>
                                        )}
                                    </>
                                )}

                                {hibridoIsChecked || wltpIsChecked && (
                                    <>
                                        {hibridoIsChecked && (
                                            <div className='grid gap-1 content-start'>
                                                <p className='text-sm text-neutral-500 font-semibold'>Híbridos Plug-In:</p>
                                                <span>Sim</span>
                                            </div>
                                        )}
                                        <div className='grid gap-1 content-start'>
                                            <p className='text-sm text-neutral-500 font-semibold'>Norma:</p>
                                            {wltpIsChecked ? (
                                                <span>WLTP</span>
                                            ) : (
                                                <span>NDEC</span>
                                            )}
                                        </div>

                                    </>
                                )}

                                {novoOuUsado === "Usado" && (
                                    <div className='grid gap-1 content-start'>
                                        <p className='text-sm text-neutral-500 font-semibold'>Data 1º Registo:</p>
                                        {dataDeRegisto}
                                    </div>
                                )}
                                {tipoDeCombustivel === 'Gasoleo' && (
                                    <div className='grid gap-1 content-start'>
                                        <p className='text-sm text-neutral-500 font-semibold'>Partículas:</p>
                                        {particulas === 1 ? (
                                            <span>&gt;=0,001 g/km</span>
                                        ) : (
                                            <span>&lt;0,001 g/km</span>
                                        )}
                                    </div>
                                )}

                            </>
                        )}
                    </div>
                </div>
            </form>
        </div>

    )
}