import React, { useState } from "react";
import { Button, Input, Spin } from "antd";
import axios from "axios";
import { AlertTwoTone, LoadingOutlined } from '@ant-design/icons';
import { AlertaMsg } from "./mensagens/mensagem_alerta";
import CopyLinkPartilhado from "./copyLinkPartilhado";
import { BiCheckCircle, BiError } from "react-icons/bi";
import CopyButton from "./copybutton";
import { RxExternalLink } from "react-icons/rx";
import CopiarLinkseTextos from "./partilha/select-com-copiesbutton";
import { useTranslation } from 'react-i18next';


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function PT_PartilharProposta({
    idDoDealAssociar,
    valorFinalCredito,
    cilindrada,
    novoOuUsado,
    tipoDeCombustivel,
    emissoesGases,
    dataDeRegisto,
    particulas,
    tipoDeVeiculoParaOutros,
    tipoDeVeiculoParaInsencoes,
    outrosServicos,

    precoFinalCarro,
    valorFinalISV,
    taxaDeServico,

    valorFinalIUC,
    iucCilindrada,
    iucCO2,
    iucAdicionalCO2,
    iucCoeficienteAnoMatriculo,
    iucAdicionalGasoleo,

    isvcomponentecilindrada,
    isvcomponenteambiental,
    isvReducaoAnosUsoCilindrada,
    isvReducaoAnosUsoAmbiental,
    isvAgravamentoParticulas,
    isvReducaoAnosUsoParticulas,
    isvTaxaAplicavelTabela,

    activeTabKeyResultadoSimulacao,

    showHubspot,
    setShowHubspot,
    setCheckDataHubspot,
    setIdDaPropostaEncontrada,
    setNomeDoDeal,
    setNomeDoClienteDeal,
    setEmailDoClienteDeal,
    setDataDeAssociacaoDoDeal,
    setEtapaDoDeal,
    setPipelineDoDeal,

    setPartilhaNomeDaProposta,
    partilhaNomeDaProposta,
    tituloDaPropostaChaveNaMaoComLinkFinalParticular,
    precoFinalDoCarroSemIva,
    valorChaveNaMaoEmpresa,
    valorChaveNaMaoParticular,
    formatarNumero,
    dataCarros,
    setTituloDaPropostaChaveNaMao,
    tituloDaPropostaChaveNaMao,
    urlCarroLink,
    setNomeDaProposta,
    nomeDaProposta,
    saveToken,
    iDTokenAuthenticated,
    erroPedido,
    tabResultadoSimulacao,
    valorFinanciadoCredito,
    isCheckedCredito,
    comissaoCredito,
    valorDaViatura,
    valorFinalaoCliente,
    valorPedirAFabi,
    taxaDeJuroCredito,
    entradaCredito,
    prestacoesMesesCredito,
    comissaoDeServico
}) {


    const { t } = useTranslation();


    //Estados de jogo de sintura
    //#1 Loading
    //#2 Sucesso
    //#3 Alerta


    const [linkPartilhavel, setLinkPartilhavel] = useState('');
    const [validarLinkPartilhavel, setValidarLinkPartilhavel] = useState('');
    const [esconde, setEsconde] = useState(true);
    const [alertaLinkPartilhado, setAlertaLinkPartilhado] = useState('');






    const handlePartilharProposta = () => {
        setValidarLinkPartilhavel('1');
        setAlertaLinkPartilhado('');
    
        let listaDeDadosAtualizados = {
            cilindrada: cilindrada,
            novo: novoOuUsado,
            tipo_de_combustivel: tipoDeCombustivel,
            emissoes_numerico: emissoesGases,
            data_registo: dataDeRegisto,
            particulas: particulas,
            tipo_de_veiculo: tipoDeVeiculoParaOutros,
            tipo_de_isencoes: tipoDeVeiculoParaInsencoes,
            outros_servicos: outrosServicos,
            isv: valorFinalISV,
            iuc: valorFinalIUC,
    
            iuc_cilindrada: iucCilindrada,
            iuc_co2: iucCO2,
            iuc_adicional_co2: iucAdicionalCO2,
            iuc_coeficiente_ano_matriculo: iucCoeficienteAnoMatriculo,
            iuc_adicional_gasoleo: iucAdicionalGasoleo,
    
            isv_componente_cilindrada: isvcomponentecilindrada,
            isv_componente_ambiental: isvcomponenteambiental,
            isv_reducao_anos_uso_cilindrada: isvReducaoAnosUsoCilindrada,
            isv_reducao_anos_uso_ambiental: isvReducaoAnosUsoAmbiental,
            isv_agravamento_particulas: isvAgravamentoParticulas,
            isv_reducao_anos_uso_particulas: isvReducaoAnosUsoParticulas,
            isv_taxa_aplicavel_tabela: isvTaxaAplicavelTabela,
    
            taxadeservico: taxaDeServico,
            comissaodeservico:comissaoDeServico,
            precofinal: precoFinalCarro,
            valor_chavenamao_particular: valorChaveNaMaoParticular,
            valor_chavenamao_empresa: valorChaveNaMaoEmpresa,
            empresa: tabResultadoSimulacao,
        };
    
        // Condicionalmente adicionar os dados de crédito se isCheckedCredito for verdadeiro
        if (isCheckedCredito) {
            listaDeDadosAtualizados = {
                ...listaDeDadosAtualizados,
                comissao_credito: comissaoCredito,
                processo_credito_valor_viatura: valorDaViatura,
                processo_credito_valor_final_ao_cliente: valorFinalaoCliente,
                processo_credito_valor_pedir_fabi: valorPedirAFabi,
                calculadora_credito_taxa_juros: taxaDeJuroCredito,
                calculadora_credito_entrada: entradaCredito,
                calculadora_credito_valor_financiado: valorFinanciadoCredito,
                calculadora_credito_meses: prestacoesMesesCredito,
                calculadora_credito_valor_final: valorFinalCredito,
         
            };
        }

        // const [comissaoDeServico, setComissaoDeServico] = useState('');

    
        const dataCriarLink = {
            nome_proposta: nomeDaProposta,
            array_dados: dataCarros,
            user_id: iDTokenAuthenticated,
            user_token: saveToken,
            id_deal: idDoDealAssociar,
            array_dados_atualizado: listaDeDadosAtualizados,
        };
    
        axios.post(`${process.env.REACT_APP_CREATE_PROPOSTA_PT}`, dataCriarLink)
            .then((response) => {
                console.log(response);
                setLinkPartilhavel(response.data.link_proposta);
                setAlertaLinkPartilhado(response.data.mensagem);
                if (response.data.sucesso === true) {
                    setValidarLinkPartilhavel('2');
                    setEsconde(false);
                    setShowHubspot(true);
                    setIdDaPropostaEncontrada(response.data.id_proposta);
                    if (response.data.hubspot.deal) {
                        const hubspot = response.data.hubspot;
                        setCheckDataHubspot(hubspot);
                        setNomeDoDeal(hubspot.deal.info.properties.dealname);
                        setNomeDoClienteDeal(hubspot.contacto.info.properties.firstname);
                        setEmailDoClienteDeal(hubspot.contacto.info.properties.email);
                        setDataDeAssociacaoDoDeal(hubspot.deal.data_registo_hubspot);
                        setEtapaDoDeal(hubspot.deal.info.properties.dealstage);
                        setPipelineDoDeal(hubspot.deal.info.properties.pipeline);
                    }
                } else {
                    setValidarLinkPartilhavel('3');
                    setEsconde(true);
                }
            })
            .catch((error) => {
                setValidarLinkPartilhavel('3');
                setEsconde(true);
            });
    };
    
    // const handleclickCopy = () => {
    //     setEsconde(true)
    //     setValidarLinkPartilhavel('')
    // }


    const handleChangeNomeDaProposta = (e) => {
        setNomeDaProposta(e.target.value);
        setPartilhaNomeDaProposta(nomeDaProposta)
        setValidarLinkPartilhavel('')
        setEsconde(true)
        setShowHubspot(false)
    }


    // console.log(linkPartilhavel)
    // function calcularISV(valorFinalISV) {
    //     if (valorFinalISV === '0€') {
    //         return 'Isento';
    //     } else {
    //         return formatarNumero(Math.ceil(valorFinalISV));
    //     }
    // }

    return (
        <div className='card'>
            <div className='titulo-form'>
                <p>{t('partilha_da_proposta.titulo_principal')}</p>
            </div>
            <div className="grid gap-4">
                <div className='flex flex-col gap-4'>
                    <Input
                        placeholder={t('partilha_da_proposta.input')}
                        value={nomeDaProposta}
                        onChange={handleChangeNomeDaProposta}
                    />
                    {validarLinkPartilhavel === "1" ? (
                        <>
                            <div className='flex items-center gap-5 flex-col justify-center text-md p-2.5'>
                                <Spin indicator={antIcon} />
                                <p className='text-white'>{t('partilha_da_proposta.gerar')}</p>
                            </div>
                        </>
                    ) : validarLinkPartilhavel === "2" ? (
                        <>
                            <div className="flex flex-col items-center gap-2 text-md border-card bg-black link-partilha-sec">
                                <p className='text-white'>{alertaLinkPartilhado}</p>
                                <div className="flex items-center flex-row gap-4 w-full">
                                    <CopiarLinkseTextos
                                        valorFinalCredito={valorFinalCredito}
                                        precoFinalCarro={precoFinalCarro}
                                        valorFinalISV={valorFinalISV}
                                        taxaDeServico={taxaDeServico}
                                        valorChaveNaMaoParticular={valorChaveNaMaoParticular}
                                        formatarNumero={formatarNumero}
                                        dataCarros={dataCarros}
                                        urlCarroLink={urlCarroLink}
                                        nomeDaProposta={nomeDaProposta}
                                        linkPartilhavel={linkPartilhavel}
                                        valorChaveNaMaoEmpresa={valorChaveNaMaoEmpresa}
                                        activeTabKeyResultadoSimulacao={activeTabKeyResultadoSimulacao}
                                        precoFinalDoCarroSemIva={precoFinalDoCarroSemIva}
                                    // nomeGeradoDaProposta={nomeGeradoDaProposta}
                                    />
                                </div>
                            </div>
                        </>
                    ) : validarLinkPartilhavel === "3" ? (
                        <>
                            <div className="mensagem-de-erro flex flex-row items-center gap-4 rounded p-2">
                                <BiError />
                                <span>{alertaLinkPartilhado}</span >
                            </div>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </div>
                <div className="flex gap-4 justify-center">
                    {esconde ? (
                        <Button type="primary" htmlType="submit" to="/" className="bg-white text-black font-bold rounded w-full"
                            onClick={handlePartilharProposta}>
                            {t('partilha_da_proposta.criar')}
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}
