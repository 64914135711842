import { useState, useEffect } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Button, Input, Tooltip } from "antd";

export default function CalculadoraCredito({
    formatarNumero,
    valorChaveNaMaoParticular,
    taxaDeJuroCredito,
    entradaCredito,
    setEntradaCredito,
    valorChaveNaMaoEmpresa,
    valorFinanciadoCredito,
    setValorFinanciadoCredito,
    prestacoesMesesCredito,
    setPrestacoesMesesCredito,
    valorFinalCredito,
    setValorFinalCredito,
    activeTabKeyResultadoSimulacao,
    valorChaveNaMaoParaCredito,
    setValorChaveNaMaoParaCredito,
    mostrarResultado,
    setMostrarResultado
}) {

    useEffect(() => {
        if (activeTabKeyResultadoSimulacao === '1') {
            setValorChaveNaMaoParaCredito(valorChaveNaMaoParticular);
        } else {
            setValorChaveNaMaoParaCredito(valorChaveNaMaoEmpresa);
        }
    }, [activeTabKeyResultadoSimulacao]);

 



    return (
        <>

            <div className='grid gap-4'>
                <div>
                    <p className='text-black text-base flex justify-between'>Taxa de Juro: <span className='font-bold text-lg'>{taxaDeJuroCredito}%</span></p>
                    <p className='text-black text-base flex justify-between'>Entrada:
                        <span className='font-bold text-lg'>
                            {entradaCredito ? `${formatarNumero(entradaCredito)}€` : `0€`}
                        </span>
                    </p>
                    <p className='text-black text-base flex justify-between'>Valor Financiado:
                        <span className='font-bold text-lg'>
                            {valorFinanciadoCredito ? `${formatarNumero(valorFinanciadoCredito)}€` : `0€`}
                        </span>
                    </p>
                    <p className='text-black text-base flex justify-between'>Montante Necessário: <span className='font-bold text-lg'>{formatarNumero(valorChaveNaMaoParaCredito)}€</span></p>
                    <p className='text-black text-base flex justify-between'>Prestações / Meses:
                        <span className='font-bold text-lg'>
                            {prestacoesMesesCredito ? `${prestacoesMesesCredito} meses` : `0 meses`}
                        </span>
                    </p>
                    <p className='center text-black text-base font-semibold flex justify-between'>Prestação mensal:
                        <span className='flex gap-2 items-center text-lg sm:text-2xl font-extrabold'>
                            {valorFinalCredito ? `${formatarNumero(valorFinalCredito)}€` : `0€`}
                            <Tooltip title="A prestação é um valor aproximado do que será o valor final.">
                                <AiOutlineInfoCircle className='icon-question' />
                            </Tooltip>
                        </span>
                    </p>
                </div>
           
            </div>

        </>
    );
}
