import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation } from 'swiper/modules';
import { AiOutlineEuro } from 'react-icons/ai';
import logo from '../assets/hubspot-icon.svg';
import { Button, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function Dashboard({ currentLanguage, saveToken, iDTokenAuthenticated, userData }) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState('');

    const [dataAnalytics, setDataAnalytics] = useState('');
    const [propostas, setPropostas] = useState('');
    const [marcasNome, setMarcasNome] = useState([]);
    const [marcasQnt, setMarcasQnt] = useState([]);
    const [modelosNome, setModelosNome] = useState([]);
    const [modelosQnt, setModelosQnt] = useState([]);
    const [funcNome, setFuncNome] = useState([]);
    const [funcQnt, setFuncQnt] = useState([]);
    const [combustiveisNome, setCombustiveisNome] = useState([]);
    const [combustiveisQnt, setCombustiveisQnt] = useState([]);


    const [contexto, setContexto] = useState('');
    const [contextoCNM, setContextoCNM] = useState('');

    const [valorTotal, setValorTotal] = useState('');
    const [valorMedia, setValorMedia] = useState('');
    const [valorChaveNaMaoTotal, setValorChaveNaMaoTotal] = useState('');
    const [valorChaveNaMaoMedia, setValorChaveNaMaoMedia] = useState('');


    const [percentageDifference, setPercentageDifference] = useState('');
    const [percentageDifferenceTaxa, setPercentageDifferenceTaxa] = useState('');
    const [percentageDifferencePropostas, setPercentageDifferencePropostas] = useState('');



    useEffect(() => {

        setLoading(true)

        const pedido = `${process.env.REACT_APP_GET_ANALYTICS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&lang=${currentLanguage}`;
        console.log(pedido)

        axios.get(pedido)
            .then((response) => {
                console.log('response', response);
                setDataAnalytics(response.data)
                setPropostas(response.data.info)
                setMarcasQnt(response.data.info.top_marcas.formato_1.qnt)
                setMarcasNome(response.data.info.top_marcas.formato_1.nome)
                console.log(response.data.info.top_marcas.formato_1.nome)
                setModelosNome(response.data.info.top_modelos.formato_1.nome)
                setModelosQnt(response.data.info.top_modelos.formato_1.qnt)
                setFuncNome(response.data.info.users.propostas.formato_1.nome)
                setFuncQnt(response.data.info.users.propostas.formato_1.qnt)
                setCombustiveisNome(response.data.info.combustiveis.formato_1.nome)
                setCombustiveisQnt(response.data.info.combustiveis.formato_1.qnt)

                setValorTotal(response.data.info.totais)
                setValorMedia(response.data.info.medias)


                const propostasCriadas = calculatePercentageDifference(response.data.info.propostas_criadas_hoje, response.data.info.propostas_criadas_ontem)

                const taxaServicoMedia = calculatePercentageDifference(response.data.info.medias.taxa_servico_mes_atual, response.data.info.medias.taxa_servico_mes_anterior)

                const propostasGeradasMes = calculatePercentageDifference(response.data.info.propostas_criadas_mes_atual, response.data.info.propostas_criadas_mes_anterior)


                setPercentageDifference(propostasCriadas)
                setPercentageDifferenceTaxa(taxaServicoMedia)
                setPercentageDifferencePropostas(propostasGeradasMes)

                setLoading(false)

            })
            .catch((error) => {
                console.error('Error occurred:', error);
            });

    }, []);


    const calculatePercentageDifference = (today, yesterday) => {
        if (yesterday === 0) return 0; // Evita divisão por zero
        const difference = today - yesterday;
        console.log(`Today: ${today}, Yesterday: ${yesterday}, Difference: ${difference}`);
        const percentage = (difference / yesterday) * 100;
        console.log(`Percentage: ${percentage}`);
        return percentage.toFixed(2); // Mantém duas casas decimais
    };



    return (
        <>
            {loading ? (
                <>
                    <div className="p-4 flex flex-row gap-4 text-primary">
                        <div className={`main-content flex flex-col gap-5 justify-center`}>
                            <div className="pr-5 pl-5">
                                <div className="basis-1/2 flex flex-col items-center justify-center gap-4 h-full">
                                    <Spin indicator={antIcon} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <div className='mt-4 mb-6'>
                            <span className="text-2xl font-semibold">Olá, {userData?.data?.display_name} 👋</span>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div class="card flex flex-col gap-4 md:...">
                                <Swiper
                                    style={{ width: '100%', cursor: 'grab' }}
                                    autoplay={{
                                        delay: 3500,
                                        disableOnInteraction: false,
                                    }}
                                    spaceBetween={50}
                                    modules={[Autoplay, Navigation]}
                                >
                                    <SwiperSlide>
                                        <div>
                                            <p className='text-sm font-medium pb-4'>{t('dashboard.propostas_criadas_hoje')}</p>
                                            <div className='flex gap-2 items-center'>
                                                <span className='text-2xl font-bold text-center'>
                                                    {propostas.propostas_criadas_hoje}

                                                </span>
                                                {propostas.propostas_criadas_hoje > propostas.propostas_criadas_ontem ?
                                                    <div className='flex items-center text-green-500'>
                                                        <FaArrowUp />
                                                        <span className='ml-1'>{percentageDifference}%</span>
                                                    </div> :
                                                    <div className='flex items-center text-red-500'>
                                                        <FaArrowDown />
                                                        <span className='ml-1'>{percentageDifference}%</span>
                                                    </div>
                                                }
                                            </div>
                                            <span className='text-base text-neutral-500'>
                                                <span className='font-bold text-white'>{propostas.propostas_criadas_ontem}</span> {t('dashboard.propostas_geradas_ontem')}
                                            </span>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <p className='text-sm font-medium pb-4'>{t('dashboard.propostas_criadas_mes')}</p>
                                            <div className='flex gap-2 items-center'>
                                                <span className='text-2xl font-bold text-center'>
                                                    {propostas.propostas_criadas_mes_atual}

                                                </span>
                                                {propostas.propostas_criadas_mes_atual > propostas.propostas_criadas_mes_anterior ?
                                                    <div className='flex items-center text-green-500'>
                                                        <FaArrowUp />
                                                        <span className='ml-1'>{percentageDifferencePropostas}%</span>
                                                    </div> :
                                                    <div className='flex items-center text-red-500'>
                                                        <FaArrowDown />
                                                        <span className='ml-1'>{percentageDifferencePropostas}%</span>
                                                    </div>
                                                }
                                            </div>
                                            <span className='text-base text-neutral-500'>
                                                <span className='font-bold text-white'>{propostas.propostas_criadas_mes_anterior}</span> {t('dashboard.propostas_geradas_mes_passado')}
                                            </span>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>

                                {/* 
                            <p className='text-sm font-medium pb-4'>Propostas geradas hoje</p>
                            <div className='flex gap-2 items-center'>
                                <span className='text-2xl font-bold text-center'>
                                    {propostas.propostas_criadas_hoje}

                                </span>
                                {propostas.propostas_criadas_hoje > propostas.propostas_criadas_ontem ?
                                    <div className='flex items-center text-green-500'>
                                        <FaArrowUp />
                                        <span className='ml-1'>{percentageDifference}%</span>
                                    </div> :
                                    <div className='flex items-center text-red-500'>
                                        <FaArrowDown />
                                        <span className='ml-1'>{percentageDifference}%</span>
                                    </div>
                                }
                            </div>
                            <span className='text-base text-neutral-500'>
                                <span className='font-bold text-white'>{propostas.propostas_criadas_ontem}</span> propostas geradas ontem
                            </span> */}
                            </div>
                            <div class="card grid md:...">
                                <p className='text-sm font-medium pb-4'>{t('dashboard.mediataxadeservico')}</p>
                                <div className='flex gap-2 items-center'>
                                    <span className='text-2xl font-bold text-center'>
                                        {valorMedia.taxa_servico_mes_atual} €
                                    </span>
                                    {valorMedia.taxa_servico_mes_atual > propostas.taxa_servico_mes_anterior ?
                                        <div className='flex items-center text-green-500'>
                                            <FaArrowUp />
                                            <span className='ml-1'>{percentageDifferenceTaxa}%</span>
                                        </div> :
                                        <div className='flex items-center text-red-500'>
                                            <FaArrowDown />
                                            <span className='ml-1'>{percentageDifferenceTaxa}%</span>
                                        </div>
                                    }
                                </div>
                                <span className='text-base text-neutral-500'>
                                    <span className='font-bold text-white'>{valorMedia.taxa_servico_mes_anterior} €</span> {t('dashboard.anteriortaxadeservico')}
                                </span>
                            </div>
                            <div className="card flex flex-col gap-4 md:...">
                                <Swiper
                                    style={{ width: '100%', cursor: 'grab' }}
                                    autoplay={{
                                        delay: 3500,
                                        disableOnInteraction: false,
                                    }}
                                    spaceBetween={50}
                                    modules={[Autoplay, Navigation]}
                                >
                                    <SwiperSlide>
                                        <div>
                                            <div className="flex flex-row items-center justify-between space-y-0 pb-4">
                                                <p className='text-sm font-medium'>{t('dashboard.taxa_de_servico')}</p>
                                                <AiOutlineEuro />
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='text-2xl font-bold'>
                                                    {valorMedia.taxa_servico} €
                                                    <span className='text-base text-neutral-500'>/{t('dashboard.media')}</span>
                                                </span>
                                                <span className='text-base font-bold text-white'>
                                                    {`${valorTotal.taxa_servico} €`}
                                                    <span className='text-neutral-500'> /{t('dashboard.total')}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className="flex flex-row items-center justify-between space-y-0 pb-4">
                                                <p className='text-sm font-medium'>{t('dashboard.preco_da_viatura')}</p>
                                                <AiOutlineEuro />
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='text-2xl font-bold'>
                                                    {valorMedia.pvp} €
                                                    <span className='text-base text-neutral-500'>/{t('dashboard.media')}</span>
                                                </span>
                                                <span className='text-base font-bold text-white'>
                                                    {`${valorTotal.pvp} €`}
                                                    <span className='text-neutral-500'> /{t('dashboard.total')}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className="flex flex-row items-center justify-between space-y-0 pb-4">
                                                <p className='text-sm font-medium'>{t('dashboard.chave_na_mao_particular')}</p>
                                                <AiOutlineEuro />
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='text-2xl font-bold'>
                                                    {valorMedia.chave_na_mao_particular} €
                                                    <span className='text-base text-neutral-500'>/{t('dashboard.media')}</span>
                                                </span>
                                                <span className='text-base font-bold text-white'>
                                                    {`${valorTotal.chave_na_mao_particular} €`}
                                                    <span className='text-neutral-500'> /{t('dashboard.total')}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div>
                                            <div className="flex flex-row items-center justify-between space-y-0 pb-4">
                                                <p className='text-sm font-medium'>{t('dashboard.chave_na_mao_empresas')}</p>
                                                <AiOutlineEuro />
                                            </div>
                                            <div className='flex flex-col'>
                                                <span className='text-2xl font-bold'>
                                                    {valorMedia.chave_na_mao_empresa} €
                                                    <span className='text-base text-neutral-500'>/{t('dashboard.media')}
                                                    </span>
                                                </span>
                                                <span className='text-base font-bold text-white'>
                                                    {`${valorTotal.chave_na_mao_empresa} €`}
                                                    <span className='text-neutral-500'> /{t('dashboard.total')}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                </Swiper>
                            </div>

                            {currentLanguage === "pt" && (
                                <>
                                    <div class="card-hubspot md:...">

                                        <div className='flex items-center gap-4 text-white'>
                                            <div class="flex w-full flex-row items-center justify-between space-y-0 pb-4">
                                                <p class="text-sm font-medium">Propostas não associadas</p>
                                                <img
                                                    src={logo}
                                                    alt="Logo"
                                                    className="h-6 object-contain"
                                                />
                                            </div>
                                        </div>
                                        <div className='flex gap-2 justify-between'>
                                            <span className='text-2xl font-bold text-center'>
                                                {propostas.propostas_hubspot_nao_associadas_hoje}
                                            </span>
                                            <Link to={`/${currentLanguage}/${t('header.propostas')}`}>
                                                <Button
                                                    type="primary"
                                                    target="_blank"
                                                    className="flex gap-2 p-2 items-center bg-black text-white font-bold rounded"
                                                >
                                                    <IoIosArrowForward />
                                                </Button>
                                            </Link>

                                        </div>
                                        {/* <span className='text-base text-neutral-500'>
                                <span className='font-bold text-white'>{propostas.propostas_criadas_ontem}</span> propostas geradas ontem
                            </span> */}
                                    </div>
                                    {/* <div class="card col-span-2 md:...">
                                    </div> */}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

