import React from "react";
import TabeladePropostas from "../../../components/pt/tabelas/tabeladepropostas";
import { useTranslation } from 'react-i18next';
import { Button } from "antd";
import { IoAddCircleOutline } from "react-icons/io5";
import logo from '../../../assets/hubspot.svg';


export default function Propostas({
    currentLanguage,
    saveToken,
    iDTokenAuthenticated,
    formatarNumero,
    showHubspot,
    setShowHubspot,
    checkDataHubspot,
    setCheckDataHubspot,
    nomeDoDeal,
    setNomeDoDeal,
    nomeDoClienteDeal,
    setNomeDoClienteDeal,
    emailDoClienteDeal,
    setEmailDoClienteDeal,
    dataDeAssociacaoDoDeal,
    setDataDeAssociacaoDoDeal,
    etapaDoDeal,
    setEtapaDoDeal,
    pipelineDoDeal,
    setPipelineDoDeal

}) {

    const { t } = useTranslation();


    

    return (
        <>
            <div>
                <TabeladePropostas
                    currentLanguage={currentLanguage}
                    formatarNumero={formatarNumero}
                    saveToken={saveToken}
                    iDTokenAuthenticated={iDTokenAuthenticated}
                    showHubspot={showHubspot}
                    setShowHubspot={setShowHubspot}
                    checkDataHubspot={checkDataHubspot}
                    setCheckDataHubspot={setCheckDataHubspot}
                    nomeDoDeal={nomeDoDeal}
                    setNomeDoDeal={setNomeDoDeal}
                    nomeDoClienteDeal={nomeDoClienteDeal}
                    setNomeDoClienteDeal={setNomeDoClienteDeal}
                    emailDoClienteDeal={emailDoClienteDeal}
                    setEmailDoClienteDeal={setEmailDoClienteDeal}
                    dataDeAssociacaoDoDeal={dataDeAssociacaoDoDeal}
                    setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                    etapaDoDeal={etapaDoDeal}
                    setEtapaDoDeal={setEtapaDoDeal}
                    pipelineDoDeal={pipelineDoDeal}
                    setPipelineDoDeal={setPipelineDoDeal}
                />
            </div>

        </>

    )
}
