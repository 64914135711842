import React, { useState, useRef } from 'react';
import { BiError } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { Input, Table, Image, Spin, Button, Checkbox, Modal } from 'antd';
import { Link } from 'react-router-dom';
import redbubble from '../../../../../assets/red-circle.svg';
import greenbubble from '../../../../../assets/green-circle.svg';
import logoCor from '../../../../../assets/hubspot-icon.svg';


export default function PropostasDoDealHubspot({
    propostasDealsData,
    currentLanguage,
    formatarNumero,
    idDoDeal
}) {

    // console.log('propostasDealsData', propostasDealsData)

    const { t } = useTranslation();

    const inputRef = useRef(null);
    const [pesquisaEmailDeal, setPesquisaEmailDeal] = useState('');
    const [searchQuery, setSearchQuery] = useState('');


    const filteredPropostas = propostasDealsData.filter((data) => {
        const nomeDaProposta = data.nome_proposta.toLowerCase();
        const nomeDoFuncionario = data.nome_criou.toLowerCase();
        const paisDaProposta = data.pais.toLowerCase();

        const searchQueryLowerCase = searchQuery.toLowerCase();

        return (
            nomeDaProposta.includes(searchQueryLowerCase) ||
            nomeDoFuncionario.includes(searchQueryLowerCase) ||
            paisDaProposta.includes(searchQueryLowerCase)
        );
    });


    console.log(propostasDealsData)



    return (
        <>
            <div className='card grid gap-4'>
                <div className='border-b-2 border-solid border-slate-500 flex items-center gap-4 pb-4'>
                    <p className='font-semibold'>Todas as Propostas</p>
                    <div>|</div>
                    <Link to={`/${currentLanguage}/simulador/?id_deal=${idDoDeal}`}>
                        <Button
                            type="primary"
                            target="_blank"
                            className="flex gap-2 items-center bg-white text-black font-bold rounded"
                        >
                            Criar nova proposta
                        </Button>
                    </Link>
                </div>
                <div className="w-full grid gap-4">
                    <div className="tabela-propostas">
                        <Table
                            className='hubspot-content'
                            dataSource={filteredPropostas}
                            rowKey={(propostasDealsData) => propostasDealsData.id_proposta}
                            scroll={{ x: 'max-content' }}
                            pagination={false}
                            bordered
                        >

                            <Table.Column
                                title={t('propostas.criado_por')}
                                key="criado_por"
                                width={100}
                                fixed={"left"}
                                render={(proposta) => (
                                    <span>{proposta.nome_criou}</span>
                                )}
                            />

                            <Table.Column
                                title="País"
                                key="pais"
                                render={(proposta) => (
                                    <span className='uppercase'>{proposta.pais}</span>
                                )}

                            />

                            <Table.Column
                                title={t('propostas.nome_da_proposta')}
                                key="pais"
                                render={(proposta) => (
                                    <Link to={`/${currentLanguage}/simulador/${proposta.expirou == 0 ? `?${t('propostas.nome_proposta_link')}` : `?${t('propostas.id_proposta_link')}`}=${proposta.expirou == 0 ? proposta.url_acesso : proposta.unique_id}`}>{proposta.nome_proposta}</Link>
                                )}

                            />

                            <Table.Column
                                title={t('ultimas_pesquisas.imagem_viatura')}
                                className='text-center'
                                render={(proposta) => (
                                    <Image
                                        style={{ textAlign: 'center' }}
                                        width={60}
                                        src={proposta.imagens?.[0]}
                                    />
                                )}
                            />

                            <Table.Column
                                title="Data de Criação"
                                key="data_criacao"
                                render={(proposta) => (
                                    <span>{proposta.data_criacao}</span>
                                )}
                            />

                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
}
