import { AutoComplete, Button, Modal, Radio, Table } from 'antd';
import React, { useState } from 'react';
import axios from 'axios';
import { AlertaMsg } from '../../mensagens/mensagem_alerta';
import logo from '../../../../assets/hubspot.svg';
import { SucessoMsg } from '../../mensagens/mensagem_sucesso';
import { MdOutlineModeEditOutline } from "react-icons/md";
import AssociarDeal from './associar-deal';
import { IoAddCircleOutline } from "react-icons/io5";
import logoIcon from '../../../../assets/hubspot-icon.svg';
import { IoEyeOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';


export default function InformacoesDeal({
    currentLanguage,
    checkDataHubspot,
    nomeDoDeal,
    nomeDoClienteDeal,
    emailDoClienteDeal,
    dataDeAssociacaoDoDeal,
    etapaDoDeal,
    pipelineDoDeal,
    idDaPropostaEncontrada,
    saveToken,
    iDTokenAuthenticated,
    setIdDaPropostaEncontrada,
    setCheckDataHubspot,
    setNomeDoDeal,
    setNomeDoClienteDeal,
    setEmailDoClienteDeal,
    setDataDeAssociacaoDoDeal,
    setEtapaDoDeal,
    setPipelineDoDeal,
    idDoDealAtual,
    setShowHubspot
}) {

    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    console.log(checkDataHubspot)

    return (
        <>
            <div className='card hubspot-card'>
                <div className={`${checkDataHubspot.length !== 0 ? 'titulo-form-hubspot mb-6' : ''}`}>
                    <div className={`flex items-center gap-4 ${checkDataHubspot.length !== 0 ? 'pb-4' : ''}`}>
                        {checkDataHubspot.length !== 0 ? (
                            <>
                                <img
                                    src={logo}
                                    alt="Logo"
                                    className="w-24 h-10 object-contain"
                                />
                                <span>|</span>
                                <span>Informação do deal</span>
                            </>
                        ) : (
                            <Button
                                type="primary"
                                className="flex gap-2 hubspot-btn items-center font-bold rounded"
                                onClick={showModal}
                            >
                                <img
                                    src={logoIcon}
                                    alt="Logo"
                                    className="w-6 object-contain"
                                />
                                Associar Proposta
                            </Button>
                        )}
                    </div>
                </div>
                {checkDataHubspot.length !== 0 ? (
                    <>
                        <div className='card-white grid'>
                            <div>
                                <p className="text-black text-base flex justify-between">Cliente: <span className="font-bold capitalize">{nomeDoClienteDeal}</span></p>
                                <p className="text-black text-base flex justify-between">Email: <span className="font-bold">{emailDoClienteDeal}</span></p>
                                <p className="text-black text-base flex justify-between">Negócio: <span className="font-bold">{nomeDoDeal}</span></p>
                                <p className="text-black text-base flex justify-between">Data de Associação: <span className="font-bold">{dataDeAssociacaoDoDeal}</span></p>
                                <p className="text-black text-base flex justify-between">Etapa: <span className="font-bold">{etapaDoDeal}</span></p>
                                <p className="text-black text-base flex justify-between">Pipeline: <span className="font-bold">{pipelineDoDeal}</span></p>
                            </div>
                            <div className="border-t-4 pt-2 mt-2 flex gap-2 justify-end">
                                <Button
                                    type="primary"
                                    target="_blank"
                                    className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                    onClick={showModal}
                                >
                                    <MdOutlineModeEditOutline />
                                    Alterar associação
                                </Button>
                                <Link to={`/${currentLanguage}/hubspot/perfil-deal/?id_deal=${checkDataHubspot.deal.id_deal_hubspot}`}>
                                    <Button
                                        type="primary"
                                        target="_blank"
                                        className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                    >
                                        <IoEyeOutline />
                                        Ver Perfil
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </>
                ) : null}
                <Modal
                    title={null}
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText="Fechar"
                    footer={null}
                    width={1000}
                    className="hubspot-modal"
                >
                    <AssociarDeal
                        setShowHubspot={setShowHubspot}
                        idDoDealAtual={idDoDealAtual}
                        checkDataHubspot={checkDataHubspot}
                        setIsModalOpen={setIsModalOpen}
                        idDaPropostaEncontrada={idDaPropostaEncontrada}
                        saveToken={saveToken}
                        iDTokenAuthenticated={iDTokenAuthenticated}
                        setIdDaPropostaEncontrada={setIdDaPropostaEncontrada}
                        setCheckDataHubspot={setCheckDataHubspot}
                        setNomeDoDeal={setNomeDoDeal}
                        setNomeDoClienteDeal={setNomeDoClienteDeal}
                        setEmailDoClienteDeal={setEmailDoClienteDeal}
                        setDataDeAssociacaoDoDeal={setDataDeAssociacaoDoDeal}
                        setEtapaDoDeal={setEtapaDoDeal}
                        setPipelineDoDeal={setPipelineDoDeal}
                    />
                </Modal>
            </div >
        </>
    );
}
