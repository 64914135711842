import React from 'react';
import { FaWhatsapp } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';




const ShareButton = ({ url, message }) => {
  const handleShare = () => {
    const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(message)}%0A${encodeURIComponent(url)}`;
    window.open(whatsappUrl, '_blank');
  };


  const { t } = useTranslation();



  return (
    <button className='flex gap-2 items-center btn-green border bg-green-500 text-white rounded p-2' onClick={handleShare}>
       <FaWhatsapp className='text-2xl' /> <span className='text-xs font-bold '>{t('partilhar.pelo_whatsapp')}</span>
    </button>
  );
};

export default ShareButton;
