import { AutoComplete, Button, Radio, Table, Spin, Popconfirm, Modal, message } from 'antd';
import React, { useState, useRef } from 'react';
import axios from 'axios';
import logo from '../../../../assets/hubspot.svg';
import { IoIosBackspace } from "react-icons/io";
import { AlertaHubspotMsg } from '../mensagens/mensagem_alerta';
import logoCor from '../../../../assets/hubspot-icon.svg';
import { IoCloseCircleOutline } from "react-icons/io5";
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';



const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function AssociarDeal({
    idDaPropostaEncontrada,
    saveToken,
    checkDataHubspot,
    iDTokenAuthenticated,
    setIdDaPropostaEncontrada,
    selectedPropostaParaHubspot,
    setCheckDataHubspot,
    setNomeDoDeal,
    setNomeDoClienteDeal,
    setEmailDoClienteDeal,
    setDataDeAssociacaoDoDeal,
    setEtapaDoDeal,
    setIsModalOpen,
    setPipelineDoDeal,
    idDoDealAtual,
    setShowHubspot
}) {

    const { t } = useTranslation();
    const inputRef = useRef(null);

    const [loading, setLoading] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [validate, setValidate] = useState(false);
    const [textoBotoes, setTextoBotoes] = useState({});


    //guardar value do input
    const [pesquisaEmailDeal, setPesquisaEmailDeal] = useState('');

    //guarda id da proposta
    const [selectedRowKeys, setSelectedRowKeys] = useState('');

    //guardar dados
    const [data, setData] = useState([]);

    //todos os deals encontrados depois de selecionar
    const [listaDeDeals, setListaDeDeals] = useState(false);

    const [alerta, setAlerta] = useState('');
    const [estadoDoAlerta, setEstadodoAlerta] = useState('0');




    let timeoutId = useRef(null);

    const handlePesquisaDealChange = (value) => {
        setPesquisaEmailDeal(value);

        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }

        timeoutId.current = setTimeout(() => {
            if (value && value.length >= 4) {
                let url = `${process.env.REACT_APP_GET_PROCURAR_DEALS}?email=${value}&token=${saveToken}&user_id=${iDTokenAuthenticated}&live`;

                axios.get(url)
                    .then((response) => {
                        const email = response.data.contactos.map((item) => ({
                            value: item.properties.email,
                            label: `${item.properties.firstname} ${item.properties.lastname || ''} | (${item.properties.email}) | (${item.properties.hs_calculated_phone_number || item.properties.mobilephone || ''})`,
                            deals: item?.deals,
                        }));
                        setData(email);
                    })
                    .catch((error) => {
                        console.error('Error occurred:', error);
                    });
            } else {
                setData([]);
            }
        }, 1500);
    };



    // const handlePesquisaDealSelect = (value) => {
    //     const selectedOption = data.find(option => option.value === value);

    //     if (selectedOption) {
    //         setListaDeDeals(selectedOption.deals || []);

    //         if (!selectedOption.deals || selectedOption.deals.length === 0) {
    //             setAlerta([{ mensagem: "o contacto selecionado não tem deals associados", sucesso: false }]);
    //         }

    //         console.log(idDoDealAtual)

    //         const dealAssociado = selectedOption.deals.find(deal => deal.id === idDoDealAtual);
    //         if (dealAssociado) {
    //             setTextoBotao('Desassociar');
    //         } else {
    //             setTextoBotao('Associar');
    //         }
    //     }
    // }


    const handlePesquisaDealSelect = (value) => {
        const selectedOption = data.find(option => option.value === value);

        if (selectedOption) {
            setListaDeDeals(selectedOption.deals || []);

            if (!selectedOption.deals || selectedOption.deals.length === 0) {
                setAlerta([{ mensagem: "O contacto selecionado não tem deals associados", sucesso: false }]);
            }

            const novosTextosBotoes = selectedOption.deals.reduce((acc, deal) => {
                acc[deal.id] = deal.id === idDoDealAtual ? 'Desassociar' : 'Associar';
                return acc;
            }, {});
            setTextoBotoes(novosTextosBotoes);
        }
    };



    //radiobutton check
    const onSelectChange = (selectedRowKey) => {
        setSelectedRowKeys(selectedRowKey);
        setAlerta('')
        setEstadodoAlerta('')
    };


    const associarNovoDealDepoisdaPesquisa = () => {
        let url = `${process.env.REACT_APP_GET_HUBSPOT_INFO_DA_PROPOSTA}?id_proposta=${idDaPropostaEncontrada}&token=${saveToken}&user_id=${iDTokenAuthenticated}&live`;
        axios.get(url)
            .then((response) => {

                if (response.data.sucesso) {
                    const hubspot = response.data.hubspot
                    setCheckDataHubspot(hubspot)

                    setNomeDoDeal(hubspot.deal.info.properties.dealname)
                    setNomeDoClienteDeal(hubspot.contacto.info.properties.firstname)
                    setEmailDoClienteDeal(hubspot.contacto.info.properties.email)
                    setDataDeAssociacaoDoDeal(hubspot.deal.data_registo_hubspot)
                    setEtapaDoDeal(hubspot.deal.info.properties.dealstage)
                    setPipelineDoDeal(hubspot.deal.info.properties.pipeline)
                } else {
                    setShowHubspot(false)
                    setCheckDataHubspot([]);
                    setNomeDoDeal('');
                    setNomeDoClienteDeal('');
                    setEmailDoClienteDeal('');
                    setDataDeAssociacaoDoDeal('');
                    setEtapaDoDeal('');
                    setPipelineDoDeal('');
                }


            })
            .catch((error) => {
                console.error('Error occurred:', error);
            });

    }


    const handleAssociarDeal = () => {

        setLoading(true)
        setIsModalVisible(false)
        setValidate(false)
        setIsModalVisible(false)
        setEstadodoAlerta('')

        if (selectedRowKeys) {

            if (!listaDeDeals && !validate) {
                setValidate(true)
                setIsModalVisible(true)
                setLoading(false)
                return
            }

            let url

            if (!selectedPropostaParaHubspot) {

                url = `${process.env.REACT_APP_GET_ASSOCIAR_DEAL}?id_proposta=${idDaPropostaEncontrada}&id_deal=${selectedRowKeys}&token=${saveToken}&user_id=${iDTokenAuthenticated}&live`;

            } else {

                const colocarVirgulanoArray = selectedPropostaParaHubspot.join(',')
                url = `${process.env.REACT_APP_GET_ASSOCIAR_DEAL}?id_proposta=${colocarVirgulanoArray}&id_deal=${selectedRowKeys}&token=${saveToken}&user_id=${iDTokenAuthenticated}&live`;

            }

            axios.get(url)
                .then((response) => {
                    // console.log('responseresponseresponse', response)
                    if (response.data.sucesso) {

                        const messages = response.data.alertas.map(item => item);
                        setAlerta(messages);
                        setEstadodoAlerta(1);
                        if (idDaPropostaEncontrada) {
                            associarNovoDealDepoisdaPesquisa()
                        }
                        setLoading(false)

                        setTimeout(() => {
                            setIsModalOpen(false);
                        }, 1500)


                    } else {

                        const messages = response.data.alertas.map(item => item);
                        setAlerta(messages);
                        setEstadodoAlerta(1)
                        if (idDaPropostaEncontrada) {
                            associarNovoDealDepoisdaPesquisa()
                        }
                        setLoading(false)

                    }

                })
                .catch((error) => {
                    setLoading(false)
                    setAlerta([{ mensagem: "tente novamente", sucesso: false }])
                });
        } else {
            setEstadodoAlerta(1)
            setLoading(false)
            setAlerta([{ mensagem: "selecione um deal", sucesso: false }])
        }

    }


    const handleClearEmail = () => {
        setPesquisaEmailDeal('');
        inputRef.current.focus();
        setAlerta('');
        setEstadodoAlerta('');
        setListaDeDeals(false);
        setSelectedRowKeys('');
    }



    const handleClosePopup = () => {
        setIsModalOpen(false)
    }

    const handleConfirmComp = () => {
        handleAssociarDeal()
    };

    const handleCancelComp = (e) => {
        setValidate(false)
        setIsModalVisible(false)
        setLoading(false)
    };

    return (
        <>
            <div className='mt-7 mb-7 grid gap-4'>
                <div className='card'>
                    <div className='flex items-center gap-4 text-white'>
                        <img
                            src={logo}
                            alt="Logo"
                            className="w-24 h-10 object-contain"
                        />
                        <span>|</span>
                        <span className='text-base font-semibold'>Associar deal</span>
                    </div>
                </div>
                <div className="grid gap-4 content-start popup-associar">
                    <div className='titulo-form'>
                        <p>Pesquisa pelo email ou contacto do cliente</p>
                    </div>
                    <div className="flex items-center gap-2">
                        <AutoComplete
                            ref={inputRef}
                            value={pesquisaEmailDeal}
                            options={data}
                            onChange={handlePesquisaDealChange}
                            onSelect={handlePesquisaDealSelect}
                            placeholder="Preenche com o email ou contacto do cliente"
                            style={{ width: '100%' }}
                        />
                        <Button type="primary" className="bg-white text-black font-bold rounded"
                            onClick={handleClearEmail}>
                            <IoIosBackspace className='iconc' />
                        </Button>
                    </div>
                    {listaDeDeals.length > 0 ? (
                        <>
                            <div>
                                <Table
                                    dataSource={listaDeDeals}
                                    rowKey={(deal) => deal.id}
                                    scroll={{ x: 'max-content' }}
                                    bordered
                                    pagination={false}
                                >
                                    <Table.Column
                                        title="Ação"
                                        key="select"
                                        render={(deal) => (
                                            <Radio
                                                checked={selectedRowKeys.includes(deal.id)}
                                                onChange={() => onSelectChange(deal.id)}
                                            />
                                        )}
                                    />
                                    <Table.Column
                                        title="Nome do deal"
                                        key="nome_deal"
                                        render={(deal) => (
                                            <span>{deal.properties.dealname}</span>
                                        )}
                                    />
                                    <Table.Column
                                        title="Data do deal"
                                        key="data_deal"
                                        render={(deal) => (
                                            <span>{deal.createdAt}</span>
                                        )}
                                    />
                                    <Table.Column
                                        title="Estado do deal"
                                        key="nome_deal"
                                        render={(deal) => (
                                            <span>{deal.properties.dealstage}</span>
                                        )}
                                    />
                                    <Table.Column
                                        title="Pipeline do deal"
                                        key="nome_deal"
                                        render={(deal) => (
                                            <span>{deal.properties.pipeline}</span>
                                        )}
                                    />
                                </Table>
                            </div>
                            <div className='flex items-center justify-end gap-2'>
                                <Button type="primary" className="flex gap-2 hubspot-btn items-center font-bold rounded"
                                    onClick={handleAssociarDeal}>
                                    <img
                                        src={logoCor}
                                        alt="Logo"
                                        className="w-6 object-contain"
                                    />
                                    {textoBotoes[selectedRowKeys] || 'Associar'}
                                </Button>
                                <Button type="primary" className="flex items-center gap-2 bg-white text-black font-bold rounded"
                                    onClick={handleClosePopup}>
                                    <IoCloseCircleOutline className='iconc' />
                                    Fechar
                                </Button>
                            </div>
                        </>
                    ) : listaDeDeals.length === 0 && (
                        <AlertaHubspotMsg propsalerta={alerta} />
                    )}
                    {loading ? (
                        <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-black text-center'>A associar ...</p>
                        </div>
                    ) : null}
                    {estadoDoAlerta === 1 && <AlertaHubspotMsg propsalerta={alerta} />}
                    <Modal
                        title="Confirmação"
                        visible={isModalVisible}
                        onOk={handleConfirmComp}
                        onCancel={handleCancelComp}
                        okText="Alterar"
                        cancelText="Cancelar"
                    >
                        <p>A proposta já se encontra associada a um deal, deseja alterar?</p>
                    </Modal>
                </div>
            </div>
        </>
    );
}
