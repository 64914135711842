import React, { useEffect, useState } from "react";
import axios from "axios";
import { Image, Input, Table, Spin, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";


const antIcon = (
    <LoadingOutlined
        style={{
            fontSize: 30,
        }}
        spin
    />
);


export default function UltimasPesquisas({ currentLanguage, iDTokenAuthenticated, saveToken, formatarNumero, userData }) {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [propostaData, setPropostaData] = useState([]);

    useEffect(() => {

        setLoading(true)

        const url = `${process.env.REACT_APP_GET_HISTORICO_PESQUISAS}?token=${saveToken}&user_id=${iDTokenAuthenticated}&lang=${currentLanguage}`;

        axios.get(url)
            .then((response) => {
                setLoading(false)
                //console.log(response)
                setPropostaData(response.data);
            })
            .catch((error) => {
                setLoading(true)
                console.error('Error occurred:', error);
            });
    }, []);

    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };



    const filteredPropostas = propostaData.filter((data) => {

        const nome = data.user_data.nome_criou.toLowerCase();
        const dataCriacao = data.data_criacao.toLowerCase();
        const marca = data.info?.marca.toLowerCase();
        const searchQueryLowerCase = searchQuery.toLowerCase();

        if (userData.roles[0] === "administrator") {
            return (
                nome.includes(searchQueryLowerCase) ||
                dataCriacao.includes(searchQueryLowerCase) ||
                marca.includes(searchQueryLowerCase)
            );
        } else {
            return (
                dataCriacao.includes(searchQueryLowerCase) ||
                marca.includes(searchQueryLowerCase)
            );
        }


    });

    return (
        <>
            <div className="historico-pesquisas">
                {loading ? (
                    <>
                        <div className="card-white tabela-propostas flex flex-col gap-4 justify-center">
                            <Spin indicator={antIcon} />
                            <p className='text-black text-center'>{t('media_mercado.loading')}</p>
                        </div>
                    </>
                ) : (
                    <div className="card flex flex-col gap-4">
                        <div className='border-b-2 border-solid border-white flex items-center gap-4 pb-4'>
                            <p className='font-semibold'>{t('ultimas_pesquisas.titulo_principal')}</p>
                            <div>|</div>
                            <Link to={`/${currentLanguage}/simulador`}>
                                <Button
                                    type="primary"
                                    target="_blank"
                                    className="flex gap-2 items-center bg-white text-black font-bold rounded"
                                >
                                    <IoMdAdd /> Criar proposta
                                </Button>
                            </Link>
                        </div>
                        <div className="">
                            {userData.roles[0] === "administrator" ? (
                                <Input
                                    type="text"
                                    placeholder={t('ultimas_pesquisas.input')}
                                    value={searchQuery}
                                    onChange={handleInputChange}
                                />
                            ) : (
                                <Input
                                    type="text"
                                    placeholder={t('ultimas_pesquisas.input_no_admin')}
                                    value={searchQuery}
                                    onChange={handleInputChange}
                                />
                            )}
                        </div>
                        <div className="w-full">
                            <div className="">
                                <Table
                                    dataSource={filteredPropostas}
                                    rowKey={(proposta) => proposta.id}
                                    scroll={{ x: 'max-content' }}
                                    bordered
                                >

                                    {userData.roles[0] === "administrator" ? (
                                        <Table.Column
                                            title={t('ultimas_pesquisas.pesquisado_por')}
                                            key="nome_criou"
                                            render={(proposta) => (
                                                proposta.user_data.nome_criou
                                            )}
                                        />
                                    ) : null}

                                    <Table.Column
                                        title={t('ultimas_pesquisas.imagem_viatura')}
                                        // key=""
                                        className='text-center'
                                        render={(proposta) => (
                                            <Image
                                                style={{ textAlign: 'center' }}
                                                width={60}
                                                src={proposta.info.imagens}
                                            />
                                        )}
                                    />

                                    <Table.Column
                                        title={t('ultimas_pesquisas.data')}
                                        key="data_criacao"
                                        render={(proposta) => (
                                            proposta.data_criacao
                                        )}
                                    />

                                    <Table.Column
                                        title={t('ultimas_pesquisas.marca')}
                                        key="marca"
                                        render={(proposta) => (
                                            proposta.info.marca ? `${proposta.info.marca}` : ""
                                        )}
                                    />

                                    <Table.Column
                                        title="URL"
                                        key="url"
                                        render={(proposta) => (
                                            proposta.url

                                        )}
                                    />
                                </Table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>

    )
}
