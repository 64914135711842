import React, { useState, useEffect } from "react";
import { Button, Input, Modal, Tabs, Tooltip } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useTranslation } from 'react-i18next';



export default function PT_ResultadodaSimulacao({
    iDTokenAuthenticated,
    activeTabKeyResultadoSimulacao,
    setActiveTabKeyResultadoSimulacao,
    formatarNumero,
    valorFinalISV,
    valorFinalIUC,
    iucCilindrada,
    iucCO2,
    iucAdicionalCO2,
    iucCoeficienteAnoMatriculo,
    iucAdicionalGasoleo,
    isvcomponentecilindrada,
    isvcomponenteambiental,
    isvReducaoAnosUsoCilindrada,
    isvReducaoAnosUsoAmbiental,
    isvAgravamentoParticulas,
    isvReducaoAnosUsoParticulas,
    isvTaxaAplicavelTabela,
    dataGeralIUC,
    taxaDeServico,
    tituloDaPropostaChaveNaMao,
    temParametros,
    precoFinalCarro,
    dataCarros,
    setTituloDaPropostaChaveNaMao,
    tituloDaPropostaChaveNaMaoComLinkFinal,
    valorChaveNaMaoEmpresa,
    valorChaveNaMaoParticular,
    precoFinalDoCarroSemIva,
    urlCarroLink,
    tituloDaPropostaChaveNaMaoComLinkFinalParticular,
    setTabResultadoSimulacao,
    tabResultadoSimulacao,
    estadoDaPartilhaNomeDaProposta,
    setIsParticular,
    mostrarResultado,
    comissaoCredito,
    isCheckedCredito
}) {


    const { t } = useTranslation();


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenISV, setIsModalOpenISV] = useState(false);


    const handleTabChange = (key) => {
        setActiveTabKeyResultadoSimulacao(key);
        // console.log('activeTabKeyResultadoSimulacao',activeTabKeyResultadoSimulacao)
        if (activeTabKeyResultadoSimulacao === '2') {
            setTabResultadoSimulacao('0')
            setIsParticular(true)
            // console.log('particular')
        } else {
            setTabResultadoSimulacao('1')
            // console.log('empresa')
            setIsParticular(false)
        }
    };

    const dataIUC = dataGeralIUC.taxas

    // console.log(valorFinalISV)
    // console.log(dataGeralIUC)
    // console.log(Math.ceil(valorFinalISV))

    // console.log('valorChaveNaMaoParticular',valorChaveNaMaoParticular)
    // console.log('valorChaveNaMaoEmpresa',valorChaveNaMaoEmpresa)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showModalISV = () => {
        setIsModalOpenISV(true);
    };

    const handleOkISV = () => {
        setIsModalOpenISV(false);
    };

    const handleCancelISV = () => {
        setIsModalOpenISV(false);
    };


    useEffect(() => {

        if (tabResultadoSimulacao === '0') {
            setActiveTabKeyResultadoSimulacao('1');
        } else {
            setActiveTabKeyResultadoSimulacao('2');
        }

    }, [estadoDaPartilhaNomeDaProposta]);


    return (
        <div className='card'>
            <div className='titulo-form'>
                <p>{t('resultadodasimulacao.titulo_principal')}</p>
            </div>
            <Tabs className='calculo-chave-na-mao' activeKey={activeTabKeyResultadoSimulacao} onChange={handleTabChange}
                tabBarGutter={16} centered>
                {(estadoDaPartilhaNomeDaProposta && (tabResultadoSimulacao == '0')) || iDTokenAuthenticated ? (
                    <TabPane tab={<span className='text-black'>{t('main.particular')}</span>}
                        key="1">
                        <div>

                            <p className='text-black text-base flex justify-between'>{t('resultadodasimulacao.preco_do_carro')}
                                <span className='font-bold text-lg'>{formatarNumero(precoFinalCarro)}€</span>
                            </p>

                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-1">
                                    {t('main.isv')}<AiOutlineInfoCircle className='icon-question' onClick={showModalISV} />
                                </span>
                                <span className='font-bold text-lg flex items-center gap-2'>
                                    {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                                </span>
                            </p>
                            <Modal
                                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                                open={isModalOpenISV}
                                onOk={handleOkISV}
                                onCancel={handleCancelISV}
                                okText="Fechar"
                                footer={null}
                            >
                                <p className="text-black text-base flex justify-between">Componente cilindrada: <span className="font-bold text-lg">{isvcomponentecilindrada ? `${isvcomponentecilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Componente ambiental: <span className="font-bold text-lg">{isvcomponenteambiental ? `${isvcomponenteambiental}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso cilindrada: <span className="font-bold text-lg">{isvReducaoAnosUsoCilindrada ? `${isvReducaoAnosUsoCilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso ambiental: <span className="font-bold text-lg">{isvReducaoAnosUsoAmbiental ? `${isvReducaoAnosUsoAmbiental}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Agravamento particulas: <span className="font-bold text-lg">{isvAgravamentoParticulas ? `${isvAgravamentoParticulas}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso particulas: <span className="font-bold text-lg">{isvReducaoAnosUsoParticulas ? `${isvReducaoAnosUsoParticulas}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Taxa aplicável tabela: <span className="font-bold text-lg">{isvTaxaAplicavelTabela ? `${isvTaxaAplicavelTabela}` : "Sem informação"}</span></p>

                            </Modal>
                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-1">
                                    {t('resultadodasimulacao.taxa_de_servico')}
                                    <Tooltip title="A taxa de serviço inclui o custo do transporte, seguro, legalização e todas as restantes despesas do processo de importação.">
                                        <AiOutlineInfoCircle className='icon-question' />
                                    </Tooltip>
                                </span>
                                <span className='font-bold text-lg flex items-center gap-2'>{taxaDeServico ? `${formatarNumero(taxaDeServico)}€` : "0€"} </span>
                            </p>

                            {isCheckedCredito && (
                                <p className='text-black text-base flex justify-between'>
                                    <span className="flex items-center gap-1">
                                        {t('resultadodasimulacao.comissao')}
                                        {/* <Tooltip title="Teste">
                                            <AiOutlineInfoCircle className='icon-question' />
                                        </Tooltip> */}
                                    </span>
                                    <span className='font-bold text-lg flex items-center gap-2'>{comissaoCredito} €</span>
                                </p>
                            )}

                        </div>

                        <div className="flex justify-between items-center">
                            <span className='center text-black text-base font-semibold'>{t('resultadodasimulacao.preco_chave_nao_mao')}</span>
                            <span className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorChaveNaMaoParticular)}€</span>
                        </div>
                        
                        <div className="border-t-4 pt-2 mt-2">
                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-1">
                                    {t('main.iuc')}
                                    <AiOutlineInfoCircle className='icon-question' onClick={showModal} />
                                </span>
                                <span className='font-bold text-lg flex items-center gap-2'>
                                    {valorFinalIUC ? `${formatarNumero(valorFinalIUC)}€` : "0€"}

                                </span>
                            </p>
                            <Modal
                                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText="Fechar"
                                footer={null}
                            >
                                <p className="text-black text-base flex justify-between">Cilindrada: <span className="font-bold text-lg">{iucCilindrada ? `${iucCilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">CO2: <span className="font-bold text-lg">{iucCO2 ? `${iucCO2}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Adicional CO2: <span className="font-bold text-lg">{iucAdicionalCO2 ? `${iucAdicionalCO2}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Coeficiente ano matricula: <span className="font-bold text-lg">{iucCoeficienteAnoMatriculo ? `${iucCoeficienteAnoMatriculo}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Adicional Gasóleo: <span className="font-bold text-lg">{iucAdicionalGasoleo ? `${iucAdicionalGasoleo}€` : "0€"}</span></p>

                                <div className="border-t-4 pt-2 mt-2">
                                    <p className="text-black text-base flex justify-between">O IUC é pago anualmente pelo proprietário do veículo. A primeira guia de IUC tem de ser paga dentro dos 90 dias após emissão da DAV.</p>
                                </div>
                            </Modal>
                        </div>
                    </TabPane>
                ) : null}
                {(estadoDaPartilhaNomeDaProposta && (tabResultadoSimulacao == '1')) || iDTokenAuthenticated ? (
                    <TabPane tab={<span className='text-black'>Empresa</span>}
                        key="2">
                        <div>
                            <p className='text-black text-base flex justify-between'>{t('resultadodasimulacao.preco_do_carro')} <span
                                className='font-bold text-lg'>{formatarNumero(precoFinalDoCarroSemIva)}€</span>
                            </p>
                            <p className='text-black text-base flex justify-between'>
                                {t('main.isv')}:
                                <span className='font-bold text-lg flex items-center gap-2'>
                                    {valorFinalISV ? `${formatarNumero(valorFinalISV)}€` : "0€"}
                                    <AiOutlineInfoCircle className='icon-question' onClick={showModalISV} />
                                </span>
                            </p>
                            <Modal
                                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                                open={isModalOpenISV}
                                onOk={handleOkISV}
                                onCancel={handleCancelISV}
                                okText="Fechar"
                                footer={null}
                            >
                                <p className="text-black text-base flex justify-between">Componente cilindrada: <span className="font-bold text-lg">{isvcomponentecilindrada ? `${isvcomponentecilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Componente ambiental: <span className="font-bold text-lg">{isvcomponenteambiental ? `${isvcomponenteambiental}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso cilindrada: <span className="font-bold text-lg">{isvReducaoAnosUsoCilindrada ? `${isvReducaoAnosUsoCilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso ambiental: <span className="font-bold text-lg">{isvReducaoAnosUsoAmbiental ? `${isvReducaoAnosUsoAmbiental}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Agravamento particulas: <span className="font-bold text-lg">{isvAgravamentoParticulas ? `${isvAgravamentoParticulas}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Redução anos uso particulas: <span className="font-bold text-lg">{isvReducaoAnosUsoParticulas ? `${isvReducaoAnosUsoParticulas}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Taxa aplicável tabela: <span className="font-bold text-lg">{isvTaxaAplicavelTabela ? `${isvTaxaAplicavelTabela}` : "Sem informação"}</span></p>

                            </Modal>
                            <p className='text-black text-base flex justify-between'>
                                <span className="flex items-center gap-2">
                                    {t('resultadodasimulacao.taxa_de_servico')}
                                    <Tooltip title="A taxa de serviço inclui o custo do transporte, seguro, legalização e todas as restantes despesas do processo de importação.">
                                        <AiOutlineInfoCircle className='icon-question' />
                                    </Tooltip>:
                                </span>
                                <span className='font-bold text-lg flex items-center gap-2'>{taxaDeServico ? `${formatarNumero(taxaDeServico)}€` : "0€"} </span>
                            </p>
                        </div>

                        <div>
                            <p className='center text-black text-base font-semibold flex justify-between'>{t('resultadodasimulacao.preco_chave_nao_mao')} <span
                                className='text-lg sm:text-2xl font-extrabold'>{formatarNumero(valorChaveNaMaoEmpresa)}€</span>
                            </p>
                        </div>
                        <div className="border-t-4 pt-2 mt-2">
                            <p className='text-black text-base flex justify-between'>
                                {t('main.iuc')}:
                                <span className='font-bold text-lg flex items-center gap-2'>
                                    {valorFinalIUC ? `${formatarNumero(valorFinalIUC)}€` : "0€"}
                                    <AiOutlineInfoCircle className='icon-question' onClick={showModal} />
                                </span>
                            </p>
                            <Modal
                                title={<span className="flex items-center gap-2"><AiOutlineInfoCircle /> {t('resultadodasimulacao.explicacao_do_calculo')}</span>}
                                open={isModalOpen}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText="Fechar"
                                footer={null} // Não há necessidade de um rodapé neste caso
                            >
                                <p className="text-black text-base flex justify-between">Cilindrada: <span className="font-bold text-lg">{iucCilindrada ? `${iucCilindrada}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">CO2: <span className="font-bold text-lg">{iucCO2 ? `${iucCO2}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Adicional CO2: <span className="font-bold text-lg">{iucAdicionalCO2 ? `${iucAdicionalCO2}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Coeficiente ano matricula: <span className="font-bold text-lg">{iucCoeficienteAnoMatriculo ? `${iucCoeficienteAnoMatriculo}€` : "0€"}</span></p>
                                <p className="text-black text-base flex justify-between">Adicional Gasóleo: <span className="font-bold text-lg">{iucAdicionalGasoleo ? `${iucAdicionalGasoleo}€` : "0€"}</span></p>
                                <div className="border-t-4 pt-2 mt-2">
                                    <p className="text-black text-base flex justify-between">O IUC é pago anualmente pelo proprietário do veículo. A primeira guia de iUC tem de ser paga dentro dos 90 dias após emissão da DAV.</p>
                                </div>
                            </Modal>
                        </div>
                    </TabPane>
                ) : null}
            </Tabs>
        </div>

    )
}
